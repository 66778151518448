import prepareWindow from "../../windowCreating/prepareWindow";
import EarningsTranscripts from "../../../components/FeatureComponents/5_EarningsTranscripts/5_EarningsTranscripts";
import ReactDOM from 'react-dom'


async function handleEarningsTranscripts(symbol, isInitialDisplay=true, existingWindowID="") {
    const WINDOW_TYPE_NAME = 'window-earnings-transcripts';
    const TITLE_PREFIX = 'Earnings Transcripts ';
  
    const window = await prepareWindow(symbol, WINDOW_TYPE_NAME, TITLE_PREFIX, isInitialDisplay, existingWindowID)
    const destinationElement = window.querySelector('.window-content')

    ReactDOM.render(<EarningsTranscripts symbol={symbol} windowID={window.id} newWindow={isInitialDisplay}/>, destinationElement)
  }

export default handleEarningsTranscripts
import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import constants from "../../../constants";
import LoadingSpinner from "../../misc/LoadingSpinner";
import WindowPieces from "../../ui/small/WindowPieces";
import WindowError from "../../misc/WindowError";
import AutosuggestSecurities from "../../autosuggest/searchbars/AutosuggestSecurities_v3";
import dashboardLayout from "../../../functions/memoryManagement/dashboardLayout";
import { getGlobalLayout, updateLayoutItem } from "../../../functions/memoryManagement/layoutManager";
import LoadButton from "../../ui/small/buttons/LoadButton";
import ZenithTable1 from "../../ui/small/tables/ZenithTable1";

const AnalystRatings = ({ symbol, windowID, newWindow }) => {
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 1. DEFAULT CONSTANTS
    const DEFAULT_PARAMS = {
        'symbol': symbol,
        'page': 0,
    };
    const ENDPOINT = '/analyst-ratings/';
    const tabs = [
        { label: "Ratings", targetID: "ratings" },
        { label: "Consensus", targetID: "consensus" },
    ];
    const DEFAULT_TAB='ratings'

    const globalLayout = getGlobalLayout();
    const layout = globalLayout.find(tab =>
        tab.layout.find(window => window.windowID === windowID)
    )
    const window = layout?.layout.find(window=>window.windowID === windowID)
    const windowParameters = window?.windowParameters
    const paramsToUse = windowParameters || DEFAULT_PARAMS;

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 2. STATES - Most Windows will have a params, loading state. Ones with tabs will have an activeTabID state

    const [params, setParams] = useState(paramsToUse);
    const [loading, setLoading] = useState(true); // New loading state
    const [error, setError] = useState(null); // Error state
    const [activeTabID, setActiveTabID] = useState(DEFAULT_TAB)
    const [data, setData] = useState({});
    const [initialRender, setInitialRender] = useState(true);

    const autoSuggestRef = useRef(null);
    

    

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 3. 

    const requestData = () => {
        const symbolValue = autoSuggestRef.current ? autoSuggestRef.current.value : params.symbol;
    
        // Update params using the previous state value
        setParams((prevParams) => {
            const newParams = {
                ...prevParams,
                ['symbol']: symbolValue
            };
            
            // Update Database
            updateLayoutItem(windowID, {
                symbol: symbolValue ? symbolValue : "N/A",
                windowParameters: newParams,
            });
            const layout = getGlobalLayout();
            dashboardLayout.saveLayout(layout);
    
            // Call the API with the updated params
            setLoading(true); // Start loading
            setError(null); 
            axios.post(constants.BACKEND_URL+ENDPOINT, newParams) // Use newParams here
                .then(response => {
                    const parsedResponse = JSON.parse(response.data.data);
                    
                    setData(parsedResponse);
                })
                .catch(error => {
                    console.error('Error during POST request:', error);
                    setError("Failed to load financial statements.");
                })
                .finally(() => {
                    setLoading(false); // Stop loading
                });
    
            return newParams; // Return the new params for state update
        });

        if (initialRender) setInitialRender(false);
    };


    useEffect(() => {
        requestData();  // Initial request
    }, []);


    const updateParams = (e) => {
        const { id, value } = e.target;
    
        // Update the params by setting the new value for the key that matches the input's id
        setParams((prevParams) => ({
            ...prevParams,
            [id]: value
        }));
    }

    const handleTabClick = (targetID) => {
        setActiveTabID(targetID); // Update active tab on click
        console.log(targetID)
        
    };


    
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 4. 
    

    return (
        <>
            {loading ? ( // Conditional rendering for loading spinner
                <LoadingSpinner />
            ) : error ? (
                <WindowError message={error} />
            ) : (
                <>
                    <WindowPieces.OptionsContainer securitySearch={true}>
                        <AutosuggestSecurities
                                id={'symbol'}
                                ref={autoSuggestRef}
                                defaultValue={params.symbol}
                                handleSubmitCustom={requestData}
                            />
                        <WindowPieces.ConfigContainer>
                            <LoadButton onClick={requestData}>LOAD</LoadButton>
                        </WindowPieces.ConfigContainer>
                    </WindowPieces.OptionsContainer>

                    <WindowPieces.TabContainer tabs={tabs} activeTabID={activeTabID} onTabClick={handleTabClick} />

                    <WindowPieces.TabContent activeTabID={activeTabID}>
                        <div id='ratings'>
                            <ZenithTable1.Table
                                tablehead={
                                <tr>
                                    <th>Action</th>
                                    <th>Grade Change</th>
                                    <th>Price When Posted</th>
                                    <th>Analyst Name</th>
                                    <th>Analyst Company</th>
                                    <th>Article URL</th>
                                </tr>
                                }
                                tablebody={
                                    <>
                                        {data.ratingsTable.map((rating, index)=>{
                                            const action = rating.action

                                            const textColor = action=="initialise"? "#0c69b5" : action == "hold"? "var(--neutral)": action == "upgrade"? "var(--primary)": "var(--negative)"
                                            const backgroundColor = action=="initialise"? "#062e4f" : action=="hold"? "#5e5001": action == "upgrade"? '#06593b': '#54061c'


                                            return (
                                            <tr key={index}>
                                                <td style={{color: textColor}}>{action.toUpperCase()}</td>
                                                <td style={{color: textColor}}>{rating.previousGrade} &#8658; {rating.newGrade}</td>
                                                <td>${rating.priceWhenPosted}</td>
                                                <td>{rating.analystName}</td>
                                                <td>{rating.gradingCompany}</td>
                                                <td><a target="_blank" style={{color: "var(--primary)"}}  rel="noopener noreferrer" href={rating.newsURL}>{rating.newsURL}</a></td>                                            
                                            </tr>
                                        )})}
                                    </>
                                }
                            
                            />
                        </div>
                        <div id='consensus'>The Consensus</div>
                    </WindowPieces.TabContent>
                    
                    {activeTabID === 'ratings' ? (
                        <WindowPieces.PageViewer 
                            // currentPage={currentPage}
                            // totalPages={totalPages}
                            // changePageFunc={changePage}
                            // handlePageInput={handlePageInputChange}
                        />
                    ) : null}
                    

              
                    
                </>
            )}
        </>
    );
};

export default AnalystRatings;
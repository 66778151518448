
import React from "react";
import ReactDOM from "react-dom";
import prepareWindow from "../../windowCreating/prepareWindow";
import HistoricalPrice from "../../../components/FeatureComponents/9_HistoricalPrice/9_HistoricalPrice";

async function handleHistoricalPrice(symbol, isInitialDisplay=true, existingWindowID="") {
    const WINDOW_TYPE_NAME = 'window-historical-price';
    const TITLE_PREFIX = 'Chart ';

    const window = await prepareWindow(symbol, WINDOW_TYPE_NAME, TITLE_PREFIX, isInitialDisplay, existingWindowID)
    const destinationElement = window.querySelector('.window-content')

    ReactDOM.render(<HistoricalPrice symbol={symbol} windowID={window.id} newWindow={isInitialDisplay}/>, destinationElement)
}

export default handleHistoricalPrice 


import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import constants from "../../../constants";
import LoadingSpinner from "../../misc/LoadingSpinner";
import WindowPieces from "../../ui/small/WindowPieces";
import WindowError from "../../misc/WindowError";
import AutosuggestSecurities from "../../autosuggest/searchbars/AutosuggestSecurities_v3";
import dashboardLayout from "../../../functions/memoryManagement/dashboardLayout";
import { getGlobalLayout, updateLayoutItem } from "../../../functions/memoryManagement/layoutManager";
import LoadButton from "../../ui/small/buttons/LoadButton";
import ZenithTable1 from "../../ui/small/tables/ZenithTable1";

const SECFIlings = ({ symbol, windowID, newWindow }) => {
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 1. DEFAULT CONSTANTS
    const DEFAULT_PARAMS = {
        'symbol': symbol,
        'page': 0,
    };
    const ENDPOINT = '/sec-filings/';

    const globalLayout = getGlobalLayout();
    const layout = globalLayout.find(tab =>
        tab.layout.find(window => window.windowID === windowID)
    )
    const window = layout?.layout.find(window=>window.windowID === windowID)
    const windowParameters = window?.windowParameters
    const paramsToUse = windowParameters || DEFAULT_PARAMS;
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 2. STATES - Most Windows will have a params, loading state. Ones with tabs will have an activeTabID state

    const [params, setParams] = useState(paramsToUse);
    const [loading, setLoading] = useState(true); // New loading state
    const [error, setError] = useState(null); // Error state
    const [data, setData] = useState({});
    const [initialRender, setInitialRender] = useState(true);

    const autoSuggestRef = useRef(null);

  
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 3. 

    const requestData = () => {
        const symbolValue = autoSuggestRef.current ? autoSuggestRef.current.value : params.symbol;
    
        // Update params using the previous state value
        setParams((prevParams) => {
            const newParams = {
                ...prevParams,
                ['symbol']: symbolValue
            };
            
            // Update Database
            updateLayoutItem(windowID, {
                symbol: symbolValue ? symbolValue : "N/A",
                windowParameters: newParams,
            });
            const layout = getGlobalLayout();
            dashboardLayout.saveLayout(layout);
    
            // Call the API with the updated params
            setLoading(true); // Start loading
            setError(null); 
            axios.post(constants.BACKEND_URL+ENDPOINT, newParams) // Use newParams here
                .then(response => {
                    const parsedResponse = JSON.parse(response.data.data);
                    
                    setData(parsedResponse);
                })
                .catch(error => {
                    console.error('Error during POST request:', error);
                    setError("Failed to load financial statements.");
                })
                .finally(() => {
                    setLoading(false); // Stop loading
                });
    
            return newParams; // Return the new params for state update
        });

        if (initialRender) setInitialRender(false);
    };

    useEffect(() => {
        requestData();  // Initial request
    }, []);


    const updateParams = (e) => {
        const { id, value } = e.target;
    
        // Update the params by setting the new value for the key that matches the input's id
        setParams((prevParams) => ({
            ...prevParams,
            [id]: value
        }));
    }


    
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 4. 
    

    return (
        <>
            {loading ? ( // Conditional rendering for loading spinner
                <LoadingSpinner />
            ) : error ? (
                <WindowError message={error} />
            ) : (
                <>
                    <WindowPieces.OptionsContainer securitySearch={true}>
                        <AutosuggestSecurities
                            id={'symbol'}
                            ref={autoSuggestRef}
                            defaultValue={params.symbol}
                            handleSubmitCustom={requestData}
                        />
                        <WindowPieces.ConfigContainer>
                            <LoadButton onClick={requestData}>LOAD</LoadButton>
                        </WindowPieces.ConfigContainer>
                    </WindowPieces.OptionsContainer>

                    <WindowPieces.Content>
                        <ZenithTable1.Table 
                            tablehead={
                                <tr>
                                    <th>Form Type</th>
                                    <th>Filing URL</th>
                                    <th>Filing Date</th>
                                    <th>Accepted Date</th>
                                    <th>CIK</th>
                                </tr>
                            }
                            tablebody={
                                <>
                                    {data.map((item, index)=>{
                        
                                        return (
                                        <tr key={index}>
                                            <td>{item.type}</td>
                                            <td><a style={{color: "var(--primary)"}} target="_blank" rel="noopener noreferrer" href={item.link}>Filing URL</a></td>        
                                            <td>{item.fillingDate}</td>
                                            <td>{item.acceptedDate}</td>
                                            <td>{item.cik}</td>                                      
                                        </tr>
                                    )})}
                                </>
                            }
                        />  
                    </WindowPieces.Content>                 
                    <WindowPieces.PageViewer 
                        // currentPage={currentPage}
                        // totalPages={totalPages}
                        // changePageFunc={changePage}
                        // handlePageInput={handlePageInputChange}
                    />
               
                    

              
                    
                </>
            )}
        </>
    );
};

export default SECFIlings;
import React from "react";

const SettingsSelect = ({valueList, innerHTMLList, value, setFunc}) => {
    return (
        <select style={{backgroundColor: "var(--theme)", color: "var(--text)", borderColor: "var(--grid)"}} value={value} className="border" onChange={(e)=>setFunc(e.target.value)}>
            {valueList.map((value, index) => (
                <option key={index} value={value}>
                    {innerHTMLList[index]}
                </option>
            ))}
        </select>
    )
}

export default SettingsSelect
import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import constants from '../../../constants';
import getRootColorString from '../../../functions/helpers/getRootColorString';

const EarningsChart = ({ windowID,  earningsData }) => {
    Chart.defaults.font.family =  'Inter'
    const textColor = getRootColorString("--text")

    const chartContainerRef = useRef(null);

    const titleTooltip = (tooltipItems) => {
        if (tooltipItems.length > 0) {
            return `Date: ${tooltipItems[0].label}`;
        }
        
    };

    // Tooltip Labels: Display Actual EPS, Estimated EPS, and Percent Surprise
    const labelTooltip = (tooltipItems) => {
        console.log(tooltipItems)
        // const index = tooltipItems.dataIndex
        // const eps = earningsData[index].eps
        // const epsEstimated = earningsData[index].epsEstimated
        // const supriseAbsolute = eps - epsEstimated
        // const supriseRel = eps/epsEstimated - 1

        // return [
        //     `Actual: ${eps}`,
        //     `Estimated: ${epsEstimated}`,
        //     `Suprise: ${supriseAbsolute}`,
        //     `Suprise (%): ${supriseRel}`,

        // ]
    };

    useEffect(() => {
        if (!earningsData || earningsData.length === 0) return;

        // Reverse the data for chronological order

        // Prepare datasets
        const labels = earningsData.map(item => item.fiscalDateEnding);

        const epsData = earningsData.map(item => ({
            x: item.fiscalDateEnding,
            y: item.eps,
            backgroundColor: item.eps !== null && item.eps < item.epsEstimated ? getRootColorString("--negative") : getRootColorString("--positive"),
        })).filter(item => item.y !== null); // Filter out null EPS

        const epsEstimatedData = earningsData.map(item => ({
            x: item.fiscalDateEnding,
            y: item.epsEstimated,
        })).filter(item => item.y !== null); // Filter out null EPS estimates

        // Create chart
        const chart = new Chart(chartContainerRef.current, {
            type: 'scatter',
            data: {
                labels, // X-axis labels (fiscal dates)
                datasets: [
                    {
                        label: 'Actual EPS',
                        radius: 6,
                        pointHitRadius: 2,
                        data: epsData,
                        borderColor: epsData.map(item => item.backgroundColor), // Use dynamic coloring
                        backgroundColor: epsData.map(item => item.backgroundColor),
                        borderWidth: 1,
                        showLine: false,
                        pointStyle: 'circle',
                    },
                    {
                        label: 'Estimated EPS',
                        radius: 6,
                        pointHitRadius: 2,
                        data: epsEstimatedData,
                        borderColor: 'white', // Hollow white circle
                        backgroundColor: 'transparent',
                        showLine: false,
                        pointStyle: 'circle',
                        pointBorderWidth: 1, // Makes the circle hollow
                    },
                ],
            },
            options: {
                scales: {
                    x: {
                        type: 'category', // To properly display fiscal dates as labels
                        ticks: {
                            color: textColor,
                            autoSkip: true, // Adjust to avoid label clutter
                            maxRotation: 45,
                            minRotation: 0,
                        },
                        grid: {
                            display: false,
                            color: '#444',
                        },
                        title: {
                            display: true,
                            text: 'Fiscal Date',
                            color: textColor,
                        },
                    },
                    y: {
                        ticks: {
                            color: textColor,
                        },
                        grid: {
                            display: true,
                            color: '#444',
                        },
                        title: {
                            display: true,
                            text: 'EPS',
                            color: textColor,
                        },
                    },
                },
                interaction: {
                    intersect: false,
                    mode: 'x',
                },
                plugins: {
                    
                    htmlLegend: {
                        containerID: windowID+' '+'legend-container',
                      },
                    legend: {
                        display: false,
                        },
                    tooltip: {
                        ...constants.CHARTJS_TOOLTIP_STYLE,
                        callbacks: { 
                            title: titleTooltip,
                            label: labelTooltip
                        }
                    }
                },
                
                responsive: true,
                maintainAspectRatio: false,
            },
            plugins: [constants.CHARTJS_HTML_LEGEND_PLUGIN],
        });

        return () => {
            chart.destroy();
        };
    }, [earningsData]);

    return (
        <>
            <div id={windowID+' '+'legend-container'}></div>
            <div>
                <canvas ref={chartContainerRef} style={{ width: '200px', height: '300px'}}></canvas>
            </div>
        </>
    );
};

export default EarningsChart;

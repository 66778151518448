import React from "react";

const RemoveButton = ({onClick}) => {
    return (
        <span
            style={{
                position: 'absolute',
                right: '10px',
                color: 'red',
                cursor: 'pointer'
            }}
            onClick={onClick}
        >
            X
        </span>
    )
}

export default RemoveButton
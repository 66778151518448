import React, { useEffect} from 'react';  
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Terminal from './pages/Terminal';
import Logout from './pages/Logout';
import Checkout from './pages/Checkout';
import Admin from './pages/Admin';
import Success from './pages/Success';
import Cancel from './pages/Cancel';
import fetchCsrfToken from './functions/getCsrfToken';

function App() {

  useEffect(()=>{
    console.log("Fetching CSRF Token from Server!!!")
    // fetchCsrfToken()
  }, [])

  return (
    <Router>
      <Routes>
        {/* <Route path="/admin" element={<Admin />} /> */}


        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terminal" element={<Terminal />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/success" element={<Success />} />
        <Route path="/cancel" element={<Cancel />} />
      </Routes>
    </Router>
  );
}

export default App;

import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import LinkIconDropdown from './misc/LinkIconDropdown';
import features from '../catalogs/features';
import dashboardLayout from '../functions/memoryManagement/dashboardLayout';
import { getGlobalLayout, setGlobalLayout } from '../functions/memoryManagement/layoutManager';
import CloseWindowButton from './ui/small/buttons/CloseWIndowButton';

// async function loadLayout() {
//     try {
//       const loadedLayout = await dashboardLayout.getAndLoadLayout();
//       const layoutData = loadedLayout.layout_data || [];
//       return layoutData;
//     } catch (error) {
//       console.error("Failed to load layout:", error);
//       return [];
//     }
//   }
// const loadedLayout = await loadLayout();
// setGlobalLayout(loadedLayout);

const ZenithGridItem = ({
    focusStyleHeader,
    onMouseDownDragFunc, 
    windowType, 
    windowID,  
    initialLink, 
    newSessionLoad, 
    onRemove, 
    onLinkChange }) => {
    const contentRef = useRef(null);

    useEffect(()=>{
        if(newSessionLoad){
            const runHandler = async() =>{
                if (windowID) {
                    const windowElement = document.getElementById(windowID)
                    if (windowElement) {
                        // const handlerFunc = handlers[windowType]
                        const handlerFunc = features.find(feature=>feature.windowTypeID===windowType)?.handler
                        
                        if (handlerFunc) {
                            try{
                                // AAPL is here just for the sake of passing a param but it doesnt matter since the feature component
                                // Will just replace it with whatever is saved in the db.
                                // We DO NOT want this to run if this is NOT the initial load (since nothing will be saved in db)
                                // if this runs when NOT initial load, we will get AAPL regardless of the symbol we want
                                await handlerFunc("AAPL", false, windowID)
                            } catch (error) {
                                console.error("Error executing handler function: ", error)
                            }
                        }
                    }
                }
            }
            runHandler();
        }

    }, [])

    return (
        <>
            <div style={{}} className="window-header header-draggable-handle" onMouseDown={onMouseDownDragFunc}>
                <span className="d-flex flex-row align-items-center">
                    <span className="window-title text-nowrap">Window Title</span>

                    {/* {features.find(feature=>feature.windowTypeID===windowType)?.linkable && (
                        <LinkIconDropdown initialLink={initialLink} windowID={windowID} onLinkChange={onLinkChange}/>
                    )} */}

                </span>
                <span className="window-controls">
                    {/* <button className="btn-icon-dark">   
                        <div className="hover-text">
                            {
                            features.find(feature=>feature.windowTypeID===windowType)?.featureDescription
                            }
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14"/>
                        </svg>
                    </button> */}
                    {/* {features.find(feature=>feature.windowTypeID===windowType)?.exportable && (
                        <button
                            id="export-to-excel"
                            className="btn-icon-dark"
                            title="Export to Excel"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
                            </svg>
                        </button>
                    )} */}
                    <CloseWindowButton onClick={()=>onRemove(windowID)} />
                </span>
            </div>
            <div style={{borderColor: "var(--grid)", backgroundColor: "var(--theme)"}} className="position-relative w-100 h-100 window-content flex-grow-1 d-flex flex-column" ref={contentRef}>
                
            </div>  
        </>
    )
}



const ResizableDraggableChild = ({ 
    n, 
    windowID, 
    windowType, 
    containerRef, 
    initialX = 0, 
    initialY = 0, 
    initialWidth = 200, 
    initialHeight = 200,
    minWidth = 200,
    minHeight = 200,
    initialLink=null, 
    onFocus, 
    onRemove,
    onLinkChange, 
    focusStyleWindow, 
    focusStyleHeader,
    zIndex, 
    onUpdatePositionSize,
    isInitialLoad
    }) => {


    const [position, setPosition] = useState({ x: initialX, y: initialY });
    const [size, setSize] = useState({ width: initialWidth, height: initialHeight });
    
    // Use refs to store mutable values
    const positionRef = useRef(position);
    const sizeRef = useRef(size);

    const isDragging = useRef(false);
    const isResizing = useRef(false);
    const dragStartPos = useRef({ x: initialX, y: initialY });
    const resizeStartSize = useRef({ width: initialWidth, height: initialHeight });
    const resizeDirection = useRef("");


    // Handle drag start
    const handleDragStart = (e) => {
        e.stopPropagation();
        isDragging.current = true;
        dragStartPos.current = { x: e.clientX - position.x, y: e.clientY - position.y };
        document.addEventListener('mousemove', handleDrag);
        document.addEventListener('mouseup', handleDragEnd);
        onFocus(windowID); // Bring this element to the top
    };

    // Handle drag move with boundaries
    const handleDrag = (e) => {
        if (isDragging.current && containerRef.current) {
            const containerRect = containerRef.current.getBoundingClientRect();
            const newX = e.clientX - dragStartPos.current.x;
            const newY = e.clientY - dragStartPos.current.y;

            const boundedX = Math.max(0, Math.min(newX, containerRect.width - size.width));
            const boundedY = Math.max(0, Math.min(newY, containerRect.height - size.height));

            positionRef.current = { x: boundedX, y: boundedY }
            setPosition({ x: boundedX, y: boundedY });

        }
    };

    // Handle drag end
    const handleDragEnd = () => {
        isDragging.current = false;
        document.removeEventListener('mousemove', handleDrag);
        document.removeEventListener('mouseup', handleDragEnd);

    

        onUpdatePositionSize(windowID, { x: positionRef.current.x, y: positionRef.current.y, w: sizeRef.current.width, h: sizeRef.current.height });
    };

    // Handle resize start with direction
    const handleResizeStart = (e, direction) => {
        e.stopPropagation();
        isResizing.current = true;
        resizeStartSize.current = { width: size.width, height: size.height };
        dragStartPos.current = { x: e.clientX, y: e.clientY };
        resizeDirection.current = direction;
        document.addEventListener('mousemove', handleResize);
        document.addEventListener('mouseup', handleResizeEnd);
    };

    // Handle resize move with boundaries and direction
    const handleResize = (e) => {
        if (isResizing.current && containerRef.current) {
            const containerRect = containerRef.current.getBoundingClientRect();
            let newWidth = resizeStartSize.current.width;
            let newHeight = resizeStartSize.current.height;
            let newX = position.x;
            let newY = position.y;
            

            if (resizeDirection.current.includes("e")) {
                newWidth += e.clientX - dragStartPos.current.x;
            }

            if (resizeDirection.current.includes("s")) {
                newHeight += e.clientY - dragStartPos.current.y;
            }

            if (resizeDirection.current.includes("w")) {
                const deltaX = e.clientX - dragStartPos.current.x;
                if (newWidth - deltaX > minWidth) {
                    newWidth -= deltaX;
                    newX += deltaX;
                } else {
                    newWidth = minWidth;
                }
            }

            if (resizeDirection.current.includes("n")) {
                const deltaY = e.clientY - dragStartPos.current.y;
                if (newHeight - deltaY > minHeight) {
                    newHeight -= deltaY;
                    newY += deltaY;
                } else {
                    newHeight = minHeight;
                }
            }
    
            // Ensure size stays within container bounds
            const boundedWidth = Math.min(newWidth, containerRect.width - newX);
            const boundedHeight = Math.min(newHeight, containerRect.height - newY);
    
            setSize({
                width: Math.max(minWidth, boundedWidth),
                height: Math.max(minHeight, boundedHeight),
            });

            if (sizeRef.current) {
                sizeRef.current.width = Math.max(minWidth, boundedWidth);
                sizeRef.current.height = Math.max(minHeight, boundedHeight);
            }
    
            // Only update position if width/height are above minimum size
            if (newWidth > minWidth) {
                setPosition((prevPosition) => {
                    positionRef.current = { ...prevPosition, x: Math.max(0, newX) }
                    return ({ ...prevPosition, x: Math.max(0, newX) })
                });
                
            }
            if (newHeight > minHeight) {
                setPosition((prevPosition) => {
                    positionRef.current = { ...prevPosition, y: Math.max(0, newY) }
                    return ({ ...prevPosition, y: Math.max(0, newY) })
                });
            }
        }
    };

    // Handle resize end
    const handleResizeEnd = () => {
        isResizing.current = false;
        resizeDirection.current = "";
        document.removeEventListener('mousemove', handleResize);
        document.removeEventListener('mouseup', handleResizeEnd);

        // Call onUpdatePositionSize with latest values
        onUpdatePositionSize(windowID, { x: positionRef.current.x, y: positionRef.current.y, w: sizeRef.current.width, h: sizeRef.current.height });
    };

    return (
        <div
            id={windowID}
            data-window-type={windowType}
            className="shadow window d-flex flex-column zenith-resizable-draggable-child"
            style={{
                ...focusStyleWindow,
                width: size.width,
                height: size.height,
                transform: `translate(${position.x}px, ${position.y}px)`,
                zIndex: zIndex,
                
            }}
            onMouseDown={() => onFocus(windowID)}
        >
            <ZenithGridItem
                onMouseDownDragFunc={handleDragStart} 
                focusStyleHeader={focusStyleHeader}
                windowType={windowType}
                windowID={windowID}
                initialLink={initialLink}
                newSessionLoad={isInitialLoad}
                onRemove={onRemove}
                onLinkChange={onLinkChange}
            />
            
            <div className="zenith-resize-handle n" onMouseDown={(e) => handleResizeStart(e, 'n')} />
            <div className="zenith-resize-handle s" onMouseDown={(e) => handleResizeStart(e, 's')} />
            <div className="zenith-resize-handle e" onMouseDown={(e) => handleResizeStart(e, 'e')} />
            <div className="zenith-resize-handle w" onMouseDown={(e) => handleResizeStart(e, 'w')} />
            
            <div className="zenith-resize-handle nw" onMouseDown={(e) => handleResizeStart(e, 'nw')} />
            <div className="zenith-resize-handle ne" onMouseDown={(e) => handleResizeStart(e, 'ne')} />
            <div className="zenith-resize-handle sw" onMouseDown={(e) => handleResizeStart(e, 'sw')} />
            <div className="zenith-resize-handle se" onMouseDown={(e) => handleResizeStart(e, 'se')} />
        </div>
    );
};




const ResizableDraggableContainer = forwardRef(({ show, tabLayout = [], tabID, onSaveParentLayout }, ref) => {
    const containerRef = useRef(null);
    const [activeID, setActiveID] = useState(null);
    const [zIndexOrder, setZIndexOrder] = useState(tabLayout.map((window)=> window.windowID) || []);
    const [layout, setLayout] = useState(tabLayout || []);
    const [initialLoad, setInitialLoad] = useState(true); 
    const [pendingItemID, setPendingItemID] = useState(null); 
    const addItemResolveRef = useRef(null); 

    useEffect(() => {
        setInitialLoad(false);
    }, [layout]); 

    useEffect(() => {
        if (pendingItemID) {
            const newItem = layout.find((item) => item.windowID === pendingItemID);
            if (newItem) {
                // Once the new item is found in the layout, resolve the promise
                const element = document.getElementById(pendingItemID);
                if (element && addItemResolveRef.current) {
                    addItemResolveRef.current(element); // Resolve the promise
                    addItemResolveRef.current = null; // Clear the ref
                    setPendingItemID(null); // Clear pending ID
                }
            }
        }
    }, [layout, pendingItemID]);

    

    const handleFocus = (id) => {
        setZIndexOrder((prevOrder) => {
            const newOrder = prevOrder.filter((item) => item !== id);
            newOrder.push(id);

            // Update layout to match the new zIndexOrder
            setLayout((prevLayout) => {
                const updatedLayout = prevLayout.filter((item) => item.windowID !== id);
                const focusedItem = prevLayout.find((item) => item.windowID === id);
                if (focusedItem) updatedLayout.push(focusedItem);
                
                const layoutHasChanged = !prevLayout.every((item, index) =>
                    item === updatedLayout[index] ||
                    (item.windowID === updatedLayout[index]?.windowID &&
                     JSON.stringify(item) === JSON.stringify(updatedLayout[index]))
                );
    
                if (layoutHasChanged) {
                    onSaveParentLayout(tabID, updatedLayout)
            
                }


                return updatedLayout;
            });

            return newOrder;
        });
        setActiveID(id);
    };

    const handleUpdatePositionSize = (windowID, newPosSize) => {
        handleFocus(windowID)
        setLayout((prevLayout) => {
            const globalLayout = getGlobalLayout()
            const targetTab = globalLayout.find(tab=>tab.tabID === tabID)
            const targetTabLayout = targetTab.layout

            const updatedLayout = targetTabLayout.map((item) => 
                item.windowID === windowID ? { ...item, ...newPosSize } : item
            );
            console.log("Updated Layout: ", updatedLayout)
            const layoutHasChanged = !targetTabLayout.every((item, index) =>
                item === updatedLayout[index] || 
                (item.windowID === updatedLayout[index].windowID &&
                 JSON.stringify(item) === JSON.stringify(updatedLayout[index]))
            );

            if (layoutHasChanged) {
                onSaveParentLayout(tabID, updatedLayout)
            }

    
            return updatedLayout;
        });
    };

    const addItem = (itemsDict) => {
        console.log("Adding Item!")
        return new Promise((resolve, reject) => {
            try {
                setPendingItemID(itemsDict.windowID); // Track the new item ID
                addItemResolveRef.current = resolve; // Store resolve to use later

                setLayout((prevLayout) => {
                    const globalLayout = getGlobalLayout()
                    const targetTab = globalLayout.find(tab=>tab.tabID === tabID)
                    const targetTabLayout = targetTab.layout
                    const newId = targetTabLayout.length;
                    const newItem = {
                        x: 0,
                        y: 0,
                        w: features.find(feature=>feature.windowTypeID===itemsDict.windowType)?.minWidth,
                        h: features.find(feature=>feature.windowTypeID===itemsDict.windowType)?.minHeight,
                        n: newId,
                        link: null,
                        ...itemsDict,
                    };
                    const updatedLayout = [...targetTabLayout, newItem];
                    
                    
                    onSaveParentLayout(tabID, updatedLayout)
                    handleFocus(itemsDict.windowID);
                    return updatedLayout;
                });
            } catch (error) {
                reject("Failed to add item");
            }
        });
    };

    const handleRemove = (windowID) => {
        setLayout((prevLayout) => {
            const updatedLayout = prevLayout.filter((item) => item.windowID !== windowID)
            onSaveParentLayout(tabID, updatedLayout)
            return updatedLayout
        });
        setZIndexOrder((prevOrder) => prevOrder.filter((item) => item !== windowID));

    };
    
    const handleLinkChange = (windowID, color) => {
        setLayout((prevLayout) => {
            const updatedLayout = prevLayout.map((item) => 
                item.windowID === windowID 
                    ? { ...item, link: color }  
                    : item                       
            );
            onSaveParentLayout(tabID, updatedLayout)
            return updatedLayout;
        });
    }

    const handleContainerClick = (e) => {
        if (e.target === containerRef.current) {
            setActiveID(null);
        }
    };

    useImperativeHandle(ref, () => ({
        addItem,
    }));

    useEffect(() => {
        const handleKeyDown = (e) => {

            if (!show) return; 

            if (e.key === "Tab" && e.shiftKey) {
                e.preventDefault();
                cycleFocus();
            }
            // else if (e.shiftKey && e.code == 'KeyF') {
            //     console.log("dnbiwekjrfnewo")
            //     e.preventDefault();
            //     // This code will not work. It does set the right coordinate in the state but does not reposition
            //     // The window on the screen
            //     // const containerRect = containerRef.current.getBoundingClientRect();
            //     // handleUpdatePositionSize(activeID, {x: 0, y: 0, w: containerRect.width, h: containerRect.height})
            // }
            else if (e.key === "Escape") {
                closeActiveTab();
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [show, activeID, zIndexOrder, layout]);

    // useEffect(() => {
    //     const handleKeyDown = (e) => {
    //         if (!show || !activeID) return; // Ignore keypresses when container is hidden or no active child
    
    //         const container = containerRef.current;
    //         if (!container) return;
    
    //         const containerRect = container.getBoundingClientRect();
    
    //         const updateChildLayout = (id, newProps) => {
    //             handleUpdatePositionSize(id, newProps);
    //         };
    
    //         if (e.shiftKey) {
    //             switch (e.key) {
    //                 case "F": // Fullscreen
    //                     updateChildLayout(activeID, {
    //                         x: 0,
    //                         y: 0,
    //                         w: containerRect.width,
    //                         h: containerRect.height,
    //                     });
    //                     break;
    //                 case "1": // First half of the screen horizontally
    //                     updateChildLayout(activeID, {
    //                         x: 0,
    //                         y: 0,
    //                         w: containerRect.width / 2,
    //                         h: containerRect.height,
    //                     });
    //                     break;
    //                 case "2": // Second half of the screen horizontally
    //                     updateChildLayout(activeID, {
    //                         x: containerRect.width / 2,
    //                         y: 0,
    //                         w: containerRect.width / 2,
    //                         h: containerRect.height,
    //                     });
    //                     break;
    //                 default:
    //                     break;
    //             }
    //         }
    //     };
    
    //     window.addEventListener("keydown", handleKeyDown);
    //     return () => {
    //         window.removeEventListener("keydown", handleKeyDown);
    //     };
    // }, [show, activeID, handleUpdatePositionSize]);

    const cycleFocus = () => {
        if (layout.length === 0) return;

        setActiveID((prevActiveID) => {
            const currentIndex = zIndexOrder.indexOf(prevActiveID);
            const nextIndex = (currentIndex + 1) % zIndexOrder.length;
            const nextID = zIndexOrder[nextIndex];

            // Bring the newly focused tab to the front
            handleFocus(nextID);
            return nextID;
        });
    };

    const closeActiveTab = () => {
        if (activeID) {
            handleRemove(activeID);
        }
    };

    return (
        <div 
            className={`zenith-grid-container flex-grow-1 ${show? 'd-block': 'd-none'}`} 
            style={{
                backgroundColor: 'var(--grid)'
            }}
            ref={containerRef}
            onClick={handleContainerClick}
        >
            {layout.map((item) => {

                return (
                <ResizableDraggableChild
                    key={item.n}
                    n={item.n}
                    windowID={item.windowID}
                    windowType={item.windowType}
                    containerRef={containerRef}
                    initialX={item.x}
                    initialY={item.y}
                    initialWidth={item.w}
                    initialHeight={item.h}
                    minWidth={features.find(feature=>feature.windowTypeID===item.windowType)?.minWidth}
                    minHeight={features.find(feature=>feature.windowTypeID===item.windowType)?.minHeight}
                    initialLink={item.link}
                    onFocus={handleFocus}
                    onRemove={handleRemove}
                    focusStyleWindow={activeID === item.windowID ? 
                        { 
                            borderColor: 'var(--primary)', 
                            boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px' 
                        } : {}
                        }
                    focusStyleHeader={activeID === item.windowID?
                        {
                            backgroundColor: '#262626',
                            color: 'white'
                        } : {}
                    }
                    zIndex={zIndexOrder.indexOf(item.windowID)}
                    onLinkChange={handleLinkChange}
                    onUpdatePositionSize={handleUpdatePositionSize}
                    isInitialLoad={initialLoad}
                />
            )})}
        </div>
    );
});

export default ResizableDraggableContainer;




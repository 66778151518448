import React, { useState, useEffect, useRef, forwardRef} from "react";
import WindowPieces from "../../ui/small/WindowPieces";
import debounce from "../../../functions/helpers/debounce";
import SuggestionMenu from "../suggestionscontainers/SuggestionMenu";
import handleCommandEntry from "../../../functions/handleCommandEntry";
import features from "../../../catalogs/features";
import axios from "axios";
import constants from "../../../constants";

const AutosuggestCommandLine = forwardRef(({
    id,
    value,
    handleSubmitCustom,
    forTerminal=false,
    setShowFunctionMenu, 
    setFunctionMenuSymbol,
    defaultValue
}, ref) => {
    
    const DEBOUNCE_DELAY = 300;
    const MAX_RESULTS = 5;

    let timeoutId = null;
    const [activeInputElement, setActiveInputElement] = useState(null)
    const [activeSuggestionType, setActiveSuggestionType] = useState(null)

    const [securitySuggestions, setSecuritySuggestions] = useState([])
    const [functionSuggestions, setFunctionSuggestions] = useState([])
    const [nonSecurityFunctionSuggestions, setNonSecurityFunctionSuggestions] = useState([])

    const [showSuggestionsMenu, setShowSuggestionsMenu] = useState(false)
    const [highlightedIndex, setHighlightedIndex] = useState(0)
    const [inputValue, setInputValue] = useState(value || "");
    
    const lastRequestId = useRef(0);


    function highlightSuggestion(items, index) {
        const INACTIVE_CLASS = 'suggestion-inactive'
        const ACTIVE_CLASS = 'suggestion-active'

        items.forEach(item => {
            item.classList.remove(ACTIVE_CLASS)
            item.classList.add(INACTIVE_CLASS)
        })
        
        // Highlight target item
        const targetItem = items[index]
        if (targetItem) {
            targetItem.classList.remove(INACTIVE_CLASS)
            targetItem.classList.add(ACTIVE_CLASS)
            
            const suggestionType = targetItem.getAttribute('data-suggestion-type')
            setActiveSuggestionType(suggestionType)
        }
    }

    function updateInput(items, index) {
        const targetItem = items[index];
        const suggestionValue = targetItem.getAttribute('data-suggestion-value');
        
        setInputValue(suggestionValue);
    }
    
    async function displaySuggestions({query, inputElement}){
        const currentRequestId = ++lastRequestId.current;
        const securityQuery = query.trim().split(' ')[0]
        const response = await axios.get(constants.BACKEND_URL + '/query-symbols/', {
            params: {
                symbol: securityQuery, 
                maxResults: MAX_RESULTS  
            }
        });

        if (currentRequestId === lastRequestId.current) {
            let securities = await response.data.data;
            securities=securities.map(obj=>{
                return {
                    'symbol': obj.symbol,
                    'name': obj.name,
                    'exchangeShortName': obj.exchangeShortName,
                    'suggestionType': 'security'
                }
            })
            const preFunctionQuery = query.trim().split(' ')[0]
            const functionQuery = query.trim().split(' ')[1]
            
            // Non-security Functions
            let nonSecurityFunctionsToSuggest = []
            if (preFunctionQuery) {
                const nonSecurityFunctionsFiltered = features.filter(feature=>
                    (feature.code.startsWith(preFunctionQuery) || feature.code.startsWith(preFunctionQuery.toUpperCase())) && (!feature.securityFunction)
                )
                nonSecurityFunctionsToSuggest = nonSecurityFunctionsFiltered.slice(0, MAX_RESULTS)
            }
            setNonSecurityFunctionSuggestions([])
            setNonSecurityFunctionSuggestions(nonSecurityFunctionsToSuggest)
            setHighlightedIndex(0)
            
            // Functions
            const DEFAULT_FUNCTIONS = [
                'window-tear-sheet',
                'window-financial-statements',
                'window-historical-price',
                'window-earnings-transcripts',
                'window-company-news'
            ];
            let functionsToSuggest = features.filter(item=>DEFAULT_FUNCTIONS.includes(item.windowTypeID))
            if (functionQuery) {
                const functionsFiltered = features.filter(feature=>
                    (feature.code.startsWith(functionQuery) || feature.code.startsWith(functionQuery.toUpperCase())) && (feature.securityFunction)
                )
                functionsToSuggest = functionsFiltered.slice(0, MAX_RESULTS)
            }
            setFunctionSuggestions([])
            setFunctionSuggestions(functionsToSuggest)
            setHighlightedIndex(0)
    
         
            // Securities
            setSecuritySuggestions([])
            setSecuritySuggestions((prevSuggestionList)=>{
                const newSuggestionsList=securities.slice(0,MAX_RESULTS)
                setHighlightedIndex(0)
                const suggestionElements = Array.from(document.querySelectorAll('.suggestion-item'));
                if (newSuggestionsList.length > 0) {
                    highlightSuggestion(suggestionElements, 0)
                }
                return newSuggestionsList
            })

        }

    }

    const handleInput = (e) => {
        e.stopPropagation()
        const query = e.target.value;
        if (query.length > 0) {
            debounce(()=> displaySuggestions({ query, inputElement: e.target }), DEBOUNCE_DELAY)();
           
        } else {
            setSecuritySuggestions([])
            setFunctionSuggestions([])
            setHighlightedIndex(0)

        }

    }

    const handleKeyDown = (e) => {
        if (e.key == "Enter") {
            e.stopPropagation()
            
            const userOnlyEnteredSecurity = inputValue.trim().split(" ").length == 1 
            
            if (userOnlyEnteredSecurity) {
                if (forTerminal) {
                    setSecuritySuggestions([])
                    setFunctionSuggestions([])
                    setNonSecurityFunctionSuggestions([])
                    
                    console.log(activeSuggestionType)

                    if (activeSuggestionType==='function' || false) {
                        const command = inputValue.trim().split(" ")
                        const function_ = command[0].toUpperCase()
                        handleCommandEntry(function_, "")
                        handleBlur()
                    } else {
                        handleBlur()
                        setShowFunctionMenu(true)
                        setFunctionMenuSymbol({
                            symbol: securitySuggestions[0].symbol,
                            name: securitySuggestions[0].name
                        })
                    }

                }
                else {
                    // const uniqueWindowElement =  e.target.closest('.window')
                    // const uniqueWindowType = uniqueWindowElement.getAttribute('data-window-type')
                    // const uniqueWindowElementID = uniqueWindowElement.id

                    // const handler = handlers[uniqueWindowType];
                    // if (handler) {
                    //     handler(inputValue, false, uniqueWindowElementID);
                    // }
                    handleSubmitCustom()

                }
            }
            else {
                const command = inputValue.trim().split(" ")
                const function_ = command[1].toUpperCase()
                const symbol = command[0] 
                if (forTerminal) {
                    handleCommandEntry(function_, symbol)
                }
                else{
                    const existingWindowID = e.target.closest('.window').id
                    handleCommandEntry(function_, symbol, false, existingWindowID)
                }
            }

            
        }
        else {
            e.stopPropagation()
            if (e.keyCode==40 && securitySuggestions.length > 0) {
                e.preventDefault()
                const suggestionElements = Array.from(document.querySelectorAll('.suggestion-item'))
                setHighlightedIndex((prevIndex)=>{
                    const newIndex = (prevIndex+1) % suggestionElements.length
                    highlightSuggestion(suggestionElements, newIndex)
                    updateInput(suggestionElements, newIndex)
                    return newIndex
                })
              
            }

            else if (e.keyCode === 38 && securitySuggestions.length > 0) {
                e.preventDefault(); 
                const suggestionElements = Array.from(document.querySelectorAll('.suggestion-item'));
                setHighlightedIndex((prevIndex)=>{
                    const newIndex = (prevIndex-1+suggestionElements.length) % suggestionElements.length
                    highlightSuggestion(suggestionElements, newIndex)
                    updateInput(suggestionElements, newIndex)
                    return newIndex
                })
               
            }

        }
    }

    const handleFocus = (e) => {
        setSecuritySuggestions([])
        setFunctionSuggestions([])
        setNonSecurityFunctionSuggestions([])
        setHighlightedIndex(0)
    }

    const handleBlur = (e)=> {
    
        setSecuritySuggestions([])
        setFunctionSuggestions([])
        setNonSecurityFunctionSuggestions([])
        setHighlightedIndex(0)
        setActiveInputElement(null)
        setInputValue("")
    
        
    }


    return(
        <div className="position-relative flex-grow-4 m-1">
            <div className="d-flex flex-row border-primary text-primary">
                <div style={{color:"var(--primary)", paddingLeft: '0.1em', lineHeight: '0em', fontSize: '1.5em', marginRight: "4px", display: 'flex', alignItems: "center"}}>&#36;</div>
                <input 
                    ref={ref}
                    id={id || undefined}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onInput={(e) => handleInput(e)}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    defaultValue={defaultValue || undefined}
                    placeholder="Press / for Command Line"
                    style={{
                        border: 'none',
                        textTransform: 'uppercase',
                        borderRadius: WindowPieces.borderRadius, 
                        padding: '0px',
                        width: '100%',
                        minWidth: '500px',
                        fontSize: '1.5em',
                        background: "var(--theme)",
                        color: "var(--text)"
                    }}
                    type="text"
                    autoComplete="off"
                />
            </div>
            {
                inputValue.length > 0 &&  (
                    <SuggestionMenu 
                        securitySuggestions={securitySuggestions} 
                        functionSuggestions={functionSuggestions} 
                        nonSecurityFunctionSuggestions={nonSecurityFunctionSuggestions}
                    />
                )
            }
            
            
        </div>
    )


})

export default AutosuggestCommandLine
import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import constants from '../../../constants';
import getRootColorString from '../../../functions/helpers/getRootColorString';
import SettingsConfigButton from '../../ui/small/buttons/SettingsConfigButton';

const ComboChart = ({ windowID, data, labels, ticker }) => {
    const [showSettings, setShowSettings] = useState(false)

    Chart.defaults.font.family =  'Inter'
    const textColor = getRootColorString("--text")
    
    const chartContainerRef = useRef(null)
    const colors = constants.COLORS

    const allDataContents = Object.values(data).flatMap(entry => entry.dataContent);
    const maxValue = Math.max(...allDataContents)*1.2;

    const dataset=Object.entries(data).map(([dict_key, dict_value], index)=> {
        const color = colors[index % colors.length];
        const dataType = dict_value['dataType']
        const dataContent = dict_value['dataContent']
        
        return {
            type: dataType.includes('percent') ? 'line' : 'bar', 
            label: dict_key,
            data: dataContent,
            borderColor: dataType.includes('percent') ? color: '',
            borderWidth: dataType.includes('percent') ? 2: 0,
            backgroundColor: `${color}`,
            yAxisID: dataType.includes('percent') ? 'y1' : 'y2', 
            barPercentage: 1,
            // categoryPercentage: 1
        }
    })

    const sortedDatasets = [
        ...dataset.filter(ds => ds.type === 'line'), // Line datasets last
        ...dataset.filter(ds => ds.type === 'bar')  // Bar datasets first
    ];

    

    useEffect(() => {
        if (!data || !labels) return;

        const chart = new Chart(chartContainerRef.current, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: sortedDatasets
            },
            options: {
                scales: {
                    y1: {
                        type: 'linear',
                        position: 'left',
                        ticks: {
                            callback: function(value) {
                                return (value * 100).toFixed(2) + '%'; // Format as percentage
                            },
                            color: textColor, // Optional: styling
                        },
                        grid: {
                            display: false,
                            color: 'red'
                        }
                    },
                    y2: {
                        type: 'linear',
                        position: 'right',
                        max: maxValue,
                        ticks: {
                            color: textColor,
                        },
                        grid: {
                            display: false,
                            drawOnChartArea: false, // Prevent grid lines for the second axis
                        },
                       
                    },
                    x: {
                        ticks: {
                            color: textColor,
                        },
                        grid: {
                            display: false,
                            color: '#444',
                        }
                    }
                },
                plugins: {
                    htmlLegend: {
                        containerID: windowID+' '+'legend-container',
                      },
                    legend: {
                        display: false,
                        },
                    watermark: {
                        text: ticker.toUpperCase(),
                        fontSize: 24,
                        color: '#DDD',
                        x: 'center',
                        y: 'center',
                        align: 'center',
                    },
                    tooltip: {
                        ...constants.CHARTJS_TOOLTIP_STYLE,
                        callbacks: {
                            label: function (tooltipItem) {
                                const datasetIndex = tooltipItem.datasetIndex;
                                const yAxisID = tooltipItem.chart.data.datasets[datasetIndex].yAxisID;
            
                                if (yAxisID === 'y1') {
                                    return `${tooltipItem.dataset.label}: ${(tooltipItem.raw * 100).toFixed(2)}%`;
                                } else {
                                    return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
                                }
                            },
                        },
                    }
                },
                responsive: true,
                maintainAspectRatio: false
            },
            plugins: [constants.CHARTJS_HTML_LEGEND_PLUGIN],
        });

        return () => {
            chart.destroy();
        };
    }, [data, labels, ticker]);

    return (
        <>
            <div id={windowID+' '+'legend-container'}></div>
            <div>
                <canvas ref={chartContainerRef} style={{ width: '200px', height: '300px'}}></canvas>
                {/* <div
                style={{
                    position: 'absolute',
                    right: '63px',
                    top: '38px',
                    zIndex: '3',
                    fontSize: '14px',
                    lineHeight: "18px",
                    fontWeight: '300'
                }}
                >
                    <SettingsConfigButton label={"SETTINGS"} onClick={()=>setShowSettings(true)}/>
                </div> */}
            </div>
        </>
    );
};

export default ComboChart;
import prepareWindow from "../../windowCreating/prepareWindow";
import React from "react";
import ReactDOM from "react-dom";
import InstitutionalPortfolioComposition from "../../../components/FeatureComponents/19_InstitutionalPortfolioComposition/19_InstitutionalPortfolioComposition";

async function handleInstitutionalPortfolioComposition(symbol, isInitialDisplay=true, existingWindowID="") {
    const WINDOW_TYPE_NAME = 'window-institutional-portfolio-composition';
    const TITLE_PREFIX = 'Institutional Portfolio Composition ';

    const window = await prepareWindow(symbol, WINDOW_TYPE_NAME, TITLE_PREFIX, isInitialDisplay, existingWindowID)
    const destinationElement = window.querySelector('.window-content')

    ReactDOM.render(<InstitutionalPortfolioComposition cik={symbol} windowID={window.id} newWindow={isInitialDisplay}/>, destinationElement)
}

export default  handleInstitutionalPortfolioComposition 
import React, {useRef} from "react";
import TearSheetSectionHeader from "./TearSheetSectionHeader";
import LineWrappers from "./LineWrappers";
import OpenWindowAlternativeButton from "../../ui/small/OpenWindowAlternativeButton";
import OpenWindowAlternativeButtonWide from "../../ui/small/OpenWindowAlternativeButtonWide";
// import TVLWLineChartArea from "../charting/tvlw/TVLW_LineChartArea";
import LineAreaChart from "../../charting/tvlw/LineAreaChartEmbedded";
import handleHistoricalPrice from "../../../functions/requestFuncs/handling/handleHistoricalPrice";
import handleFinancialStatements from "../../../functions/requestFuncs/handling/handleFinancialStatements";
import PerformanceBlock from "./PerformanceBlock";
import Button1 from "../../ui/small/buttons/Button1";

const TearSheetStructure = ({ sheet, priceData }) => {
    const chartContainerRef = useRef()

    return (

        <div className="container-fluid m-0 w-100 h-100 p-3">
            <div className="row">
                <h1>{sheet.symbol} - {sheet.companyName}</h1>
                <div>Sector: {sheet.sector} | Industry: {sheet.industry}</div>
            </div>
            <hr style={{borderTop: "3px solid #bbb"}}></hr>

            <div className="row mt-2">
                <div className="col-md-3">
                    <div className="mb-3">
                        <TearSheetSectionHeader text="SUMMARY" />
                        <LineWrappers.LineWrapper name={"Mkt. Cap."}  value={sheet.mktCap}/>
                        <LineWrappers.LineWrapper name={"Beta"}  value={sheet.beta}/>
                        <LineWrappers.LineWrapper name={"Daily Volume"}  value={sheet.volAvg}/>
                    </div>
                    
                    <div className="mb-3">
                        <TearSheetSectionHeader text="KEY RATIOS" />
                        <LineWrappers.LineWrapper name={"P/E"}  value={sheet.ttmPE}/>
                        <LineWrappers.LineWrapper name={"EPS"}  value="N/A"/>
                        <LineWrappers.LineWrapperPercent name={"Dividend Yield"}  value="N/A"/>
                        <LineWrappers.LineWrapper name={"LTM Debt/Equity"}  value={sheet.ttmDE}/>
                    </div>
                    <div className="mb-3">
                        <TearSheetSectionHeader text="RECENT PERFORMANCE" />
                        <div className="container-fluid m-0">
                            <PerformanceBlock label={"1W"} performancevalue={sheet.week_1} />
                            <PerformanceBlock label={"1M"} performancevalue={sheet.month_1} />
                            <PerformanceBlock label={"3M"} performancevalue={sheet.month_3} />
                            <PerformanceBlock label={"6M"} performancevalue={sheet.month_6} />
                            <PerformanceBlock label={"YTD"} performancevalue={sheet.ytd} />
                            <PerformanceBlock label={"1Y"} performancevalue={sheet.year_1} />
                        </div>
                    </div>
                </div>


                <div className="col-md-9">
                    <div>
                        <div className="d-flex justify-content-between mb-1">
                            <TearSheetSectionHeader text="HISTORICAL PRICE" />
                            <Button1 onClick={()=>handleHistoricalPrice(sheet.symbol)}>Open Chart</Button1>
                            {/* <OpenWindowAlternativeButton label={"Open Historical Chart Window"} onClick={() => handleHistoricalPrice(sheet.symbol)} /> */}
                        </div>
                    </div>
                    <div ref={chartContainerRef} style={{height: '400px'}}>
                        <LineAreaChart lineData={priceData} symbol={sheet.symbol} contentContainerRef={chartContainerRef}/>
                    </div>
                </div>
            </div>

            

            <div className="row mt-2">
                    <div className="col mb-3">
                        <TearSheetSectionHeader text="GROWTH" />
                        <LineWrappers.LineWrapperPercent name={"3-Yr Revenue CAGR"}  value={sheet.cagrRevenue}/>
                        <LineWrappers.LineWrapperPercent name={"3-Yr EBITDA CAGR"}  value={sheet.cagrEBITDA}/>
                        <LineWrappers.LineWrapperPercent name={"3-Yr EBIT CAGR"}  value={sheet.cagrEBIT}/>
                        <LineWrappers.LineWrapperPercent name={"3-Yr Net Income CAGR"}  value={sheet.cagrNI}/>
                        <LineWrappers.LineWrapperPercent name={"3-Yr FCF CAGR"}  value={sheet.cagrFCF}/>
                    </div>
                    <div className="col mb-3">
                        <TearSheetSectionHeader text="EFFICIENCY" />
                        <LineWrappers.LineWrapperPercent name={"Gross Margin"}  value={sheet.ttmGrossMargin}/>
                        <LineWrappers.LineWrapperPercent name={"EBIT Margin"}  value={sheet.ttmEbitMargin}/>
                        <LineWrappers.LineWrapperPercent name={"Net Margin"}  value={sheet.ttmNetMargin}/>
                        <LineWrappers.LineWrapperPercent name={"ROA"}  value={sheet.ttmROA}/>
                        <LineWrappers.LineWrapperPercent name={"ROE"}  value={sheet.ttmROE}/>
                        <LineWrappers.LineWrapperPercent name={"ROIC"}  value={sheet.ttmROIC}/>
                    </div>
                    <div className="col mb-3">
                        <TearSheetSectionHeader text="MULTIPLES" />
                        <LineWrappers.LineWrapper name={"P/B"}  value={sheet.ttmPriceBook}/>
                        <LineWrappers.LineWrapper name={"P/Sales"}  value={sheet.ttmPriceSales}/>
                        <LineWrappers.LineWrapper name={"P/FCF"}  value={sheet.ttmPriceFCF}/>
                        <LineWrappers.LineWrapper name={"EV/EBITDA"}  value={sheet.ttmEVEbitda}/>
                        <LineWrappers.LineWrapper name={"EV/Sales"}  value={sheet.ttmEVSales}/>
                        <LineWrappers.LineWrapper name={"EV/FCF"}  value={sheet.ttmEVFCF}/>
                    </div>
            </div>
            <Button1 onClick={()=>handleFinancialStatements(sheet.symbol)} fullWidth={true}>View {sheet.symbol} Financials</Button1>

            <div className="row mt-2">
                <div>
                    <TearSheetSectionHeader text="COMPANY DESCRIPTION" />
                    </div>
                <div>
                    {sheet.description}
                </div>

            </div>
        </div>



      
      );
};

export default TearSheetStructure
// import React, { useState} from "react";
// import CustomModal2 from "../CustomModal2";
// import Button1 from "../../ui/small/buttons/Button1/Button1";

// const ChooseColorModal = ({ show, setShow, onColorChoice }) => {
//     const [activeColor, setActiveColor] = useState("#212121")

//     return (
//         <CustomModal2
//             show={show}
//             title={"Set Color"}
//             body={
//                 <div style={{ width: "400px", textAlign: "center" }}>
//                     <div class="">
//                         <label htmlFor="color-input">Input Color</label>
//                         <input
//                             name="color-input"
//                             type="color"
//                             onChange={(e) => {
//                                 setActiveColor(e.target.value); // Pass the selected color
//                             }}
//                             style={{
//                                 height: "30px",
//                                 width: "30px",
//                                 padding: "0px",
//                                 background: "none",
//                                 cursor: "pointer",
//                                 appearance: "none", // Remove default styles
//                                 WebkitAppearance: "none", // Remove WebKit-specific styles
//                             }}
//                         />
//                     </div>
//                     <Button1 onClick={()=>{
//                         onColorChoice(activeColor)
//                         setShow(false)
//                         }}>Confirm</Button1>
//                 </div>
//             }
//             onClose={() => {
//                 setShow(false)
//             }}
//         />
//     );
// };

// export default ChooseColorModal;

import React from "react";
import CustomModal2 from "../CustomModal2";

const ChooseColorModal = ({show, setShow, onColorChoice}) => {
    const height = '20px';
    const width = '20px';

    const palleteColors = [
        '#F87168',
        '#FEA362',
        '#E2B203',
        '#94C748',
        '#4BCE97',
        '#6CC3E0',
        '#579DFF',
        '#9F8FEF',
        '#E774BB',
        '#B3B9C4'
    ];
  
    return (
        <CustomModal2 
            show={show}
            title={"Set Color"}
            body={
                <>
                    <div style={{width: '400px'}} className="d-flex flex-wrap">
                        {palleteColors.map((color, index) => (
                            <div
                            key={index}
                            onClick={() => {
                                onColorChoice(color)
                                setShow(false)
                            }}
                            className="m-1"
                            style={{ height: height, width: width, backgroundColor: color }}
                            ></div>
                        ))}
                    </div>
                </>
            }
            onClose={()=>setShow(false)}
        />
    )
}

export default ChooseColorModal
import React, { useState, useEffect, useRef, forwardRef} from "react";
import AutosuggestSecurities from "./AutosuggestSecurities_v3";

const AutosuggestSecuritiesModal = forwardRef(({
    id,
    value,
    handleSubmitCustom,
    defaultValue
}, ref) => {
    
    
    return(
        <div className="position-relative">
            <AutosuggestSecurities 
                id={id}
                value={value}
                handleSubmitCustom={handleSubmitCustom}
                defaultValue={defaultValue}
            />
        </div>
    )


})

export default AutosuggestSecuritiesModal
import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import LoadingSpinner from "../../misc/LoadingSpinner";
import WindowPieces from "../../ui/small/WindowPieces";
import WindowError from "../../misc/WindowError";
import dashboardLayout from "../../../functions/memoryManagement/dashboardLayout";
import { getGlobalLayout, updateLayoutItem } from "../../../functions/memoryManagement/layoutManager";
import constants from "../../../constants";
import ZenithTable1 from "../../ui/small/tables/ZenithTable1";
import ZenithTable1Sortable from "../../ui/small/tables/ZenithTable1Sortable";


const InstitutionalPortfolioComposition = ({ cik, windowID, newWindow }) => {
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 1. DEFAULT CONSTANTS
    const DEFAULT_PARAMS = {
        'cik': cik,
        'date': '2024-06-30'
    };
    const ENDPOINT = '/institutional-portfolio-composition/';
    
    let windowParameters
    if (newWindow) {
        const layout = getGlobalLayout();
        const windowParameters = layout.find(layout => layout.windowID === windowID)?.windowParameters;
    
        console.log(layout)
        console.log(windowParameters)
    }

    const paramsToUse = windowParameters || DEFAULT_PARAMS;
    

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 2. STATES - Most Windows will have a params, loading state. Ones with tabs will have an activeTabID state

    const [params, setParams] = useState(paramsToUse);
    const [loading, setLoading] = useState(true); // New loading state
    const [error, setError] = useState(null); // Error state
    const [data, setData] = useState({});
    const [initialRender, setInitialRender] = useState(true);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 3. 

    const requestData = () => {

        setParams((prevParams) => {
            const newParams = {
                ...prevParams,
            };
            
            // Update Database
            const layout = getGlobalLayout();
            updateLayoutItem(windowID, {
                symbol: cik ? cik : "N/A",
                windowParameters: newParams,
            });
            dashboardLayout.saveLayout(layout);
    
            // Call the API with the updated params
            setLoading(true); // Start loading
            setError(null); 
            axios.post(constants.BACKEND_URL+ENDPOINT, newParams) // Use newParams here
                .then(response => {
                    const parsedResponse = JSON.parse(response.data.data);
                    
                    setData(parsedResponse);
                })
                .catch(error => {
                    console.error('Error during POST request:', error);
                    setError("Failed to load financial statements.");
                })
                .finally(() => {
                    setLoading(false); // Stop loading
                });
    
            return newParams; // Return the new params for state update
        });

        if (initialRender) setInitialRender(false);
    };

    useEffect(() => {
        requestData();  // Initial request
    }, []);


    const updateParams = (e) => {
        const { id, value } = e.target;
    
        // Update the params by setting the new value for the key that matches the input's id
        setParams((prevParams) => ({
            ...prevParams,
            [id]: value
        }));
    }

   
    const cols = [
        { key: "symbol", label: "Symbol" },
        { key: "securityName", label: "Symbol Name" },
        { key: "industryTitle", label: "Industry" },
        { key: "weight", label: "Weight" },
        { key: "sharesNumber", label: "# of Shares" },
        { key: "avgPricePaid", label: "Average Price" },
        { key: "ownership", label: "% Shares Outstanding" },
        { key: "firstAdded", label: "First Added" },
        ];        
    

    
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 4. 
    

    return (
        <>
            {loading ? ( // Conditional rendering for loading spinner
                <LoadingSpinner />
            ) : error ? (
                <WindowError message={error}/>
            ) : (
                <>
                    <WindowPieces.Content>
                        <div className="p-2" style={{color: "var(--primary)"}}>{data[0].investorName} Holdings</div>

                        <ZenithTable1Sortable 
                            columns={cols}
                            data={data}
                        />


                        {/* <ZenithTable1.Table 
                            tablehead={
                                <tr>
                                    <th>Symbol</th>
                                    <th>Security Name</th>
                                    <th>Industry</th>
                                    <th>Weight (%)</th>
                                    <th># of Shares</th>
                                    <th>Average Price Paid</th>
                                    <th>% of Shares Outstanding Owned</th>
                                    <th>Date first Added</th>
                                    
                                </tr>
                            }
                            tablebody={
                                <>
                                    {data.map((item, index)=>{
                                    return (
                                    <tr key={index}>
                                        <td>{item.symbol}</td>
                                        <td>{item.securityName}</td>
                                        <td>{item.industryTitle}</td>
                                        <td>{item.weight}</td>
                                        <td>{item.sharesNumber}</td>
                                        <td>{item.avgPricePaid}</td>
                                        <td>{item.ownership}%</td>
                                        <td>{item.firstAdded}</td>                                    
                                    </tr>
                                )})}
                                </>
                            }
                        /> */}
                     
                    </WindowPieces.Content>
                </>
            )}
        </>
    );
};

export default InstitutionalPortfolioComposition;
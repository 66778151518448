import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import constants from '../../../constants';

const LineChartMultiSeries = ({ data, chartID, initialAppear=true }) => {
    const companyNames = Object.keys(data);
    const numberOfColumns = data[companyNames[0]].length;
    const numColumnsArrayFunc = (n) => Array.from({ length: n }, (_, i) => i);
    const labels = numColumnsArrayFunc(numberOfColumns);
   

    const chartContainerRef = useRef();

    const colors = constants.COLORS

    const dataset=Object.entries(data).map(([dict_key, dict_value], index)=> {
        const color = colors[index % colors.length];

        return {
            type: 'line', // if percentage this is 'line' not bar
            label: dict_key,
            data: dict_value,
            borderColor: color,
            borderWidth: 2,
            backgroundColor: `${color}75`,
        }
    })



    useEffect(() => {
        const chart = new Chart(chartContainerRef.current, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: dataset
            },
            options: {
                scales: {
              
                    y: {
                        type: 'linear', 
                        position: 'right',
                        ticks: {
                            color: '#DDD',
                        },
                        grid: {
                            drawOnChartArea: false, // Prevent grid lines for the second axis
                        }
                    },
                    x: {
                        ticks: {
                            color: '#DDD',
                        },
                        grid: {
                            color: '#444',
                        }
                    }
                },
                plugins: {
                    legend: {
                        labels: {
                            color: '#DDD',
                        }
                    },
                    tooltip: {
                        ...constants.CHARTJS_TOOLTIP_STYLE
                    }
                },
                responsive: true,
                maintainAspectRatio: false
            }
        });

        // Resize handling
        const resizeObserver = new ResizeObserver(() => {
            chart.resize();
        });

        resizeObserver.observe(chartContainerRef.current);

        return () => {
            resizeObserver.disconnect();
            chart.destroy();
        };
    }, [data, labels]);

    if (initialAppear){
        return <canvas id={chartID} ref={chartContainerRef} style={{ width: '100%', height: '500px' }} />;
    }
    else {
        return <canvas id={chartID} ref={chartContainerRef} className='d-none' style={{ width: '100%', height: '500px' }} />;
    }
};

export default LineChartMultiSeries;

import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import LoadingSpinner from "../../misc/LoadingSpinner";
import WindowPieces from "../../ui/small/WindowPieces";
import WindowError from "../../misc/WindowError";
import AutosuggestSecurities from "../../autosuggest/searchbars/AutosuggestSecurities_v3";
import dashboardLayout from "../../../functions/memoryManagement/dashboardLayout";
import { getGlobalLayout, updateLayoutItem } from "../../../functions/memoryManagement/layoutManager";
import constants from "../../../constants";
import DCFComponents from "./DCFComponents";
import LoadButton from "../../ui/small/buttons/LoadButton";

const DCF = ({ symbol, windowID, newWindow }) => {
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 1. DEFAULT CONSTANTS
    const DEFAULT_PARAMS = {
        'symbol': symbol,
        'historicalPeriods': 3,
        'periodType': 'annual',
        'divider': 'millions'
    };
    const ENDPOINT = '/dcf/';
  
    const globalLayout = getGlobalLayout();
    const layout = globalLayout.find(tab =>
        tab.layout.find(window => window.windowID === windowID)
    )
    const window = layout?.layout.find(window=>window.windowID === windowID)
    const windowParameters = window?.windowParameters
    const paramsToUse = windowParameters || DEFAULT_PARAMS;
    
    

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 2. STATES - Most Windows will have a params, loading state. Ones with tabs will have an activeTabID state

    const [params, setParams] = useState(paramsToUse);
    const [loading, setLoading] = useState(true); // New loading state
    const [error, setError] = useState(null); // Error state
    const [data, setData] = useState({});
    const [initialRender, setInitialRender] = useState(true);

    const autoSuggestRef = useRef(null);


    const [forecastPeriod, setForecastPeriod] = useState(3)

    const [forecastRevenue, setForecastRevenue] = useState(
        Array(forecastPeriod).fill(0.02) // Default 2% growth for forecast periods
    );
    const [forecastedRevenue, setForecastedRevenue] = useState([])


    console.log(forecastRevenue)
      
    

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 3. 

    const requestData = () => {
        const symbolValue = autoSuggestRef.current ? autoSuggestRef.current.value : params.symbol;
    
        // Update params using the previous state value
        setParams((prevParams) => {
            const newParams = {
                ...prevParams,
                ['symbol']: symbolValue
            };
            
            // Update Database
            
            // Update Database only after the initial render
            updateLayoutItem(windowID, {
                symbol: symbolValue ? symbolValue : "N/A",
                windowParameters: newParams,
            });
            const layout = getGlobalLayout();
            dashboardLayout.saveLayout(layout);
            
    
            // Call the API with the updated params
            setLoading(true); // Start loading
            setError(null); 
            axios.post(ENDPOINT, newParams) // Use newParams here
                .then(response => {
                    const parsedResponse = JSON.parse(response.data.data);
                    console.log(parsedResponse)
                    setData(parsedResponse);
                })
                .catch(error => {
                    console.error('Error during POST request:', error);
                    // setError("Failed to load financial statements.");

                    const fakeData = {
                        "date": [
                            2022,
                            2023,
                            2024
                        ],

                        "revenue": [
                            365817000000,
                            394328000000,
                            383285000000
                        ],
                        "costOfRevenue": [
                            212981000000,
                            223546000000,
                            214137000000
                        ],
                        "operatingExpenses": [
                            43827000000,
                            51573000000,
                            55229000000
                        ],
                        "depreciationAndAmortization": [
                            11284000000,
                            11104000000,
                            11519000000
                        ],
                        "incomeTaxExpense": [
                            14527000000,
                            19300000000,
                            16741000000
                        ],
                        "capitalExpenditure": [
                            -11085000000,
                            -10708000000,
                            -10959000000
                        ],
                        "changeInWorkingCapital": [
                            -4911000000,
                            1200000000,
                            -6577000000
                        ]
                    }
                    setData(fakeData)
                })
                .finally(() => {
                    setLoading(false); // Stop loading
                });
    
            return newParams; // Return the new params for state update
        });

        if (initialRender) setInitialRender(false);
    };

    useEffect(() => {
        requestData();  // Initial request
    }, []);

    useEffect(()=>{
        setForecastRevenue(Array(forecastPeriod).fill(0.02))
    }, [forecastPeriod])

    const updateParams = (e) => {
        const { id, value } = e.target;
    
        // Update the params by setting the new value for the key that matches the input's id
        setParams((prevParams) => ({
            ...prevParams,
            [id]: value
        }));
    }

    const handleForecastChange = (index, value) => {
        setForecastRevenue(prevForecast => {
          const updatedForecast = [...prevForecast];
          updatedForecast[index] = value;
          return updatedForecast;
        });
      };
   

    
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 4. 
    

    return (
        <>
            {loading ? ( // Conditional rendering for loading spinner
                <LoadingSpinner />
            ) : error ? (
                <WindowError message={error}/>
            ) : (
                <>
                    <WindowPieces.OptionsContainer securitySearch={true}>
                        <AutosuggestSecurities
                            id={'symbol'}
                            ref={autoSuggestRef}
                            defaultValue={params.symbol}
                            handleSubmitCustom={requestData}
                        />
                        <LoadButton onClick={requestData}>LOAD</LoadButton>
                        <div className="d-flex flex-row">
                            <WindowPieces.SquareInput 
                                id={'historicalPeriods'}
                                type={'number'}
                                placeholder={'Historical Periods'}
                                value={params.periods}
                                onChange={updateParams}
                            />
                            <WindowPieces.SquareInput 
                                id={'forecastPeriod'}
                                type={'number'}
                                placeholder={'Forecast Period'}
                                value={forecastPeriod}
                                onChange={(e) => setForecastPeriod(e.target.value)}
                            />
                            <WindowPieces.SquareSelect
                                id={'periodType'}
                                innerHTMLList={['Annual', 'Quarter']} 
                                valueList={['annual', 'quarter']}
                                value={params.periodType}
                                onChange={updateParams}

                            />
                            <WindowPieces.SquareSelect
                                id={'divider'}
                                innerHTMLList={['Billions', 'Millions', 'Thousands']} 
                                valueList={['billions', 'millions', 'thousands']}
                                value={params.divider}
                                onChange={updateParams}

                            />
                            <WindowPieces.SquareLoadButton 
                                label={"LOAD"}
                                onClick={requestData}
                            
                            />
                        </div>
                        
                    </WindowPieces.OptionsContainer>

    
                    <WindowPieces.Content>
                        <div className="d-flex flex-row">
                            <table className={constants.TABLE_BS_CLASSES}>
                                <thead>
                                    <tr>
                                        <td>Date</td>
                                        {data['date'].map((value, index) => (
                                            <td key={index}>{value}</td>
                                        ))}
                                        {Array.from({ length: forecastPeriod }).map((_, index) => {
                                            const lastDate = data['date'][data['date'].length - 1]; 
                                            const forecastYear = lastDate + index + 1; 
                                            return <td className="text-primary " key={`forecast-${index}`}>{forecastYear}</td>;
                                        })}
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td>Revenue</td>
                                        {data['revenue'].map((value, index) => (
                                            <td key={index}>{value}</td>
                                        ))}


                                        <td key={`forecast-revenue-0`} className="text-primary">
                                            {data['revenue'][data['revenue'].length-1]*(1+forecastRevenue[0])}
                                        </td>
                                        
                                        {Array.from({length: forecastPeriod-1}).map((_, index)=>{
                                            return (
                                            <td key={`forecast-revenue-${index+1}`} className="text-primary">
                                                {data['revenue'][index+1]*(1+forecastRevenue[index+1])}
                                            </td>
                                        )})}


                                    
                                    </tr>

                                    <tr>
                                        <td>Revenue % Growth</td>
                                        {DCFComponents.calculatePercentageChange(data['revenue']).map((value, index) => (
                                            <td key={index}>{value}</td>
                                        ))}

                                        {/* Render input fields for forecast percentages */}

                                        {Array.from({length: forecastPeriod}).map((_,index)=>(
                                            <td key={`forecast-growth-input-${index}`}>
                                            <WindowPieces.SquareInput 
                                                value={forecastRevenue[index]}
                                                onChange={(e) => handleForecastChange(index, parseFloat(e.target.value))}
                                            />
                                            </td>

                                        ))}



                                    </tr>

                                
                                </tbody>
                            </table>
                        </div>
                    </WindowPieces.Content>

                </>
            )}
        </>
    );
};

export default DCF;

import React from "react"
import FormattingComponents from "../../formatting/FormattingComponents"

const LineWrapper = ({ name, value }) => {
    return (
        <div className="d-flex justify-content-between mt-1">
            <span className="text-nowrap">{name}</span>
            <span><strong><FormattingComponents.NumberRounded value={value} numDecimals={2} /></strong></span>
        </div>
    )
}

const LineWrapperPercent = ({ name, value }) => {
    return (
        <div className="d-flex justify-content-between mt-1">
            <span className="text-nowrap">{name}</span>
            <span><strong><FormattingComponents.Percentage value={value} numDecimals={2} /></strong></span>
        </div>
    )
}

export default { LineWrapper, LineWrapperPercent }
import React from 'react';

const SuggestionListForFundamentals = ({ fundamentalSuggestions }) => {
    
    return (
        <div 
            className='border'
            style={{
                top: '100%',
                left: '0',
                overflowY: 'auto',
                overflowX: 'hidden',
                maxWidth: '100%',
                width: '100%',
                zIndex: 1000,
                textAlign: 'left',
                backgroundColor: "var(--theme)",
                borderColor: "var(--grid)"
            }} >
                <div>
                    {
                        fundamentalSuggestions.length > 0 ? (
                            fundamentalSuggestions.map((fundamentalSuggestionObject, index) => {
                                
                                return (
                                    <div 
                                        key={index} 
                                        data-suggestion-type={fundamentalSuggestionObject.suggestionType} 
                                        data-suggestion-value={fundamentalSuggestionObject.name} 
                                        style={{
                                            backgroundColor: "var(--theme)",
                                            color: "var(--text)"
                                        }}
                                        className="row suggestion-item p-1 d-flex align-items-center">
                                        <div className="col-6">
                                            <span className="fw-bold text-nowrap">{fundamentalSuggestionObject.name}</span>
                                        </div>
                                        
                                        <div className="col-6 text-end">
                                            <span>{fundamentalSuggestionObject.sourceName}</span>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div style={{color: "var(--negative-c)"}}>No Valid Fundamental!</div>
                        )
                    }
                    
            </div> 
        </div>
    );
}

export default SuggestionListForFundamentals;

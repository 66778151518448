import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import LoadingSpinner from "../../misc/LoadingSpinner";
import WindowPieces from "../../ui/small/WindowPieces";
import WindowError from "../../misc/WindowError";
import dashboardLayout from "../../../functions/memoryManagement/dashboardLayout";
import { getGlobalLayout, updateLayoutItem } from "../../../functions/memoryManagement/layoutManager";
import AutosuggestSecurities from "../../autosuggest/searchbars/AutosuggestSecurities_v3";
import CandlestickChart from "../../charting/tvlw/CandlestickChart";
import LineChart from "../../charting/tvlw/LineChart";
import LineAreaChart from "../../charting/tvlw/LineAreaChart";
import LoadButton from "../../ui/small/buttons/LoadButton";
import constants from "../../../constants";


const HistoricalPrice = ({ symbol, windowID, newWindow }) => {
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 1. DEFAULT CONSTANTS
    const DEFAULT_PARAMS = {
        'symbol': symbol,
        'format': "arealine",
        'timeframe': '1Y',
        'interval': '1D',
    };
    const ENDPOINT = '/historical-price/';

    const globalLayout = getGlobalLayout();
    const layout = globalLayout.find(tab =>
        tab.layout.find(window => window.windowID === windowID)
    )
    const window = layout?.layout.find(window=>window.windowID === windowID)
    const windowParameters = window?.windowParameters
    const paramsToUse = windowParameters || DEFAULT_PARAMS;

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 2. STATES - Most Windows will have a params, loading state. Ones with tabs will have an activeTabID state

    const [params, setParams] = useState(paramsToUse);
    const [loading, setLoading] = useState(true); // New loading state
    const [error, setError] = useState(null); // Error state
    const [data, setData] = useState({});
    const [initialRender, setInitialRender] = useState(true);


    const autoSuggestRef = useRef(null);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 3. 

    const requestData = () => {
        const symbolValue = autoSuggestRef.current ? autoSuggestRef.current.value : params.symbol;

        // Update params using the previous state value
        setParams((prevParams) => {
            const newParams = {
                ...prevParams,
                ['symbol']: symbolValue
            };

            // Update Database
            updateLayoutItem(windowID, {
                symbol: symbolValue ? symbolValue : "N/A",
                windowParameters: newParams,
            });
            const layout = getGlobalLayout();
            dashboardLayout.saveLayout(layout);

            // Call the API with the updated params
            setLoading(true); // Start loading
            setError(null);
            axios.post(constants.BACKEND_URL+ENDPOINT, newParams) // Use newParams here
                .then(response => {
                    const parsedResponse = JSON.parse(response.data.data);
                    setData(parsedResponse);
                })
                .catch(error => {
                    console.error('Error during POST request:', error);
                    setError("Failed to load financial statements.");
                    // setData(sample_data)
                })
                .finally(() => {
                    setLoading(false); // Stop loading
                });

            return newParams; // Return the new params for state update
        });

        if (initialRender) setInitialRender(false);
    };

    useEffect(() => {
        requestData();  // Initial request
    }, []);



    const updateParams = (e) => {
        const { id, value } = e.target;
    
        setParams((prevParams) => ({
            ...prevParams,
            [id]: value
        }));
    }

    // const window = document.getElementById(windowID)
    const contentContainerRef = useRef();

    const ranges = [
        { name: '1h', value: '1h' },
        { name: '4h', value: '4h' },
        { name: '6h', value: '6h' },
        { name: '12h', value: '12h' },
        { name: '1D', value: '1D' },
    ];

    const renderChart = () => {
        switch (params.format) {
            case 'candlestick':
                return <CandlestickChart ohlcData={data} symbol={params.symbol} params={params} windowContainer={document.getElementById(windowID)} contentContainerRef={contentContainerRef} />;
            case 'line':
                return <LineChart lineData={data} symbol={params.symbol} windowContainer={document.getElementById(windowID)} contentContainerRef={contentContainerRef} />;
            case 'arealine':
                return <LineAreaChart lineData={data} symbol={params.symbol} windowContainer={document.getElementById(windowID)} contentContainerRef={contentContainerRef} />;
            default:
                return <CandlestickChart ohlcData={data} symbol={params.symbol} windowContainer={document.getElementById(windowID)} contentContainerRef={contentContainerRef} />;
        }
    };

    return (
        <>
            {loading ? ( // Conditional rendering for loading spinner
                <LoadingSpinner />
            ) : error ? (
                <WindowError message={error}/>
            ) : (
                <>
                    <WindowPieces.OptionsContainer securitySearch={true}>

                        <AutosuggestSecurities
                            id={'symbol'}
                            ref={autoSuggestRef}
                            defaultValue={params.symbol}
                            handleSubmitCustom={requestData}
                        />
                        <WindowPieces.ConfigContainer>
                            {/* <WindowPieces.SquareSelect 
                                // valueList={['candlestick', 'line', 'arealine']}
                                // innerHTMLList={['Candlestick', 'Line', 'Area Line']}
                                valueList={['line', 'arealine']}
                                innerHTMLList={['Line', 'Area Line']}
                                id='format'
                                onChange={updateParams}
                                value={params.format}

                            /> */}
                            <WindowPieces.SquareSelect 
                                innerHTMLList={['1 Min', '5 Min', '15 Min', '30 Min', '1 Hour', '4 Hour', '1 Day']}
                                valueList={['1min', '5min', '15min', '30min', '1hour', '4hour', '1D']}
                                id='interval'
                                onChange={updateParams}
                                value={params.interval}

                            />
                            <WindowPieces.SquareSelect 
                                innerHTMLList={['1 Day', '5 Days', '1 Month', '3 Month', '6M', 'YTD', '1 Year', '3 Years', '5 Years', '10 Years']}
                                valueList={['1D', '5D', '1M', '3M', '6M', 'YTD', '1Y', '3Y', '5Y', '10Y']}
                                id='timeframe'
                                onChange={updateParams}
                                value={params.timeframe}

                            />
                            <LoadButton onClick={requestData}>LOAD</LoadButton>
                        </WindowPieces.ConfigContainer>
                    </WindowPieces.OptionsContainer>

                 
                    <WindowPieces.ChartContainer ref={contentContainerRef}>
                        {renderChart()}
                    </WindowPieces.ChartContainer>

    
                    
                </>
            )}
        </>
    );
};

export default HistoricalPrice;

import React, {useState, useEffect, useRef} from "react"
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import constants from "../../../constants";
import axios from 'axios';

function logWithTime(message) {
    const currentTime = new Date().toLocaleString();
    console.log(`[${currentTime}] ${message}`);
}

const WidgetComponent = ({initialData, symbol}) => {
    const getCloseData = () => {
        return initialData.map(dataObject => dataObject.close)
    }
    const getCloseAndTimeData = () => {
        console.log(initialData.map(dataObject => ({time: dataObject.date, close: dataObject.close})))
        return initialData.map(dataObject => ({time: dataObject.date, close: dataObject.close}))
    }
    const getLastPrice = () => {
        return initialData[initialData.length - 1].close
    }
    
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Data States
    const [data, setData] = useState(getCloseAndTimeData)
    const [livePrice, setLivePrice] = useState(getLastPrice)
    const [livePercentChange, setLivePercentChange] = useState(0.05)
  
    // Streaming 
    const websocket = useRef(null);
    const pollingInterval = useRef(null);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const connectWebSocket = () => {
        websocket.current = new WebSocket('wss://websockets.financialmodelingprep.com');
        
        const loginMessage = {
            'event': 'login',
            'data': {
                'apiKey': constants.DATA_API_KEY
            }
        };

        websocket.current.onopen = () => {
            websocket.current.send(JSON.stringify(loginMessage));
        };

        websocket.current.onmessage = (message) => {
            const messageData = JSON.parse(message.data);
            const event = messageData.event;

            if (event === 'login' && messageData.status === 200) {
                const subscribeMessage = {
                    'event': 'subscribe', 
                    'data': { 'ticker': symbol }
                };
                websocket.current.send(JSON.stringify(subscribeMessage));
            } else if ('s' in messageData) {
                if (pollingInterval.current) {
                    clearInterval(pollingInterval.current);
                    // console.log("Cleared Polling Interval!")
                }
                handleNewQuote(messageData);
            } else if (messageData.event === 'heartbeat') {
                setupPolling();
            }
        };

        websocket.current.onerror = (error) => {
            console.error("WebSocket error:", error);
        };

        websocket.current.onclose = () => {
            console.log("WebSocket connection closed");
        };
    };


    const setupPolling = () => {
        if (!pollingInterval.current) {
            pollingInterval.current = setInterval(() => {
                axios.post('/intraday-price/', { 'symbol': symbol })
                    .then(response => {
                        // Handle the response if needed
                    })
                    .catch(error => {
                        console.error('Error during POST request:', error);
                    });
            }, 60000);
            logWithTime("Created Polling Interval");
        }
    };

    const handleNewQuote = (quote) => {
        const timestamp = Math.floor(quote.t / 1000000000);  // Assuming the timestamp is in seconds
        const price = quote.lp;
        setLivePrice(price)

        const timeString = new Date(Date.now()).toLocaleTimeString(undefined, {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false // Set to true if you want 12-hour format with AM/PM
        });
        const intervalStart = Math.floor(timestamp / 60) * 60 * 15;

        // setData((prevData)=> {
        //     // Starting New Data Point
        //     if (!prevData || prevData[prevData.length - 1].time < intervalStart) {
        //         console.log("New Data Point!")
        //         // New Interval: push old data point and start new datapoint
        //         if (prevData) {
        
        //         }


        //     } else {

        //     }

        // })


        // setCurrentOHLC((prevOHLC) => {
        //     // Check if we're starting a new interval
        //     if (!prevOHLC || prevOHLC.time < intervalStart) {
        //         console.log("New Bar!")
        //         // New interval: push the old OHLC data and start a new bar
        //         if (prevOHLC) {
        //             setLatestCandlestickData(prevOHLC);  // Push the old data to the chart as a new candlestick
        //         }
                
        //         // Start a new OHLC bar for the new interval
        //         return {
        //             time: intervalStart,
        //             open: price,
        //             high: price,
        //             low: price,
        //             close: price,
        //         };
        //     } else {
        //         // Use the latest open, high, low from ohlcData if available
        //         const mostRecentHistoricalOHLC = ohlcData[ohlcData.length - 1];
     
        //         const updatedOHLC = {
        //             ...prevOHLC,
        //             open: mostRecentHistoricalOHLC ? mostRecentHistoricalOHLC.open : prevOHLC.open,
        //             high: Math.max(
        //                 mostRecentHistoricalOHLC ? mostRecentHistoricalOHLC.high : prevOHLC.high,
        //                 price
        //             ),
        //             low: Math.min(
        //                 mostRecentHistoricalOHLC ? mostRecentHistoricalOHLC.low : prevOHLC.low,
        //                 price
        //             ),
        //             close: price,
        //         };
                
        //         // Continuously push the updated OHLC to the chart for live updates
        //         setLatestCandlestickData(updatedOHLC);
        //         console.log(updatedOHLC)
        //         return updatedOHLC;
        //     }
        // });
        
    };

    useEffect(()=>{
        connectWebSocket();
        return () => {
        
            if (pollingInterval.current) clearInterval(pollingInterval.current);
            if (websocket.current) websocket.current.close();
        };
    }, [])

    return (
        <div className="d-flex h-100">
            <div>
                <div>{symbol.toUpperCase()}</div>
                <div className="d-flex">
                    <div>{livePrice}</div>
                    <div>Percent % Change</div>
                </div>
            </div>
            <div>
                LIVE PRICE
            </div>
        </div>
    )
}

export default WidgetComponent
import React from "react";
import ReactDOM from "react-dom";
import prepareWindow from "../../windowCreating/prepareWindow";
import InsiderTrading from "../../../components/FeatureComponents/6_InsiderTrading/6_InsiderTrading";

async function handleInsiderTrading(symbol, isInitialDisplay=true, existingWindowID="") {
    const WINDOW_TYPE_NAME = 'window-insider-trading';
    const TITLE_PREFIX = 'Insider Trading ';

    const window = await prepareWindow(symbol, WINDOW_TYPE_NAME, TITLE_PREFIX, isInitialDisplay, existingWindowID)
    const destinationElement = window.querySelector('.window-content')

    ReactDOM.render(<InsiderTrading symbol={symbol} windowID={window.id} newWindow={isInitialDisplay}/>, destinationElement)
}

export default  handleInsiderTrading 
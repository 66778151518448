import React from 'react';
import SitePieces from '../ui/small/SitePieces';
import CloseWindowButton from '../ui/small/buttons/CloseWIndowButton';

const CustomModal2 = ({ show, title, body, onClose }) => {
    if (!show) return null;

    return (
        <>
            {/* Backdrop */}
            <div 
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black
                    zIndex: 999, // Ensure it's behind the modal but above other content
                }}
                onClick={onClose} // Close modal when clicking on the backdrop
            ></div>

            {/* Modal */}
            <div 
                style={{
                    position: 'fixed',
                    backgroundColor: 'var(--theme)',
                    top: '50%', // Center vertically
                    left: '50%', // Center horizontally
                    transform: 'translate(-50%, -50%)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '2rem',
                    color: 'var(--text)',
                    borderColor: "var(--grid)",
                    zIndex: 1000, // Ensure it's above the backdrop
                }}
                className='border'
            >
                <div className='w-100 d-flex flex-row justify-content-between align-items-center'>
                    <h2>{title}</h2>
                    <CloseWindowButton onClick={onClose} title='Close'/>
                </div>
                <div style={{backgroundColor: "var(--theme)"}} className='d-flex flex-column w-100 h-100'>
                    {body}
                </div>
            </div>
        </>
    );
};

export default CustomModal2;

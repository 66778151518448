import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import constants from '../constants';

// not sensitive
const stripePromise = loadStripe('pk_test_51QPYYrIwFuKasYMC6P3y220W1McATBljLgTKlYcHTsFMQutNC1AqAFt3jVr6ovqjwODeRoTinR2TBzJOdx7g7ki400Dw0ONyFu');

const handleCheckout = async (email) => {
    const stripe = await stripePromise;

    try {
        const response = await axios.post(constants.BACKEND_URL+'/create-checkout-session/', {
            email: email,
        });

        const { sessionId } = response.data;
        const { error } = await stripe.redirectToCheckout({ sessionId });

        if (error) console.error('Stripe Checkout Error:', error);

    } catch (err) {
        console.error('API Request Error:', err);
    }
};

export default handleCheckout
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import constants from '../../../constants';
import { showAlert } from '../../../functions/showAlert';
import watchlistData from '../../../functions/memoryManagement/watchlistData';
import CustomModal from '../../modals/CustomModal1';
import AutosuggestSecurities from '../../autosuggest/searchbars/AutosuggestSecurities_v3';
import ZenithTable1 from "../../ui/small/tables/ZenithTable1"
import ToggleButton1 from '../../ui/small/buttons/ToggleButton1';
import AddButton from '../../ui/small/buttons/AddButton';
import getRootColorString from '../../../functions/helpers/getRootColorString';

// TUESDAY 9:30AM 
// TO FIX IN THIS COMPONENT
// Might need to the forEach in ws subscribe block - we might need to remove the forEach and just subscribe to the tickers as a list
// else if (messageData.event === 'data') <= change this block to whatever the websocket data comes as 
// get data from the fmp api every 1m if the event is heartbeat.

const Watchlist = ({ initWatchlistObject }) => {
    const [watchlistObject, setWatchlistObject] = useState(initWatchlistObject);
    const [selectedWatchlist, setSelectedWatchlist] = useState(Object.keys(initWatchlistObject)[0]);
    const [stockData, setStockData] = useState([]);
    const [previousClosePrices, setPreviousClosePrices] = useState({})
    const [hoveredTicker, setHoveredTicker] = useState(null);

    // Modal related states
    const [showAddTickerModal, setShowAddTickerModal] = useState(false);
    const [newTicker, setNewTicker] = useState("");
    const [showCreateWatchlistModal, setShowCreateWatchlistModal] = useState(false);
    const [newWatchlistName, setNewWatchlistName] = useState("");
    const [showDeleteWatchlistModal, setShowDeleteWatchlistModal] = useState(false);
    const [renameWatchlistName, setRenameWatchlistName] = useState("");
    const [showRenameWatchlistModal, setShowRenameWatchlistModal] = useState(false);

    const websocket = useRef(null)
    const pollingInterval = useRef(null)
    const autoSuggestRef = useRef(null);

    const positiveColor = getRootColorString("--positive")
    const negativeColor = getRootColorString("--negative")
    

    useEffect(() => {
        const tickers = watchlistObject[selectedWatchlist];
        axios.post(constants.BACKEND_URL+'/watchlist-update/', { 'symbols': tickers })
            .then(response => {
                response = response.data.data;
                const parsedResponse = JSON.parse(response)
          
                setStockData(parsedResponse)

                const newPreviousClosePrices = {};
                parsedResponse.forEach(item => {
                    newPreviousClosePrices[item.ticker] = item.previousClose;
                });
                setPreviousClosePrices(newPreviousClosePrices);
                

            })
            .catch(error => {
                console.error('Error during POST request:', error);
        });


        const connectWebSocket = () => {
            websocket.current = new WebSocket('wss://websockets.financialmodelingprep.com');
            // websocket.current = new WebSocket("ws://localhost:8000/ws/data/");

            // Prepare the login and subscribe objects
            const login = {
                'event': 'login',
                'data': {
                    'apiKey':constants.DATA_API_KEY
                }
            };

            websocket.current.onopen = () => {
                websocket.current.send(JSON.stringify(login));

            };


            websocket.current.onmessage = (event) => {
                const messageData = JSON.parse(event.data);
         
                if (messageData.event == 'login' && messageData.status == 200) {

                    const subscribeMessage={
                        'event': 'subscribe', 
                        'data': {
                            'ticker': watchlistObject[selectedWatchlist]
                        }
                    }
                    websocket.current.send(JSON.stringify(subscribeMessage));
                }

                else if (messageData.event == 'heartbeat') {

                    if (!pollingInterval.current) {
                        pollingInterval.current = setInterval(() => {

                            const tickers = watchlistObject[selectedWatchlist];
                            axios.post(constants.BACKEND_URL+'/watchlist-update/', { 'symbols': tickers })
                                .then(response => {
                                    response = response.data.data;
                                    const parsedResponse = JSON.parse(response)
                                   
                                    setStockData(parsedResponse)

                                    const newPreviousClosePrices = {};
                                    parsedResponse.forEach(item => {
                                        newPreviousClosePrices[item.ticker] = item.previousClose;
                                    });
                                    setPreviousClosePrices(newPreviousClosePrices);
                                    
                                })
                                .catch(error => {
                                    console.error('Error during POST request:', error);
                                });


                        }, 60000); 
                    }


                }

                else if ('s' in messageData) {
                    const messageTicker = messageData.s.toUpperCase();
                
                    setPreviousClosePrices(prevPrices => {
                        const websocketData = {
                            'ticker': messageTicker,
                            'lastPrice': messageData.lp,
                            'change': messageData.lp - prevPrices[messageTicker],
                            'changePercent': ((messageData.lp / prevPrices[messageTicker]) - 1)*100
                        };
                
                        setStockData(prevItems => {
                            // Check if we have any items
                            if (prevItems) {
                                const stockIndex = prevItems.findIndex(stock => stock.ticker === websocketData.ticker);
                    
                                if (stockIndex > -1) {
                                    // If the stock exists, create a new array and update the existing stock
                                    const newStockData = [...prevItems];
                                    newStockData[stockIndex] = {
                                        ...newStockData[stockIndex],
                                        lastPrice: websocketData.lastPrice,
                                        change: websocketData.change,
                                        changePercent: websocketData.changePercent
                                    };
                                    return newStockData; // Return the updated array
                                } else {
                                    // If the stock does not exist, add the new stock data
                                    return [...prevItems, websocketData]; // Add the new stock data
                                }
                            }
                    
                            // If prevItems is empty (initial case), return the new websocketData
                            return [websocketData];
                        });
                
                        if (pollingInterval.current) {
                            clearInterval(pollingInterval.current);
                            pollingInterval.current = null; 
                        }
                
                        // Return the previous prices unmodified, since we're only reading them
                        return prevPrices;
                    });
                }
            };

            websocket.current.onerror = (error) => {
                console.error("WebSocket error:", error);
            };

            websocket.current.onclose = () => {
                console.log("WebSocket connection closed");
            };
        };

        connectWebSocket();
        

        // Clean up websocket on unmount
        return () => {
            if (pollingInterval.current) {
                clearInterval(pollingInterval.current); 
            }
            if (websocket.current) {
                websocket.current.close(); 
            }
        };
    }, [selectedWatchlist, watchlistObject]);

    // Handle adding a ticker to the selected watchlist
    const handleAddTicker = () => {
        setShowAddTickerModal(true);
    };

    const handleConfirmAddTicker = () => {
        if (watchlistObject[selectedWatchlist]) {
            if (newTicker && !watchlistObject[selectedWatchlist].includes(newTicker)) {
                setWatchlistObject(prev => {
                    const updatedWatchlist = {
                        ...prev,
                        [selectedWatchlist]: [...prev[selectedWatchlist], newTicker.toUpperCase()]
                    };
                    watchlistData.saveWatchlist(updatedWatchlist);
                    return updatedWatchlist;
                });
                setNewTicker(""); // Reset input field after adding
            } else if (watchlistObject[selectedWatchlist].includes(newTicker)) {
                showAlert('warning', `${newTicker.toUpperCase()} is already in the '${selectedWatchlist}' watchlist.`);
            }
            setShowAddTickerModal(false);
        } else {
            showAlert('error', 'Please create a watchlist to add a ticker!');
        }
    };

    // Handle creating a new watchlist using modal
    const handleCreateWatchlist = () => {
        setShowCreateWatchlistModal(true);
    };

    const handleConfirmCreateWatchlist = () => {
        if (newWatchlistName && !watchlistObject[newWatchlistName]) {
            setWatchlistObject(prev => {
                const updatedWatchlist = {
                    ...prev,
                    [newWatchlistName]: []
                };
                watchlistData.saveWatchlist(updatedWatchlist);
                return updatedWatchlist;
            });
            setSelectedWatchlist(newWatchlistName);
            setNewWatchlistName(""); // Reset input field after creating
        } else if (watchlistObject[newWatchlistName]) {
            showAlert('error', "Watchlist with this name already exists.");
        }
        setShowCreateWatchlistModal(false);
    };

    const handleDeleteWatchlist = () => {
        setShowDeleteWatchlistModal(true);
    };

    const handleConfirmDeleteWatchlist = () => {
        setWatchlistObject(prev => {
            const updatedWatchlists = { ...prev };
            delete updatedWatchlists[selectedWatchlist];
            watchlistData.saveWatchlist(updatedWatchlists);
            return updatedWatchlists;
        });
        const newSelection = Object.keys(watchlistObject)[0] || "";
        setSelectedWatchlist(newSelection);
        setShowDeleteWatchlistModal(false);
    };

    const handleDeleteTicker = (ticker) => {
        setWatchlistObject(prev => {
            const updatedWatchlist = {
                ...prev,
                [selectedWatchlist]: prev[selectedWatchlist].filter(t => t !== ticker)
            };
            watchlistData.saveWatchlist(updatedWatchlist);
            return updatedWatchlist;
        });
    };

    const handleRenameWatchlist = () => {
        setShowRenameWatchlistModal(true);
    };

    const handleConfirmRenameWatchlist = () => {
        if (renameWatchlistName && !watchlistObject[renameWatchlistName]) {
            setWatchlistObject(prev => {
                const updatedWatchlist = { ...prev };
                updatedWatchlist[renameWatchlistName] = updatedWatchlist[selectedWatchlist];
                delete updatedWatchlist[selectedWatchlist];

                watchlistData.saveWatchlist(updatedWatchlist);
                return updatedWatchlist;
            });
            setSelectedWatchlist(renameWatchlistName);
            setRenameWatchlistName(""); // Reset input field after renaming
        } else if (watchlistObject[renameWatchlistName]) {
            showAlert('error', "A watchlist with this name already exists.");
        }
        setShowRenameWatchlistModal(false);
    };



    return (
        <div class="p-2">
            <div className='d-flex justify-content-between'>
                <ToggleButton1 
                    showToggle={true}
                    label={selectedWatchlist ? selectedWatchlist : "Select Watchlist"}
                    dropDownMenu={
                        <ul style={{backgroundColor: "var(--theme)"}} className="dropdown-menu dropdown-menu-end rounded-0">
                        {Object.keys(watchlistObject).map((watchlist, index) => (
                            <li key={index}>
                                <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() => setSelectedWatchlist(watchlist)}
                                >
                                    {watchlist}
                                </a>
                            </li>
                        ))}
                        <li><hr className="dropdown-divider" /></li>
                        <li>
                            <a
                                style={{color: "var(--text)"}}
                                className="dropdown-item"
                                href="#"
                                onClick={handleRenameWatchlist}
                            >
                                Rename Watchlist
                            </a>
                        </li>
                        <li>
                            <a
                                className="dropdown-item"
                                style={{color: "var(--positive-c)"}}
                                href="#"
                                onClick={handleCreateWatchlist}
                            >
                                Create New Watchlist
                            </a>
                        </li>
                        <li>
                            <a
                                className="dropdown-item"
                                style={{color: "var(--negative-c)"}}
                                href="#"
                                onClick={handleDeleteWatchlist}
                            >
                                Delete Watchlist
                            </a>
                        </li>
                    </ul>
                    }
                
                />
                <AddButton onClick={handleAddTicker}>
                    + Add Symbol
                </AddButton>
            </div>
            
            {/* Render Table */}
            <ZenithTable1.Table 
                tablehead={
                    <tr>
                        <th className='text-nowrap'>Ticker</th>
                        <th className='text-nowrap'>Last Price</th>
                        <th className='text-nowrap'>Change</th>
                        <th className='text-nowrap'>Change %</th>
                    </tr>
                }
                tablebody={
                    <>
                        {stockData.map((stock, index) => (
                            <tr key={index}>
                                <td
                                    onMouseEnter={() => setHoveredTicker(stock.ticker)}
                                    onMouseLeave={() => setHoveredTicker(null)}
                                    style={{ position: 'relative' }}
                                >
                                    {stock.ticker}
                                    {hoveredTicker === stock.ticker && (
                                        <span
                                            style={{
                                                position: 'absolute',
                                                right: '10px',
                                                color: 'red',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => handleDeleteTicker(stock.ticker)}
                                        >
                                            &#10005;
                                        </span>
                                    )}
                                </td>
                                <td>{stock.lastPrice.toFixed(2)}</td>
                                <td style={{ color: stock.change >= 0 ? positiveColor : negativeColor }}>
                                    {stock.change.toFixed(2)}
                                </td>
                                <td style={{ color: stock.changePercent >= 0 ? positiveColor : negativeColor }}>
                                    {stock.changePercent.toFixed(2)}%
                                </td>
                            </tr>
                        ))}
                    </>
                }
            />
        

            {/* Modals for Add Ticker, Create Watchlist, and Delete Watchlist */}
            <CustomModal
                show={showRenameWatchlistModal}
                title={`Rename Watchlist '${selectedWatchlist}'`}
                body={
                    <input
                        type="text"
                        value={renameWatchlistName}
                        onChange={(e) => setRenameWatchlistName(e.target.value)}
                        placeholder="Enter new watchlist name"
                    />
                }
                onClose={() => setShowRenameWatchlistModal(false)}
                onConfirm={handleConfirmRenameWatchlist}
            />

            

            <CustomModal
                show={showAddTickerModal}
                title="Add New Ticker"
                body={
                    <AutosuggestSecurities 
                        id={'hello'}
                        ref={autoSuggestRef}
                        handleSubmitCustom={(e)=>setNewTicker((e.target.value).toUpperCase())}
                        defaultValue={""}

                    />
                   
                }
                onClose={() => setShowAddTickerModal(false)}
                onConfirm={handleConfirmAddTicker}
            />

            <CustomModal
                show={showCreateWatchlistModal}
                title="Create New Watchlist"
                body={<input type="text" value={newWatchlistName} onChange={(e) => setNewWatchlistName(e.target.value)} placeholder="Enter watchlist name" />}
                onClose={() => setShowCreateWatchlistModal(false)}
                onConfirm={handleConfirmCreateWatchlist}
            />

            <CustomModal
                show={showDeleteWatchlistModal}
                title={`Delete ${selectedWatchlist}`}
                body={<p>Are you sure you want to delete this watchlist?</p>}
                onClose={() => setShowDeleteWatchlistModal(false)}
                onConfirm={handleConfirmDeleteWatchlist}
            />
        </div>
    );
};

export default Watchlist;

import React, { useRef, useEffect, useState } from 'react';
import initEventListeners from '../functions/initEventListeners';
import { setGridLayoutRef } from '../functions/gridLayoutManager';
import ResizableDraggableContainer from '../components/ZenithGridLayout';
import AutosuggestCommandLine from '../components/autosuggest/searchbars/AutosuggestCommandLine_v3';
import handleHelp from '../functions/requestFuncs/handling/handleHelp';
import LoginModal from '../components/modals/CustomModals2/LoginModal';
import RegisterModal from '../components/modals/CustomModals2/RegisterModal';
import SettingsModal from '../components/modals/CustomModals2/SettingsModal';
import AccountModal from '../components/modals/CustomModals2/AccountModal';
import IntroductionModal from '../components/modals/CustomModals2/IntroductionModal';
import ChooseColorModal from '../components/modals/CustomModals2/ChooseColorModal';
import SecurityFunctionModal from '../components/modals/CustomModals2/SecurityFunctionsModal';
import Button1 from '../components/ui/small/buttons/Button1';
import ToggleButton1 from '../components/ui/small/buttons/ToggleButton1';
import dashboardLayout from '../functions/memoryManagement/dashboardLayout';
import { getGlobalLayout, setGlobalLayout } from '../functions/memoryManagement/layoutManager';
import TabToggleButton from '../components/ui/small/buttons/TabToggleButton';
import RenameTabModal from '../components/modals/CustomModals2/RenameTabModal';
import AddButton from '../components/ui/small/buttons/AddButton';
import handleCheckout from '../functions/handleCheckout';
import UserPlanBadge from '../components/misc/UserPlanBadge';
import Button2 from '../components/ui/small/buttons/Button2';
import constants from '../constants';
import UserSummary from '../components/misc/UserSummary';
import getRootColorString from '../functions/helpers/getRootColorString';
import updateRootColor from '../functions/helpers/updateRootColor';

import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../css/app/main.min.css';
import '../css/app/index.css';
import '../css/app/window.css';
import '../css/app/table.css';
import '../css/app/theme.css';
import '../css/app/grid.css';
import '../css/app/zenithGridLayout.css'
import "../css/app/colors.css"
import "../css/app/highlighting.css"
import "../css/app/scrollbar.css"
import "../css/app/alert-dropdown.css"
import "../css/app/input-select.css"
import userPreferences from '../functions/memoryManagement/userPreferences';
import IconToggleButton from '../components/ui/small/buttons/IconToggleButton';
import { showAlert } from '../functions/showAlert';

async function loadLayout() {
  try {
    const loadedLayout = await dashboardLayout.getAndLoadLayout();
    const layoutData = loadedLayout.layout_data
    if (layoutData) {
      console.log("Data from DB: ", layoutData)
      return layoutData;
    } else {
      console.log("No DB Layout, Using Default Layout")
      return constants.DEFAULT_LAYOUT
    }
  } catch (error) {
    console.error("Failed to load layout:", error);
    return constants.DEFAULT_LAYOUT;
  }
}

const loadedLayout = await loadLayout();
console.log("Setting Global Layout (Terminal.js)", loadedLayout)
setGlobalLayout(loadedLayout);

async function loadUserPreferences() {
  try {
    const loadedUserPreferences = await userPreferences.getUserPreferences();
    const preferences = loadedUserPreferences.preferences
    if (preferences) {
      console.log("User Preferences from DB: ", preferences)
      Object.entries(preferences).forEach(([key, value]) => {
        updateRootColor(key, value);
      });
    } else {
      console.log ("No User Preferences")
    }
  } catch (error) {
    console.error("Failed to load user preferences: ", error)
  }
}
loadUserPreferences()



function Terminal() {
  const HEADER_HEIGHT = '30px';
  const FOOTER_HEIGHT = '30px';

  ///////////////////////////////////////////////////////////////////////// STATE MANAGEMENT
  const [showFunctionMenu, setShowFunctionMenu] = useState(false);
  const [functionMenuSymbol, setFunctionMenuSymbol] = useState({});
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [showSettingModal, setShowSettingModal] = useState(false);
  const [showColorModal, setShowColorModal] = useState(false);
  const [showRenameTabModal, setShowRenameTabModal] = useState(false);
  const [showIntroductionModal, setShowIntroductionModal] = useState(false);
  // Tabs & Layout
  const [tabs, setTabs] = useState(
    loadedLayout.map((item) => ({ id: item.tabID, name: item.tabName, color: item.tabColor })) || []
  );
  const [activeTab, setActiveTab] = useState(
    loadedLayout && loadedLayout.length > 0 ? loadedLayout[0].tabID : null
  );
  const [layout, setLayout] = useState(loadedLayout || [])

  // User State
  const [user, setUser] = useState(null);
  // const [user, setUser] = useState({
  //   username: 'nmuft2', 
  //   email: 'nmuft2@gmail.com', 
  //   group: 'level1_user'});

  ///////////////////////////////////////////////////////////////////////// REFS
  // Refs
  const zenithGridLayout = useRef(null);
  const tabRefs = useRef({}); // Manage tab refs here

  ///////////////////////////////////////////////////////////////////////// FUNCTIONS

  initEventListeners();


  const addTab = () => {
    const n = tabs.length > 0 ? tabs.length + 1 : 1;
    // const newId = tabs.length > 0 ? tabs[tabs.length - 1].id + 1 : 1;
    const time = Date.now()
    const newId = `tab-${time}`
    const newTabName = `Tab ${n}`
    const newTab = { id: newId, name:  newTabName, color: 'var(--primary)'};
    setTabs([...tabs, newTab]);
    setActiveTab(newId);
    
    tabRefs.current[newId] = React.createRef(); 

    setLayout((prevLayout)=>{
      const newTabStructure = {
        tabID: newId,
        tabName: newTabName,
        tabColor: getRootColorString('--primary'),
        layout: []
      }
      const updatedLayout = [...prevLayout, newTabStructure]
      setGlobalLayout(updatedLayout)
      dashboardLayout.saveLayout(updatedLayout)
      return updatedLayout
    })
  };

  const removeTab = (id) => {
    // Prevent deletion if there's only one tab left
    if (tabs.length === 1) {
      showAlert("error", "Cannot delete the last tab.")
      return;
    }
  
    const updatedTabs = tabs.filter((tab) => tab.id !== id);
    setTabs(updatedTabs);

    setLayout((prevLayout) => {
      const updatedLayout = prevLayout.filter((layout) => layout.tabID !== id);

      setGlobalLayout(updatedLayout);
      dashboardLayout.saveLayout(updatedLayout); 
  
      return updatedLayout; 
    });

    delete tabRefs.current[id]; 
  
    if (id === activeTab && updatedTabs.length > 0) {
      setActiveTab(updatedTabs[0].id); 
    } else if (updatedTabs.length === 0) {
      // This won't happen since user cannot delete the last tab.
      setActiveTab(null); 
    }
  };

  const changeColor = (newColor)=> {
    setLayout((prevLayout)=>{
      const targetTabLayout = prevLayout.find(layout=>layout.tabID===activeTab)
      targetTabLayout.tabColor = newColor

      setGlobalLayout(prevLayout)
      dashboardLayout.saveLayout(prevLayout)

      const updatedTabs = prevLayout.map((item) => ({ id: item.tabID, name: item.tabName, color: item.tabColor }))
      setTabs(updatedTabs)

      return prevLayout
    })
  }
  const renameTab = (newName)=>{
    setLayout((prevLayout)=>{
      const targetTabLayout = prevLayout.find(layout=>layout.tabID===activeTab)
      targetTabLayout.tabName = newName

      setGlobalLayout(prevLayout)
      dashboardLayout.saveLayout(prevLayout)

      const updatedTabs = prevLayout.map((item) => ({ id: item.tabID, name: item.tabName, color: item.tabColor }))
      setTabs(updatedTabs)

      return prevLayout
    })

  }

  const saveLayout = (tabID, newTabLayout) => {
    setLayout((prevLayout)=>{
      console.log("Before: ", prevLayout)
      const globalLayout = getGlobalLayout()
      const targetTabLayout = prevLayout.find(layout=>layout.tabID===tabID)
      targetTabLayout.layout = newTabLayout

      setGlobalLayout(prevLayout)
      console.log("After: ", prevLayout)
      console.log("Saving to DB: ", prevLayout)
      dashboardLayout.saveLayout(prevLayout)

      return prevLayout
    })

  }

  const activeTabObject = tabs.find((tab) => tab.id === activeTab);


  
  ///////////////////////////////////////////////////////////////////////// USE EFFECT HOOKS
  useEffect(() => {
    // setGridLayoutRef(tabRefs.current[activeTabObject.id]); // Set the grid layout ref in the global manager
    fetch('/user-info/')
      .then((response) => response.json())
      .then((data) => setUser(data));
  }, []);
  
  useEffect(() => {
    // Initialize tabRefs from loadedLayout
    loadedLayout.forEach((item) => {
      if (!tabRefs.current[item.tabID]) {
        tabRefs.current[item.tabID] = React.createRef();
      }
    });
  }, []); // Runs once on mount
  
  useEffect(() => {
    if (tabs.length > 0) {
      tabRefs.current[tabs[0].id] = React.createRef();
      setGridLayoutRef(tabRefs.current[tabs[0].id]?.current); 
    } else {
      addTab()
      // setActiveTab(tabs[0].id)
    }
  }, []);


  useEffect(() => {
    if (activeTabObject && activeTabObject.id) {
      setGridLayoutRef(tabRefs.current[activeTabObject.id]?.current);
    }
  }, [activeTab, activeTabObject]);



  return (
    <>
      <div style={{ paddingBottom: '0px', backgroundColor: "var(--negative)" }} className="position-fixed h-100 w-100">
        {/* Header */}
        <div style={{ height: '30px', backgroundColor: "var(--theme)" }} className="position-absolute top-0 w-100">
          <nav
            style={{backgroundColor: "var(--theme)", height: "100%"}}
            className="position-absolute top-0 w-100 navbar navbar-expand-xxl py-0"
          >
            <div className="terminal-header container-fluid p-1">
              <div className='d-flex justify-content-between w-100'>
                <div className='d-flex'>
                  <AutosuggestCommandLine
                    id="command-line-main"
                    forTerminal={true}
                    setShowFunctionMenu={setShowFunctionMenu}
                    setFunctionMenuSymbol={setFunctionMenuSymbol}
                  />
                  
                  <div className='d-flex align-items-center'>
                    {tabs.map((tab, index) => (
                      <TabToggleButton 
                        // key={tab.id}
                        key={index}
                        isActive={tab.id===activeTab}
                        color={tab.color}
                        onTabChange={
                          ()=>{
                            setActiveTab(tab.id);
                            setGridLayoutRef(tabRefs.current[tab.id].current)
                          }
                        }
                        onTabRemove={
                          ()=>removeTab(tab.id)
                        }
                        onChooseColor={
                          ()=>setShowColorModal(true)
                        }
                        onTabRename={
                          ()=>setShowRenameTabModal(true)
                        }
                        label={tab.name}
                        
                      />


                    ))}
                    <div className='mx-1'>
                      <AddButton onClick={addTab}>+ Add Tab</AddButton>
                    </div>
                  </div>
                </div>
                <div className='d-flex align-items-center justify-content-end'>
                  {user ? (
                    <IconToggleButton
                      label={
                        <div className='d-flex align-items-center justify-content-center'>
                          <svg style={{height: "18px", width: "18px", color: "var(--text)"}}  xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                            <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
                          </svg>
                          {/* <div>{user ? user.username : 'Guest'}</div> */}
                        </div>
                      }
                      dropDownMenu={
                        <ul className="dropdown-menu dropdown-menu-end rounded-0" style={{backgroundColor: "var(--theme)"}}>
                          {user && (  
                            <>
                              <li>
                                <div className='dropdown-item'>
                                  <UserSummary userObject={user} />
                                </div>
                              </li>
                              <li>
                                <hr className="dropdown-divider" />
                              </li>
                            </>    
                            )
                          }
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => setShowAccountModal(true)}
                            >
                              Account
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => setShowSettingModal(true)}
                            >
                              Settings
                            </a>
                          </li>
                          <li>
                            <hr className="dropdown-divider" />
                          </li>
                          {user ? (
                            <li>
                              <a className="dropdown-item" style={{color: "var(--negative-c)"}} href="/logout">
                                Log Out
                              </a>
                            </li>
                          ) : (
                            <li>
                              <a
                                className="dropdown-item "
                                style={{color: "var(--positive-c)"}}
                                onClick={() => setShowLoginModal(true)}
                              >
                                Log In
                              </a>
                            </li>
                          )}
                        </ul>
                      }
                    />

                  ) : (
                    <>
                    <div>
                      <Button1 onClick={()=>setShowLoginModal(true)}>Log In</Button1>
                    </div>
                    <div className='ms-2'>
                      <Button2 onClick={()=>setShowLoginModal(true)}>Register</Button2>
                    </div>
                    </>

                  )}

                </div>
              </div>
            </div>
          </nav>
        </div>
        
        {/* Main Body - FIGR Grid */}
        <div
          style={{
            marginTop: '30px',
            paddingBottom: FOOTER_HEIGHT,
            height: `calc(100% - ${HEADER_HEIGHT})`,
            overflowY: 'auto',
            backgroundColor: "var(--grid)"
          }}
          className="position-absolute w-100 d-flex flex-column"
        >
          {tabs.map((tab, index) => (
       
              <ResizableDraggableContainer show={tab.id === activeTab} onSaveParentLayout={saveLayout} tabID={tab.id} tabLayout={layout.find(item=>item.tabID===tab.id)?.layout || []} ref={tabRefs.current[tab.id]} />
            
          ))}
        </div>
        
        {/* Footer */}
        <div style={{ height: FOOTER_HEIGHT }} className="position-fixed bottom-0 w-100">
          <footer
            style={{ height: FOOTER_HEIGHT, backgroundColor: "var(--theme)", color: "var(--text)" }}
            className="position-fixed bottom-0 w-100 footer d-flex flex-row flex-wrap justify-content-between align-items-center py-1"
          >
            <p className="m-0 mx-2">© 2024 FIGR Financial Terminal, Inc</p>
            <div className="d-flex flex-row align-items-center">
              <div className="mx-1">
                <Button2 onClick={()=> handleHelp()}>HELP</Button2>
              </div>
            </div>
          </footer>
        </div>


      </div>
      {/* Modals */}
      <IntroductionModal show={showIntroductionModal} setShow={setShowIntroductionModal}/>
      <RenameTabModal show={showRenameTabModal} setShow={setShowRenameTabModal} onRenameTab={renameTab}/>
      <ChooseColorModal show={showColorModal} setShow={setShowColorModal} onColorChoice={changeColor}/>
      <SecurityFunctionModal show={showFunctionMenu} setShow={setShowFunctionMenu} functionMenuSymbol={functionMenuSymbol}/>
      <LoginModal show={showLoginModal} setShow={setShowLoginModal} setShowRegister={setShowRegisterModal} />
      <RegisterModal show={showRegisterModal} setShow={setShowRegisterModal} setShowLogin={setShowLoginModal} />
      <AccountModal show={showAccountModal} setShow={setShowAccountModal} />
      <SettingsModal show={showSettingModal} setShow={setShowSettingModal} />
    </>
  );
}

export default Terminal;

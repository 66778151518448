import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import constants from "../../../constants";
import LoadingSpinner from "../../misc/LoadingSpinner";
import WindowPieces from "../../ui/small/WindowPieces";
import CustomModal1 from '../../modals/CustomModal1'
import AutosuggestSecuritiesModal from "../../autosuggest/searchbars/AutosuggestSecurities_v3_Modal";
import AutosuggestSecurities from "../../autosuggest/searchbars/AutosuggestSecurities_v3";
import AutosuggestFundamentals from "../../autosuggest/searchbars/AutosuggestFundamentals_v3";
import GroupAnalysisTimeTable from "./GroupAnalysisTimeTable";
import WindowError from "../../misc/WindowError";
import FileTabStructure from "../../misc/FileTabStructure";
import fundamentals from "../../../catalogs/fundamentals";
import LoadButton from "../../ui/small/buttons/LoadButton";
import Button1 from "../../ui/small/buttons/Button1";
import Button2 from "../../ui/small/buttons/Button2";


const sampleData = [
    { name: "Alice", age: 24, city: "New York" },
    { name: "Bob", age: 30, city: "Los Angeles" },
    { name: "Charlie", age: 22, city: "Chicago" },
    { name: "Dave", age: 35, city: "Houston" },
  ];

  const sampleColumns = [
    { key: "name", label: "Name" },
    { key: "age", label: "Age" },
    { key: "city", label: "City" },
  ];


  

// Inline styles for table headers and cells



 


const GroupAnalysisByTime = ({symbol}) => {
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    // A Pre-request for this function to get the peers.
    

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 1. DEFAULT CONSTANTS
    const DEFAULT_PARAMS = {
        'symbol': symbol,
        'useDefaultPeers': true,
        'symbolsList': [],
        'metric': 'netIncome',
        'periods': 3,
        'periodType': 'annual'
    };
    const ENDPOINT = '/group-time/'
    const tabs = [
        { label: "Table", targetID: "group-time-table" },
        { label: "Chart", targetID: "group-time-chart" },

    ];
    const DEFAULT_TAB="group-time-table"
/////////////////////////////////////////////////////////////////////////////////////////////////////////

    const [params, setParams] = useState(DEFAULT_PARAMS)
    const [loading, setLoading] = useState(true); // New loading state
    const [error, setError] = useState(null); // Error state
    const [activeTabID, setActiveTabID] = useState(DEFAULT_TAB)
    const [data, setData] = useState({});
    const [initialRender, setInitialRender] = useState(true);
    const [displayedMetric, setDisplayedMetric] = useState(null)
    const [displayedInterval, setDisplayedInterval] = useState(null)
    const [displayedPeriodType, setDisplayedPeriodType] = useState(null)
    const [symbolToAdd, setSymbolToAdd] = useState(null)

    const autoSuggestRef = useRef(null);
    const autoSuggestFundamentalRef = useRef(null);
    const autoSuggestAddSymbolRef=useRef(null)

    //modal states
    const [showSelectFundamentalModal, setShowSelectFundamentalModal] = useState(false)
    const [showAddSymbolModal, setShowAddSymbolModal] = useState(false)
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    const getPeers = async (symbol) => {
        const params = { 'symbol': symbol };
    
        try {
            const response = await axios.post(constants.BACKEND_URL+'/get-peers/', params);
            const data = await JSON.parse(response.data.data);
            setParams((prevParams)=>{
                const newParams={
                    ...prevParams,
                    ['symbolsList']: data
                }
                return newParams
            })


            console.log(data)
            return data;
        } catch (error) {
            console.error("Error fetching peers:", error);
            // setError(`Failed to fetch peer companies for ${symbol}`)
            return [];
        }
    };


    const requestData = async () => {
        const symbolValue = autoSuggestRef.current ? autoSuggestRef.current.value : params.symbol;
    
        await getPeers(symbolValue)

        setParams((prevParams) => {
            const newParams = {
                ...prevParams,
                ['symbol']: symbolValue
            };
            console.log(newParams)
            
            setLoading(true); 
            setError(null); 
            axios.post(constants.BACKEND_URL+ENDPOINT, newParams) 
                .then(response => {
                    const parsedResponse = JSON.parse(response.data.data);
                    setData(parsedResponse);
                })
                .catch(error => {
                    console.error('Error during POST request:', error);
                    setError("Failed to load group analysis by time.");
                })
                .finally(() => {
                    setLoading(false); 
                });
            setDisplayedMetric(newParams.metric)
            setDisplayedInterval(newParams.periods)
            setDisplayedPeriodType(newParams.periodType)
            return newParams; 
        });

        if (initialRender) setInitialRender(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            await getPeers(params.symbol); 
            requestData();
          };
      
          fetchData();
    }, []);

    const handleTabClick = (targetID) => {
        setActiveTabID(targetID); // Update active tab on click
        console.log(targetID)
        
    };
    ////////////////////////////////////////////////////////////
    const groupedFundamentals = fundamentals.reduce((acc, item) => {
      const { sourceName } = item;
      if (!acc[sourceName]) {
        acc[sourceName] = []; // Initialize array if key doesn't exist
      }
      acc[sourceName].push(item);
      return acc;
    }, {});
    

    const backendToFrontendName = (backendName) => {
      return fundamentals.find((item)=> backendName === item.fmpName)?.name
    }
    const frontEndToBackendName = (frontendName) => {
      return fundamentals.find((item)=> frontendName === item.name)?.fmpName
    }
    const onFundamentalChange = (newState) => {
      setParams((prevParams)=>{
        const newParams={
          ...prevParams,
          ['metric']: frontEndToBackendName(newState)
        }
        console.log(newParams)
        return newParams
      })
    }
    const updateParams = (e) => {
      const { id, value } = e.target;
  
      // Update the params by setting the new value for the key that matches the input's id
      setParams((prevParams) => ({
          ...prevParams,
          [id]: value
      }));
    }
    const removeSymbol = (symbolToRemove) => {
      // remove from params so future requests wont use symbols
      setParams((prevParams)=>{
        const newParams = {
          ...prevParams,
          symbolsList: prevParams.symbolsList.filter(symbol => symbol !== symbolToRemove)
        }
        console.log(newParams)
        return newParams
      })

      // remove from table by updating the data state
      const updatedData = { ...data };
      delete updatedData[symbolToRemove];
      setData(updatedData); // Update state with the modified data

    }
    const addSymbol= (symbolToAdd) => {
      console.log(symbolToAdd)
    }

    return (
        <>
            <WindowPieces.OptionsContainer securitySearch={true}>
                <AutosuggestSecurities
                    id={'symbol'}
                    ref={autoSuggestRef}
                    defaultValue={params.symbol}
                    handleSubmitCustom={requestData}
                />
                
                <WindowPieces.ConfigContainer>
                  <Button2 onClick={()=>setShowSelectFundamentalModal(true)}>Select Fundamental</Button2>
                </WindowPieces.ConfigContainer>
                
                
                


            </WindowPieces.OptionsContainer>

            <WindowPieces.TabContainer tabs={tabs} activeTabID={activeTabID} onTabClick={handleTabClick} />
            <div className="ms-2 d-flex flex-row justify-content-between text-nowrap">
              <span>Displaying <span className="text-primary fw-bold">{backendToFrontendName(displayedMetric)} (Periods={displayedInterval}, Period Type={displayedPeriodType})</span>; Selected <span className="text-primary fw-bold">{backendToFrontendName(params.metric)} (Periods={params.periods}, Period Type={params.periodType})</span></span>
              <LoadButton onClick={requestData}>Run Group Analysis</LoadButton>
            </div>

            {loading ? ( 
                <LoadingSpinner />
            ) : error ? (
                <WindowError message={error}/>
            ) : (
                <>
                    
                    <WindowPieces.TabContent activeTabID={activeTabID} useKey={true} paddingBottom={'30px'}>
                        <GroupAnalysisTimeTable 
                          key={'group-time-table'} 
                          data={data}
                          onSymbolRemove={removeSymbol}
                          onSymbolAdd={console.log("Hey")} 
                          setShowAddSymbolModal={()=>setShowAddSymbolModal(true)}
                          />
                        <div key={'group-time-chart'}>

                        </div>
                    </WindowPieces.TabContent>
                    {/* <SortableTable data={sampleData} columns={sampleColumns} /> */}
                    
                </>     
            )}
            <CustomModal1
              show={showSelectFundamentalModal}
              body={
                <>
                  <h3>Search for Fundamental</h3>
                  <div className="d-flex flex-row w-100 justify-content-between">
                    <AutosuggestFundamentals
                      onFundamentalChange={onFundamentalChange}
                      ref={autoSuggestFundamentalRef}
                      defaultValue={backendToFrontendName(params.metric) || null}
                      // handleSubmitCustom={}
                      placeholder={"Enter ticker symbol"}
                    />
                    {/* <SitePieces.ZenithButtonPrimary label={"Confirm"} onClick={() =>setShowSelectFundamentalModal(false)} /> */}
                  </div>
                  <div className='text-center my-3'>OR use the menu to make a selection...</div>
                  <FileTabStructure
                      selectedMetric={params.metric} 
                      tabsObject={groupedFundamentals}
                      onClick={(metric)=> setParams((prevParams=>{
                        const newParams = {
                          ...prevParams,
                          ['metric']: metric
                        };
                        console.log(newParams)
                        return newParams
                      }))}
                      />
                  <h3 className="my-4">Interval & Number of Periods</h3>
                  <div className="container-fluid">
                      <div className="row">
                        <div className="col-3">
                          <label>Period</label>
                        </div>
                        <div className="col-9">
                          <WindowPieces.SquareInput 
                                        id={'periods'}
                                        type={'number'}
                                        placeholder={'Periods'}
                                        value={params.periods}
                                        onChange={updateParams}
                                    />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3">
                          <label>Period Type</label>
                        </div>
                        <div className="col-9">
                          <WindowPieces.SquareSelect
                            id={'periodType'}
                            innerHTMLList={['Annual', 'Quarter']} 
                            valueList={['annual', 'quarter']}
                            value={params.periodType}
                            onChange={updateParams}

                          />
                        </div>
                      </div>
                      
                  </div>
                  
                  </>
              }
              onClose={() => setShowSelectFundamentalModal(false)}
              onConfirm={() =>setShowSelectFundamentalModal(false)}
            />
            <CustomModal1 
              show={showAddSymbolModal}
              body={
                <>
                  <h3>Add a Symbol to Group</h3>
                  <AutosuggestSecuritiesModal
                            id={'add-symbol'}
                            ref={autoSuggestAddSymbolRef}
                            defaultValue={"Add Symbol to Group"}
                            handleSubmitCustom={symbolToAdd}
                  />
                </>
                
              }
              onClose={() => setShowAddSymbolModal(false)}
              onConfirm={() =>{

                setShowAddSymbolModal(false)
              }}
            
            />

        
        </>
    )
}

export default GroupAnalysisByTime;

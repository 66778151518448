import prepareWindow from "../../windowCreating/prepareWindow";
import React from "react";
import ReactDOM from "react-dom";
import Help from "../../../components/FeatureComponents/17_Help/17_Help";

async function handleHelp(symbol, isInitialDisplay=true, existingWindowID="") {
    const WINDOW_TYPE_NAME = 'window-help';
    const TITLE_PREFIX = 'Help ';

    const window = await prepareWindow(symbol, WINDOW_TYPE_NAME, TITLE_PREFIX, isInitialDisplay, existingWindowID)
    const destinationElement = window.querySelector('.window-content')

    ReactDOM.render(<Help/>, destinationElement)
}

export default  handleHelp 
import React, { useEffect, useState } from "react";

const CheckBox = React.memo(({ setBool, initialValue = false }) => {
    const [isChecked, setIsChecked] = useState(initialValue);

    useEffect(() => {
        setBool(isChecked); // Only notify the parent when `isChecked` changes
    }, [isChecked]);

    const handleChange = (event) => {
        setIsChecked(event.target.checked); // Internal state update
    };

    return (
        <input
            style={{
                margin: "0px",
                height: "20px",
                width: "20px",
                outline: "none",
                borderColor: "var(--grid)",
                backgroundColor: "var(--theme)"
            }}
            className="form-check-input rounded-0 border"
            type="checkbox"
            checked={isChecked}
            onChange={handleChange}
        />
    );
});

export default CheckBox

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { showAlert } from '../functions/showAlert';

function Cancel() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/terminal');
    showAlert('error', 'Error! Payment Cancelled!')

  }, [navigate]);

  return <div>Payment Successful! Thank you...</div>;
}

export default Cancel;

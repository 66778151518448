import React, {useState, useEffect, useRef} from "react";
import WindowPieces from "../../ui/small/WindowPieces";
import features from "../../../catalogs/features";
import constants from "../../../constants";
import ZenithTable1 from "../../ui/small/tables/ZenithTable1";

const Help = ({ }) => {
 

    const [activeTabID, setActiveTabID] = useState('functions')
    
    const tabs = [
        { label: "Functions", targetID: "functions" },
        { label: "FAQs", targetID: "faq" },
        { label: "Contact", targetID: "contact" },
    ];
    const handleTabClick = (targetID) => {
        setActiveTabID(targetID); // Update active tab on click
        console.log(targetID)
        
    };
    

    return (
        <>
            <>

                <WindowPieces.TabContainer tabs={tabs} activeTabID={activeTabID} onTabClick={handleTabClick} />

                <WindowPieces.TabContent activeTabID={activeTabID}>
                    <div id="functions">
                        <ZenithTable1.Table 
                            tablehead={
                                    <tr>
                                        <th>Function</th>
                                        <th>Description</th>
                                    
                                    </tr>
                            }
                            tablebody={
                                <>
                                    {features.map((feature, index)=>(
                                        <>
                                            {feature.code && (
                                                <tr key={index}>
                                                    <td className="text-primary">{feature.code}</td>
                                                    <td>{feature.featureName}</td>
                                                
                                                </tr>
                                            )}
                                        </>
                                    ))}
                                </>
                            }
                        />
                        
                    </div>
                    <div id="faq">
                        <div>FAQs</div>
                    </div>
                    <div id="contact">
                        <div>Contact</div>
                    </div>
                </WindowPieces.TabContent>
            </>
        
        </>
    );
};

export default Help;
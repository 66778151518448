import React from "react";
import ReactDOM from "react-dom";
import Watchlist from "../../../components/FeatureComponents/14_Watchlist/14_Watchlist";
import watchlistData from "../../memoryManagement/watchlistData";
import prepareWindow from "../../windowCreating/prepareWindow";

async function loadWatchlist() {
    try {
      let watchlist = await watchlistData.getWatchlist()
      watchlist = watchlist.watchlist_data
      console.log(watchlist)
      return watchlist;
    } catch (error) {
      console.error("Failed to load watchlist:", error);
      return {}
    }
}

async function handleWatchlist(symbol, isInitialDisplay=true, existingWindowID="") {
    const WINDOW_TYPE_NAME = 'window-watchlist';
    const TITLE_PREFIX = 'Watchlist ';

    const window = await prepareWindow(symbol, WINDOW_TYPE_NAME, TITLE_PREFIX, isInitialDisplay, existingWindowID)
    const destinationElement = window.querySelector('.window-content')


    const DEFAULT_WATCH_LIST = {
        'Indicies': ['^SPX', '^VIX', '^NDX'],
        'Tech': ['META', 'MSFT', 'NVDA', 'GOOGL', 'AMZN', 'TSLA'],
        "Finance": ["JPM", "GS", "BAC"]
    }

    const watchlist = await loadWatchlist()
    console.log(watchlist)
    let watchlistObject
    if (!watchlist || Object.keys(watchlist).length === 0) {
        watchlistObject=DEFAULT_WATCH_LIST
    } else {
        watchlistObject=watchlist
    }

    ReactDOM.render(<Watchlist  initWatchlistObject={watchlistObject}/>, destinationElement)
}

export default handleWatchlist 

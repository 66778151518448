import React from "react";
import ReactDOM from "react-dom";
import prepareWindow from "../../windowCreating/prepareWindow";
import Widget from "../../../components/FeatureComponents/25_Widget/25_Widget";

async function handleWidget(symbol, isInitialDisplay=true, existingWindowID="") {
    const WINDOW_TYPE_NAME = 'window-widget';
    const TITLE_PREFIX = 'Widget';

    const window = await prepareWindow(symbol, WINDOW_TYPE_NAME, TITLE_PREFIX, isInitialDisplay, existingWindowID)
    const destinationElement = window.querySelector('.window-content')

    ReactDOM.render(<Widget symbol={symbol} windowID={window.id} newWindow={isInitialDisplay}/>, destinationElement)
}

export default  handleWidget ;

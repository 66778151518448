import ajaxRequestFuncs from "../requestFuncs/ajaxRequestFuncs";
import axios from "axios";
import constants from "../../constants";

function saveLayout(layout) {
    console.trace("saveLayout invoked")
    const csrfToken = ajaxRequestFuncs.getCookie('csrftoken');
    axios.post(constants.BACKEND_URL+'/save-dashboard-layout/', layout, {
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,  
        },
        withCredentials: true,  
    })
    .then(response => {
        // console.log(response);  
    })
    .catch(error => {
        console.error("An error occurred:", error);  
    });
}

function getAndLoadLayout() {
    return axios.get(constants.BACKEND_URL+'/get-dashboard-layout/', {
        withCredentials: true,
        headers: {
            'X-CSRFToken': ajaxRequestFuncs.getCookie('csrftoken')
        }
    })
    .then(response => {
        console.log("Layout from DB Raw: ", response.data); // Log the layout data
        return response.data; 
    })
    .catch(error => {
        console.error("An error occurred:", error); 
        throw error;
    });
}

export default { saveLayout, getAndLoadLayout }
import React, { useState } from "react";
import CustomModal2 from "../CustomModal2";
import PageUpButton from "../../ui/small/buttons/PageUpButton";
import PageDownButton from "../../ui/small/buttons/PageDownButton";

const IntroductionModal = ({ show, setShow }) => {
    const [currentSlide, setCurrentSlide] = useState(0); // Start with the first slide

    // Define your slides as an array of JSX elements
    const slides = [
        <>
            <h2>Slide 1</h2>
            <p>Welcome to the FIGR Terminal! Here's some introductory content.</p>
            <img src="/path/to/image1.jpg" alt="Intro" style={{ width: "100%" }} />
        </>
        ,
        <>
            <h2>Slide 2</h2>
            <p>Welcome to the FIGR Terminal! Here's some introductory content.</p>
            <img src="/path/to/image1.jpg" alt="Intro" style={{ width: "100%" }} />
        </>,
        <>
            <h2>Slide 3</h2>
            <p>Welcome to the FIGR Terminal! Here's some introductory content.</p>
            <img src="/path/to/image1.jpg" alt="Intro" style={{ width: "100%" }} />
        </>,
        <>
            <h2>Slide 4</h2>
            <p>Welcome to the FIGR Terminal! Here's some introductory content.</p>
            <img src="/path/to/image1.jpg" alt="Intro" style={{ width: "100%" }} />
        </>,
    ];

    // Handle navigation
    const handleNextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % slides.length); // Cycle to the next slide
    };

    const handlePreviousSlide = () => {
        setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length); // Cycle to the previous slide
    };

    return (
        <CustomModal2
            show={show}
            title={"Welcome to FIGR Terminal!"}
            body={
                <>
                    <div  style={{ width: '400px', height: "400px", textAlign: 'center' }}>
                        <div>
                            {slides[currentSlide]} {/* Render the current slide */}
                        </div>

                        <div style={{position: 'absolute', bottom: "5px", left: '50%', transform: 'translate(-50%)'}}>
                            <div className="d-flex justify-content-center align-items-center mt-3">
                                {slides.map((_, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            width: "10px",
                                            height: "10px",
                                            margin: "0 5px",
                                            backgroundColor: index === currentSlide ? "var(--primary)" : "gray",
                                            borderRadius: "0px",
                                            transition: "background-color 0.3s ease",
                                        }}
                                    />
                                ))}
                            </div>
                            <div className="d-flex justify-content-center align-items-center mt-3">
                                <PageDownButton onClick={handlePreviousSlide} />
                                <PageUpButton onClick={handleNextSlide} />
                            </div>
                        </div>
                    </div>

                    
                </>
            }
            onClose={() => setShow(false)}
        />
    );
};

export default IntroductionModal;



import React from "react";


const TabToggleButton = ({
  label,
  color,
  isActive,
  onTabChange,
  onTabRemove,
  onChooseColor,
  onTabRename
}) => {
  return (
    <div
      className={`btn-group rounded-0 mx-1 text-nowrap`} style={{background: `${isActive? color: "var(--grid)"}`, height: '20px' }}
    >
      <button style={{color: `${isActive? 'black': color}`}} type="button" className="rounded-0 btn btn-sm" onClick={onTabChange}>
        {label}
      </button>
      {isActive && (
        <button style={{color: `${isActive? 'black': color}`}} type="button" className="rounded-0 btn btn-sm dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
          <span className="visually-hidden">Toggle Dropdown</span>
        </button>
      )}
      <ul
        className="dropdown-menu dropdown-menu-end rounded-0" style={{backgroundColor: "var(--theme)"}} data-bs-theme="dark">
        <li>
          <a className="dropdown-item" href="#" onClick={onTabRename}>Rename</a>
        </li>
        <li>
          <a className="dropdown-item" href="#" onClick={onChooseColor}>Set Color</a>
        </li>
        <li>
          <hr className="dropdown-divider"></hr>
        </li>
        <li>
          <a className="dropdown-item" style={{color: "var(--negative-c)"}} href="#" onClick={onTabRemove}>Delete Dashboard</a>
        </li>
      </ul>
    </div>
  )
};

export default TabToggleButton;

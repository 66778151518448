import React from "react";
import ReactDOM from "react-dom";
import prepareWindow from "../../windowCreating/prepareWindow";
import SenateHouseDisclosures from "../../../components/FeatureComponents/11_SenateHouseDisclosures/11_SenateHouseDisclosures";

async function handleSenateHouseDisclosure(symbol, isInitialDisplay=true, existingWindowID="") {
    const WINDOW_TYPE_NAME = 'window-senate-house-disclosure';
    const TITLE_PREFIX = 'Senate House Disclosures ';

    const window = await prepareWindow(symbol, WINDOW_TYPE_NAME, TITLE_PREFIX, isInitialDisplay, existingWindowID)
    const destinationElement = window.querySelector('.window-content')

    ReactDOM.render(<SenateHouseDisclosures symbol={symbol} windowID={window.id} newWindow={isInitialDisplay}/>, destinationElement)
}






export default  handleSenateHouseDisclosure 
import React from "react";

const SettingsSection = ({sectionTitle, children}) => {
    return (
        <>
            <div>
                <h6 className="fw-bold">{sectionTitle}</h6>
                <div className="container-fluid p-0">
                    {children}
                </div>
            </div>
            <hr></hr>
        </>
    )
}

export default SettingsSection
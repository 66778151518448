import React from "react";

const SettingLineWrapper = ({label, children}) => {
    return (
        <div className='row d-flex align-items-center mt-2'>
            <div className="text-nowrap col-2">
                <label>{label}</label>
            </div>
            <div className="ms-3 col-2 d-flex align-items-center">
                {children}
            </div>
        </div>
    )
}

export default SettingLineWrapper
import prepareWindow from "../../windowCreating/prepareWindow";
import React from "react";
import ReactDOM from "react-dom";
import InstitutionalOwnershipByStock from "../../../components/FeatureComponents/18_InstitutionalOwnershipByStock/18_InstitutionalOwnershipByStock";

async function handleInstitutionalOwnershipByStock(symbol, isInitialDisplay=true, existingWindowID="") {
    const WINDOW_TYPE_NAME = 'window-institutional-ownership-by-stock';
    const TITLE_PREFIX = 'Institutional 13-F Ownership ';

    const window = await prepareWindow(symbol, WINDOW_TYPE_NAME, TITLE_PREFIX, isInitialDisplay, existingWindowID)
    const destinationElement = window.querySelector('.window-content')

    ReactDOM.render(<InstitutionalOwnershipByStock symbol={symbol} windowID={window.id} newWindow={isInitialDisplay}/>, destinationElement)
}

export default  handleInstitutionalOwnershipByStock 
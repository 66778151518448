// import commands from "./catalogs/commands";
// import features from "../../../catalogs/features"
import features from "../catalogs/features"
import { showAlert } from "./showAlert";

export default async function handleCommandEntry(command, symbol="AAPL", newWindow=true, existingWindowID) {

    const suggestion = features.find(feature => feature.code === command);
    // const suggestion = commands.find(s => s.symbol === command);
    if (suggestion && suggestion.handler) {
        if (newWindow){ 
            await suggestion.handler(symbol); 
        }
        else {
            await suggestion.handler(symbol, false, existingWindowID)
        }
    } else {
        console.error(`Unknown command: ${command}`);
        showAlert("error", `Unknown Command: ${command}`)
    }
}
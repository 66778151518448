import React, { useState } from 'react';

const FileTabStructure = ({ tabsObject, onClick, selectedMetric }) => {
  console.log(selectedMetric)
  const [activeTab, setActiveTab] = useState(Object.keys(tabsObject)[0]);
  const [selectedItem, setSelectedItem] = useState(selectedMetric || null); // New state for selected item

  const renderTabs = () => {
      return Object.keys(tabsObject).map((tab, index) => (
        <button
          key={index}
          className={`py-2 text-nowrap tab-link ${activeTab === tab ? "active" : ""}`}
          onClick={() => setActiveTab(tab)}
        >
          {`${tab} (${tabsObject[tab].length})`}
        </button>
      ));
    };

    const renderTabContent = () => {
      return tabsObject[activeTab].map((item, index) => (
        <div
          key={index}
          className={`scrollable-list-item px-2 ${selectedItem === item.fmpName ? "text-primary fw-bold" : ""}`} // Apply "selected" style if item is active
          onClick={() => {
              setSelectedItem(item.fmpName); // Set selected item on click
              onClick(item.fmpName);
          }}
        >
          {item.name}
        </div>
      ));
    };

  return (
      <div className="container-fluid">
          <div className="row">
              <div className="col-4 ps-1">
                  {renderTabs()}
              </div>
              <div style={{ maxHeight: '200px', borderColor: "var(--grid)" }} className="col-8 border overflow-y-auto p-0">
                  {renderTabContent()}
              </div>
          </div>
      </div>
  );
};

export default FileTabStructure;

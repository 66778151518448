import React from 'react';

function WindowError({message}) {
    return (
        <div style={{color: "var(--negative-c)", backgroundColor: "var(--theme)"}} className='d-flex justify-content-center fw-bold align-items-center w-100 h-100'>
            {message}
        </div>
    )
}

export default WindowError
import createWindow from "./createWindow";
import dashboardLayout from "../memoryManagement/dashboardLayout";
import { getGlobalLayout, updateLayoutItem } from "../memoryManagement/layoutManager";

export default async function prepareWindow(symbol, windowTypeName, titlePrefix, isInitialDisplay, existingWindowID){
    let uniqueWindow;
    let destinationElement;

    // 1. Creating the Window or finding it, if it already exists.
    if (isInitialDisplay){
        uniqueWindow=await createWindow(windowTypeName)
        destinationElement = uniqueWindow.querySelector('.window-content')
        destinationElement.id = `${uniqueWindow.id}-content`
    }
    else {
        uniqueWindow=document.getElementById(existingWindowID)
        destinationElement=uniqueWindow.querySelector('.window-content')
    }

    // 2. Initializing the Window to the Database
    // const windowTitle = symbol? `${titlePrefix} ${symbol.toUpperCase()}`: titlePrefix;
    const windowTitle = titlePrefix
    // const layout = getLayout();
    // const targetWindowID = uniqueWindow.id;

    // if (document.getElementById(uniqueWindow.id)) {
    //     updateLayoutItem(uniqueWindow.id, {
    //         symbol: symbol? symbol: "N/A",
    //         windowTitle: windowTitle,
    //     });
    //     dashboardLayout.saveLayout(layout);
    // } 
    // else {
    //     console.error( `Window with ID ${targetWindowID} not found in layout.`)
    // }

    uniqueWindow.querySelector('.window-title').innerHTML = windowTitle;
    // uniqueWindow.dataset.symbol = symbol.toUpperCase()

    return uniqueWindow
}

import React from "react";

const PageUpButton = ({onClick}) => {
    return (
        <button 
            id='page-up' 
            style={{ width: "30px", aspectRatio: '1', borderRadius: "0px", borderColor: "var(--grid)" }} 
            className="btn btn-small border ms-2" 
            onClick={onClick}
        >
            &rarr;
        </button>
    )
}

export default PageUpButton
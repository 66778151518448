import React, { useState, useEffect, useRef, forwardRef} from "react";
import WindowPieces from "../../ui/small/WindowPieces";
import debounce from "../../../functions/helpers/debounce";
import SuggestionListForFundamentals from "../suggestionscontainers/SuggestionListForFundamentals";
import fundamentals from "../../../catalogs/fundamentals";

const AutosuggestFundamentals = forwardRef(({
    id,
    value,
    handleSubmitCustom,
    defaultValue,
    onFundamentalChange
}, ref) => {
    
    const DEBOUNCE_DELAY = 300;
    const MAX_RESULTS = 5;

    let timeoutId = null;
    const [activeInputElement, setActiveInputElement] = useState(null)
    const [activeSuggestionType, setActiveSuggestionType] = useState(null)

    const [suggestedFundamentals, setFundamentalSuggestions] = useState([])
    const [showSuggestionsMenu, setShowSuggestionsMenu] = useState(false)
    const [highlightedIndex, setHighlightedIndex] = useState(0)
    const [inputValue, setInputValue] = useState(defaultValue || ""); // Control the input value


    function highlightSuggestion(items, index) {
        const INACTIVE_CLASS = 'suggestion-inactive'
        const ACTIVE_CLASS = 'suggestion-active'

        items.forEach(item => {
            item.classList.remove(ACTIVE_CLASS)
            item.classList.add(INACTIVE_CLASS)
        })
        
        // Highlight target item
        const targetItem = items[index]
        if (targetItem) {
            targetItem.classList.remove(INACTIVE_CLASS)
            targetItem.classList.add(ACTIVE_CLASS)
            
            const suggestionType = targetItem.getAttribute('data-suggestion-type')
            setActiveSuggestionType(suggestionType)
        }
    }

    function updateInput(items, index) {
        const targetItem = items[index];
        const suggestionValue = targetItem.getAttribute('data-suggestion-value');
        setInputValue(suggestionValue);
        onFundamentalChange(suggestionValue)
    }
    
    async function displaySuggestions({query, inputElement}){
        console.log(query)
        console.log(fundamentals)
        const fundamentalSuggestionsFiltered = fundamentals.filter(suggestion=>
            suggestion.name.toLowerCase().startsWith(query) || suggestion.name.toUpperCase().startsWith(query) || suggestion.name.startsWith(query)
        )
        console.log(fundamentalSuggestionsFiltered)

        setFundamentalSuggestions([])
        setFundamentalSuggestions(fundamentalSuggestionsFiltered)
        setHighlightedIndex(0)
    }

    const handleInput = (e) => {
        e.stopPropagation()
        const query = e.target.value;
        if (query.length > 0) {
            debounce(()=> displaySuggestions({ query, inputElement: e.target }), DEBOUNCE_DELAY)();
           
        } else {
            setFundamentalSuggestions([])
            setHighlightedIndex(0)

        }

    }

    const handleKeyDown = (e) => {
        if (e.key == "Enter") {
            e.stopPropagation()
            setFundamentalSuggestions([])
            handleBlur(e)
            // handleSubmitCustom()
            // onFundamentalChange(inputValue)
        }

        else {
            e.stopPropagation()
            if (e.keyCode==40 && suggestedFundamentals.length > 0) {
                e.preventDefault()
                const suggestionElements = Array.from(document.querySelectorAll('.suggestion-item'))
                setHighlightedIndex((prevIndex)=>{
                    const newIndex = (prevIndex+1) % suggestionElements.length
                    highlightSuggestion(suggestionElements, newIndex)
                    updateInput(suggestionElements, newIndex)
                    return newIndex
                })
              
            }

            else if (e.keyCode === 38 && suggestedFundamentals.length > 0) {
                e.preventDefault(); 
                const suggestionElements = Array.from(document.querySelectorAll('.suggestion-item'));
                setHighlightedIndex((prevIndex)=>{
                    const newIndex = (prevIndex-1+suggestionElements.length) % suggestionElements.length
                    highlightSuggestion(suggestionElements, newIndex)
                    updateInput(suggestionElements, newIndex)
                    return newIndex
                })
               
            }

        }
    }

    const handleFocus = (e) => {
        setFundamentalSuggestions([])
        setActiveInputElement(e.target)
        setHighlightedIndex(0)
    }

    const handleBlur = (e)=> {
        setFundamentalSuggestions([])
        setHighlightedIndex(0)
        setActiveInputElement(null)    
    }


    return(
        <div className="flex-grow-4 m-1 w-100">
            <div style={{borderColor: "var(--grid)"}}  className="border d-flex flex-row">
                <div style={{paddingLeft: '0.1em', paddingTop: '0.1em', lineHeight: '0em', fontSize: '1.5em', marginRight: "4px", display: 'flex', alignItems: "center"}}>&#36;</div>
                <input 
                    ref={ref}
                    id={id || undefined}
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                        onFundamentalChange(e.target.value)
                    }}
                    onInput={(e) => handleInput(e)}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    // defaultValue={defaultValue || undefined}
                    style={{
                        border: 'none',
                        textTransform: 'uppercase',
                        borderRadius: WindowPieces.borderRadius, 
                        padding: '0px',
                        width: '100%',
                        backgroundColor: "var(--theme)",
                        color: "var(--text)"
                        // minWidth: '500px'
                    }}
                    type="text"
                    autoComplete="off"
                />
            </div>
            {
                (inputValue.length > 0 && activeInputElement) && (
                    <SuggestionListForFundamentals fundamentalSuggestions={suggestedFundamentals}/>
                )
            }
        </div>
    )


})

export default AutosuggestFundamentals
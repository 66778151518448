import App from './App';
import ReactDOM from 'react-dom';
import React from 'react';
// import "./assets/landing-page/css/main.min.css"
// import "./assets/landing-page/css/theme.min.css"

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import constants from '../../../constants';
import CustomModal1 from '../../modals/CustomModal1';
import ChartSettingsComponent from '../../ui/small/ChartSettingsComponent';
import getRootColorString from '../../../functions/helpers/getRootColorString';

const Histogram = ({windowID, bin_edges, counts}) => {
    Chart.defaults.font.family =  'Inter'
    const textColor = getRootColorString("--text")

    const [color, setColor] = useState('green')
    const [showSettings, setShowSettings] = useState(false)


    const chartContainerRef = useRef(null)
    const mappedData = bin_edges.map((bin, index)=>(
        {x: bin, y: counts[index]}
    ))

    useEffect(() => {
        // if (!data) return;

        const chart = new Chart(chartContainerRef.current, {
            type: 'bar',
            data: {
                labels: bin_edges,
                datasets: [{
                        label: "Frequency",
                        data:mappedData,
                        borderColor: color,
                        backgroundColor: color,
                        borderWidth: 1,
                        borderColor: 'black',
                        barPercentage: 1,
                        categoryPercentage: 1
                    }
                ]
            },
            options: {
                scales: {
                    x: {
                        type: 'linear',
                        offset: false,
                        ticks: {
                            color: textColor,
                        },
                        grid: {
                            offset: false,
                            display: false,
                            color: '#444',
                        },
                        title: {
                            color: textColor,
                            display: true,
                            text: "Bins"
                        }
                    },
                    y: {
                        title: {
                            color: textColor,
                            display: true,
                            text: "Frequency"
                        },
                        ticks: {
                            color: textColor,
                        }
                    }
                },
                plugins: {
                    htmlLegend: {
                        containerID: windowID+' '+'legend-container',
                      },
                    legend: {
                        display: false,
                        },
                    tooltip: {
                        ...constants.CHARTJS_TOOLTIP_STYLE
                    }
                },
                responsive: true,
                maintainAspectRatio: false
            },
            plugins: [constants.CHARTJS_HTML_LEGEND_PLUGIN],
        });

        return () => {
            chart.destroy();
        };
    }, [color]);

    return (
        <>
            <div>
                <div id={windowID+' '+'legend-container'}></div>
                <div className='position-relative'>
                    <canvas ref={chartContainerRef} style={{ width: '200px', height: '300px'}}></canvas>
                    <ChartSettingsComponent onClick={()=>setShowSettings(true)}/>
                </div>
                <CustomModal1 
                    show={showSettings}
                    body={
                        <h3>Chart Settings</h3>
                    }
                    onClose={()=>setShowSettings(false)}
                    onConfirm={()=>setShowSettings(false)}
                
                />
            </div>
        </>

    );
};

export default Histogram;
import getRootColorString from "./functions/helpers/getRootColorString";

const getOrCreateLegendList = (chart, id) => {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer.querySelector('ul');
  
    if (!listContainer) {
      listContainer = document.createElement('ul');
      listContainer.style.display = 'flex';
      listContainer.style.flexDirection = 'row';
      listContainer.style.margin = 0;
      listContainer.style.padding = 0;
  
      legendContainer.appendChild(listContainer);
    }
  
    return listContainer;
};
  
const CHARTJS_HTML_LEGEND_PLUGIN = {
    id: 'htmlLegend',
    afterUpdate(chart, args, options) {
        const ul = getOrCreateLegendList(chart, options.containerID);

        // Remove old legend items
        while (ul.firstChild) {
            ul.firstChild.remove();
        }

        // Reuse the built-in legendItems generator
        const items = chart.options.plugins.legend.labels.generateLabels(chart);

        items.forEach(item => {
            const li = document.createElement('li');
            li.style.alignItems = 'center';
            li.style.cursor = 'pointer';
            li.style.display = 'flex';
            li.style.flexDirection = 'row';
            li.style.padding = '5px';

            li.onclick = () => {
                const {type} = chart.config;
                if (type === 'pie' || type === 'doughnut') {
                // Pie and doughnut charts only have a single dataset and visibility is per item
                chart.toggleDataVisibility(item.index);
                } else {
                chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                }
                chart.update();
            };

            // Color box
            console.log(item)
            console.log("1px solid " + item.strokeStyle)
            const boxSpan = document.createElement('span');
            boxSpan.style.background = item.fillStyle;
            boxSpan.style.border = item.lineWidth > 0? "1px solid " + item.strokeStyle: 'none'
            boxSpan.style.display = 'inline-block';
            boxSpan.style.flexShrink = 0;
            boxSpan.style.height = '20px';
            boxSpan.style.marginRight = '10px';
            boxSpan.style.width = '20px';

            // Text
            const textContainer = document.createElement('p');
            textContainer.style.color = item.fontColor;
            textContainer.style.margin = 0;
            textContainer.style.padding = 0;
            textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

            const text = document.createTextNode(item.text);
            textContainer.appendChild(text);

            li.appendChild(boxSpan);
            li.appendChild(textContainer);
            ul.appendChild(li);
        });
    }
    };

const TABLE_BS_CLASSES="table table-dark text-light table-borderless table-sm table-hover w-100 m-0"
const TABLE2_BS_CLASSES="table table-dark text-light table-borderless table-striped table-hover m-0"

const COLORS = [
    '#ffd700',  
    '#0CB577',  // green
    '#b50c3c',  // red
    '#3357ff',  // blue
    '#ff33a1',  // pink
    '#33fff7',  // cyan
    '#ff5733',  // red-orange
    '#f7ff33',  // yellow
    '#5733ff',  // purple
    '#ff3380',  // magenta
    '#33ffb0'   // teal
  ];

const POSITIVE_COLOR = getRootColorString("--positive")
const NEGATIVE_COLOR = getRootColorString("--negative")

const tooltipColor = getRootColorString("--grid")

const CHARTJS_TOOLTIP_STYLE = {
  cornerRadius: '0px',
  titleMarginBottom: 1,
  backgroundColor: tooltipColor,
  yAlign: 'bottom',
  displayColors: false,
}

const WINDOWPIECES_VERTICAL_PADDING = '1px'
const WINDOWPIECES_BORDER_RADIUS = '0px'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const DATA_API_KEY = "d7594509a9042376633ef5951c51f05c" // REMOEV WHEN DONE TESTING

const DEFAULT_LAYOUT =[]

// const DEFAULT_LAYOUT = []

export default {
    BACKEND_URL,
    DATA_API_KEY,
    TABLE_BS_CLASSES,
    TABLE2_BS_CLASSES,
    COLORS,
    POSITIVE_COLOR,
    NEGATIVE_COLOR,
    CHARTJS_TOOLTIP_STYLE,
    CHARTJS_HTML_LEGEND_PLUGIN,
    WINDOWPIECES_VERTICAL_PADDING,
    WINDOWPIECES_BORDER_RADIUS,
    DEFAULT_LAYOUT
}

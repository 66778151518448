import React, { useState, forwardRef } from 'react';
import constants from '../../../constants';

const verticalPadding = constants.WINDOWPIECES_VERTICAL_PADDING
const borderRadius = constants.WINDOWPIECES_BORDER_RADIUS


function LoadButton({label, onClick, fullWidth=false}) {
    return (
        <button className={`btn btn-primary load border-0 ${fullWidth? 'w-100': 'm-1'}  `} style={{height: '24px', borderRadius: borderRadius, paddingBlock: verticalPadding}} onClick={onClick}>{label}</button>
    )
}

function LoadButtonSecondary({label, onClick, fullWidth=false}) {
    return (
        <button className={`btn btn-secondary load border-0 ${fullWidth? 'w-100': ''}  `} style={{borderRadius: borderRadius, paddingBlock: verticalPadding}} onClick={onClick}>{label}</button>
    )
}

function SquareRadioButton({label, onClick}) {
    return (
        <button style={{ borderRadius: "0px", fontSize: '10px', height: '18px', padding: '1px', color: "var(--text)", borderColor: "var(--grid)"}} className="btn btn-small border m-0" onClick={onClick}>
            {label}
        </button>

    )
    
}

function SquareInput({title='Periods', type, id, placeholder, defVal, value, onChange, onKeyDown}) {
    return (

        <input  
            title={title}    
            style={{
                height: '28px', 
                borderRadius: borderRadius, 
                paddingBlock: verticalPadding, 
                fontSize: '12px',
                borderColor: "var(--grid)",
                backgroundColor: "var(--theme)",
                color: "var(--text)"
            
            }} 
            value={value || undefined} // Only pass value if it exists
            onChange={onChange} 
            onKeyDown={onKeyDown} 
            defaultValue={defVal || undefined} 
            type={type} 
            id={id} 
            min={0}
            max={100}
            className="me-1 submit-on-enter border" 
            // placeholder={placeholder}
            
            >
        </input>
      
 
    )
}
function SquareSelect({valueList, innerHTMLList, name, id, value, onChange}) {
    return (
  
        <select style={{
            height: '28px', 
            borderRadius: borderRadius, 
            paddingBlock: verticalPadding,
            borderColor: "var(--grid)",
            backgroundColor: "var(--theme)",
            color: "var(--text)"
            
            }} onChange={onChange} value={value}  name={name} id={id} className="me-1 submit-on-enter border">
            {valueList.map((value, index) => (
                    <option key={index} value={value}>
                        {innerHTMLList[index]}
                    </option>
                ))}
        </select>

    )
}


function SquareOptionButton({label, value, onClick}) {
    return (
        <button className="mx-1 btn btn-outline-black m-1 hist-timeframe-btn border align-middle" value={value} style={{maxHeight:"32px", borderRadius: borderRadius, fontSize: "16px", padding: "1px 12px", maxHeight: "24px", color: "var(--text)", borderColor: "var(--grid)"}} onClick={onClick}>{label}</button>
    )
}


function SquareWindowTab({ label, targetID }) {

    return (
        <button
            style={{color: "var(--text)"}}
            className="col btn-sm text-nowrap tab-switch-btn tab-link"
            data-target={targetID}
        
        >
            {label}
        </button>
    );
}




//////////////////////////////////////////////////////////////////////////// AFTER RESTRUCTURING

const OptionsContainer = ({ children, securitySearch=false }) => {
    return (
        <div style={{backgroundColor: "var(--theme)"}} className='position-relative d-flex flex-row justify-content-between border-bottom'>
            {children}
        </div>
    )
}

const ConfigContainer = ({ children }) => {
    return (
        <div className="d-flex flex-row align-items-center">
            {children}
        </div>
    )
}









const Tab = ({ label, targetID, onClick, isActive }) => {
    return (
        <button
            style={{
                backgroundColor: "var(--theme)",
                color: "var(--text)"

            }}
            className={`btn-sm text-nowrap tab-switch-btn tab-link ${isActive ? 'active' : ''}`}
            onClick={() => onClick(targetID)}
        >
            {label}
        </button>
    );
};

// TabContainer component to manage the tabs and content
const TabContainer = ({ tabs, activeTabID, onTabClick }) => {
    return (
        <div className='
        d-flex 
        flex-row 
        justify-content-around 
        tab-container
        border-dark2 
        border-bottom 
       '>
            {tabs.map(tab => (
                <Tab 
                    key={tab.targetID} 
                    label={tab.label} 
                    targetID={tab.targetID} 
                    onClick={onTabClick}
                    isActive={activeTabID === tab.targetID}
                />
            ))}
        </div>
    );
};

const TabContent = ({ children, activeTabID, useKey = false , paddingBottom}) => {

    return (
        <div 
        style={{paddingBottom: paddingBottom}}
        className="
        tab-content 
        overflow-x-auto 
        overflow-y-auto 
        flex-grow-1">
            {children.map((child, index) => {
                // Use child.key or child.id based on useKey
                const identifier = useKey ? child.key : child.props.id;
                return (
                    <div 
                        key={index} 
                        className={`tab-pane ${identifier === activeTabID ? 'd-block' : 'd-none'}`}
                    >
                        {child}
                    </div>
                );
            })}
        </div>
    );
};

const Content = forwardRef(({ children, paddingBottom }, ref) => {
    return (
        <div ref={ref} 
        style={{paddingBottom: paddingBottom}}
        className="
        overflow-x-auto 
        overflow-y-auto 
        flex-1
        h-100
        
        ">
            {children}
        </div>
    );
});

const ChartContainer = forwardRef(({ children, paddingBottom }, ref) => {
    return (
        <div ref={ref} 
        style={{paddingBottom: paddingBottom}}
        className="
        overflow-x-hidden 
        overflow-y-hidden 
        flex-1
        h-100
        ">
            {children}
        </div>
    );
});





const PageViewer = ({currentPage, totalPages=100, changePageFunc, handlePageInput}) => {
    return (
        <div style={{marginBottom: '30px'}} className="window-page-viewer d-flex justify-content-center align-items-center border-top border-dark2 p-2">
            <button 
                id='page-down' 
                style={{ width: "30px", aspectRatio: '1', borderRadius: "0px", borderColor: "var(--grid)" }} 
                className="btn btn-small border me-2" 
                onClick={() => changePageFunc(currentPage - 1)}
                disabled={currentPage === 1} // Disable if on the first page
            >
                &larr;
            </button>
            <input
                type="number" 
                value={currentPage}
                style={{
                    backgroundColor: "var(--theme)",
                    borderColor: "var(--grid)",
                    color: "var(--text)",
                    width: "40px", 
                    aspectRatio: '2', 
                    borderRadius: "0px"
                }}
                className="submit-on-enter figr-form-control text-center mx-2"
                placeholder="0"
                min="1"
                max={totalPages}
                onChange={handlePageInput}
            />
            <button 
                id='page-up' 
                style={{ width: "30px", aspectRatio: '1', borderRadius: "0px", borderColor: "var(--grid)"  }} 
                className="btn btn-small border ms-2" 
                onClick={() => changePageFunc(currentPage + 1)}
                disabled={currentPage === totalPages} // Disable if on the last page
            >
                &rarr;
            </button>
        </div>
    )
}









export default {
    SquareLoadButton: LoadButton, 
    SquareLoadButtonSecondary: LoadButtonSecondary,
    SquareRadioButton, 
    SquareInput, 
    SquareSelect, 
    SquareOptionButton, 
    SquareWindowTab, 
    OptionsContainer,
    ConfigContainer,
    Tab,
    TabContainer, 
    TabContent,
    Content,
    ChartContainer,
    PageViewer,
    borderRadius, 
    verticalPadding}
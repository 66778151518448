import axios from "axios";
import ajaxRequestFuncs from "../ajaxRequestFuncs";

function handleExcelDownload(e) {
    const windowElement = e.target.closest('.window');
    const windowType = windowElement.getAttribute('data-window-type')
    const parsedWindowType = windowType.replace(/-\d+$/, '');
    let fileName;

    const inputsDict = Array.from(windowElement.querySelectorAll('.search, .submit-on-enter'))
    .reduce((acc, input) => {
        if (input.classList.contains('search')) {
            acc['symbol'] = input.value;  // Assign a fixed key 'ticker' for .search elements
        } else if (input.id) {
            acc[input.id] = input.value;
        }
        return acc;
    }, {});
    const requestData = inputsDict

    if (parsedWindowType == "window-financial-statements") {
        fileName = `${inputsDict['symbol']}_Financials.xlsx`
    }


    axios.post('/excel-download/', requestData, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': ajaxRequestFuncs.getCookie('csrftoken')
          },
        responseType: 'blob',  
        withCredentials: true
    })
    .then(blob => {
        // Create a link to download the file

        if (blob.data.size > 0) {
            const url = URL.createObjectURL(blob.data);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName; // Name of the downloaded file
            document.body.appendChild(a);
            a.click();
            a.remove();
            URL.revokeObjectURL(url); // Clean up the URL.createObjectURL reference
        } else {
            console.error('Received an empty file.');
        }
    })
    .catch(error => {
        console.error('Error:', error);
        console.log("Open Modal that Prompts Premium Subscription")

        
    });






}   

export default handleExcelDownload
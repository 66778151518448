import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import constants from '../constants';

const createCustomerPortal = async () => {
    try {
        const response = await axios.post(constants.BACKEND_URL+'/create-customer-portal/');
        window.location.href = response.data.url; 
        
    } catch (err) {
        console.error("Error creating customer portal session:", err);
    }
};

export default createCustomerPortal

import prepareWindow from "../../windowCreating/prepareWindow";
import EarningsTranscripts from "../../../components/FeatureComponents/5_EarningsTranscripts/5_EarningsTranscripts";
import ReactDOM from 'react-dom'
import EPS from "../../../components/FeatureComponents/24_EPS/24_EPS";

async function handleEps(symbol, isInitialDisplay=true, existingWindowID="") {
    const WINDOW_TYPE_NAME = 'window-eps';
    const TITLE_PREFIX = 'Earnings Per Share ';
  
    const window = await prepareWindow(symbol, WINDOW_TYPE_NAME, TITLE_PREFIX, isInitialDisplay, existingWindowID)
    const destinationElement = window.querySelector('.window-content')

    ReactDOM.render(<EPS symbol={symbol} windowID={window.id} newWindow={isInitialDisplay}/>, destinationElement)
  }

export default handleEps
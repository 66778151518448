import React from "react";

function calculatePercentageChange(arr) {
    return arr.map((value, index) => {
        if (index === 0) {
            return '';  // First value has no previous value to compare with
        } else {
            let previousValue = arr[index - 1];
            let percentageChange = (value - previousValue) / previousValue;
            return percentageChange.toFixed(2);  // Round to two decimal places
        }
    });
}

const DoubleLineGrowth = ({rowName, rowValues}) => {
    const growthArray = calculatePercentageChange(rowValues)

    return (
        <>
            <tr>
                <td>{rowName}</td>
                {rowValues.map((value, idx) => (
                    <td key={idx}>{value}</td>
                ))}
            </tr>
            <tr>
                <td>{rowName} % Growth</td>
                {growthArray.map((value, idx) => (
                    <td key={idx}>{value}</td>
                ))}
            </tr>
        
        </>
        
    )

}

const DoubleLinePercentOf = ({rowName, rowValues, targetRowName, targetRowValues}) => {
    return (
        <>
            <tr>
                <td>{rowName}</td>
                {rowValues.map((value, idx) => (
                    <td key={idx}>{value}</td>
                ))}
            </tr>
            <tr>
                <td>{rowName} % of {targetRowName}</td>
                {rowValues.map((value, idx) => (
                    <td key={idx}>{value/targetRowValues[idx]}</td>
                ))}
            </tr>

        
        </>
    )
}

export default {
    calculatePercentageChange,
    DoubleLineGrowth,
    DoubleLinePercentOf
}
import ajaxRequestFuncs from "../requestFuncs/ajaxRequestFuncs";
import axios from "axios";

function saveWatchlist(watchlistObject) {
    const csrfToken = ajaxRequestFuncs.getCookie('csrftoken');
    
    axios.post('/save-watchlist/', watchlistObject, {
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,  // Include CSRF token in the header
        },
        withCredentials: true,  // Ensure that the cookie is sent with the request
    })
    .then(response => {
        console.log(response);  // Log the successful response
    })
    .catch(error => {
        console.error("An error occurred:", error);  // Log any errors
    });
}

function getWatchlist() {
    return axios.get('/get-watchlist/', {
        withCredentials: true,
        headers: {
            'X-CSRFToken': ajaxRequestFuncs.getCookie('csrftoken') // Adding CSRF token in case it's required
        }
    })
    .then(response => {
        // console.log(response.data); // Log the layout data
        console.log(response)
        return response.data; // Return the layout data
    })
    .catch(error => {
        console.error("An error occurred:", error); // Log any errors
        throw error; // Re-throw the error to be handled by the caller
    });
}

export default { saveWatchlist, getWatchlist }
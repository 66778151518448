import React, {useState} from "react";
import constants from "../../../../constants";
import ZenithTable1 from "./ZenithTable1";

const ZenithTable1Sortable = ({ data, columns }) => {
    const [tableData, setTableData] = useState(data);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: "NONE" });
  
    // Function to handle sorting when clicking a column header
    const handleSort = (key) => {
      let direction = "ASC";
      if (sortConfig.key === key && sortConfig.direction === "ASC") {
        direction = "DESC";
      } else if (sortConfig.key === key && sortConfig.direction === "DESC") {
        direction = "NONE";
      }
  
      const sortedData = [...tableData];
      if (direction === "ASC") {
        sortedData.sort((a, b) => (a[key] > b[key] ? 1 : -1));
      } else if (direction === "DESC") {
        sortedData.sort((a, b) => (a[key] < b[key] ? 1 : -1));
      }
  
      setTableData(direction === "NONE" ? data : sortedData);
      setSortConfig({ key, direction });
    };
  
    // Helper function to render sorting icons
    const renderSortIcon = (key) => {
      if (sortConfig.key !== key) return null;
      if (sortConfig.direction === "ASC") return "↑";
      if (sortConfig.direction === "DESC") return "↓";
      return null;
    };
  
    return (
      <ZenithTable1.Table 
        tablehead={
          <tr>
            {columns.map((col) => (
              <th
                key={col.key}
                onClick={() => handleSort(col.key)}
           
              >
                {col.label} {renderSortIcon(col.key)}
              </th>
            ))}
          </tr>
        }
        tablebody={
          <>
            {tableData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((col) => (
                  <td key={col.key} >
                    {row[col.key]}
                  </td>
                ))}
              </tr>
            ))}
          </>
        }
      />
    );
};

export default ZenithTable1Sortable
import React from "react";
import ReactDOM from "react-dom";
import prepareWindow from "../../windowCreating/prepareWindow";
import SECFIlings from "../../../components/FeatureComponents/10_SECFilings/10_SECFilings";

async function handleSECFilings(symbol, isInitialDisplay=true, existingWindowID="") {
    const WINDOW_TYPE_NAME = 'window-sec-filings';
    const TITLE_PREFIX = 'SEC Filings ';

    const window = await prepareWindow(symbol, WINDOW_TYPE_NAME, TITLE_PREFIX, isInitialDisplay, existingWindowID)
    const destinationElement = window.querySelector('.window-content')

    ReactDOM.render(<SECFIlings symbol={symbol} windowID={window.id} newWindow={isInitialDisplay}/>, destinationElement)
}


export default  handleSECFilings 
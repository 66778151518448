import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import constants from "../../../constants";
import LoadingSpinner from "../../misc/LoadingSpinner";
import WindowPieces from "../../ui/small/WindowPieces";
import CustomModal1 from "../../modals/CustomModal1";
import AutosuggestSecurities from "../../autosuggest/searchbars/AutosuggestSecurities_v3";
import LoadButton from "../../ui/small/buttons/LoadButton";
import AddButton from "../../ui/small/buttons/AddButton";
import ZenithTable1 from "../../ui/small/tables/ZenithTable1";


const GroupAnalysisByMetric = ({ symbol }) => {
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 1. DEFAULT CONSTANTS
    const DEFAULT_PARAMS = {
        symbol: symbol,
        metrics: ["revenue", "netIncome", "freeCashFlow"],
    };
    const ENDPOINT = "/group-metric/";

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 2. STATES
    const [params, setParams] = useState(DEFAULT_PARAMS);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const autoSuggestRef = useRef(null);

    // States for company names and metrics
    const [companyNames, setCompanyNames] = useState([]);
    const [metrics, setMetrics] = useState([]);

    const [showAddTickerModal, setShowAddTickerModal] = useState(false);
    const [newTicker, setNewTicker] = useState("");
    const [showAddMetricModal, setShowAddMetricModal] = useState(false);
    const [newMetric, setNewMetric] = useState("");

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 3. DATA FETCHING

    const requestData = () => {
        const symbolValue = autoSuggestRef.current
            ? autoSuggestRef.current.value
            : params.symbol;

        // Update params using the previous state value
        setParams((prevParams) => {
            const newParams = {
                ...prevParams,
                symbol: symbolValue.toUpperCase(),
            };

            setLoading(true);
            axios
                .post(constants.BACKEND_URL+ENDPOINT, newParams)
                .then((response) => {
                    const parsedResponse = response.data.data;
                    const parsedData = JSON.parse(parsedResponse);
                    setData(parsedData);
                    setCompanyNames(Object.keys(parsedData));
                    // Assuming that each company has the same set of metrics, you can extract metrics from the first company
                    const firstCompany = Object.keys(parsedData)[0];
                    setMetrics(Object.keys(parsedData[firstCompany]));
                })
                .catch((error) => {
                    console.error("Error during POST request:", error);
                })
                .finally(() => {
                    setLoading(false);
                });

            return newParams; // Return the new params for state update
        });
    };

    useEffect(() => {
        requestData(); // Initial request
    }, []);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 4. UTILITY FUNCTIONS

    const calculateMean = (arr) =>
        arr.reduce((acc, val) => acc + val, 0) / arr.length;
    const calculateMedian = (arr) => {
        const sorted = [...arr].sort((a, b) => a - b);
        const mid = Math.floor(sorted.length / 2);
        return sorted.length % 2 !== 0
            ? sorted[mid]
            : (sorted[mid - 1] + sorted[mid]) / 2;
    };
    const calculateMax = (arr) => Math.max(...arr);
    const calculateMin = (arr) => Math.min(...arr);

    const metricData = metrics.map((metric) => {
        return companyNames.map((company) => data[company][metric]);
    });

    // Calculate statistics for each metric
    const means = metricData.map(calculateMean);
    const medians = metricData.map(calculateMedian);
    const maxValues = metricData.map(calculateMax);
    const minValues = metricData.map(calculateMin);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 5. EVENT HANDLERS

    const handleAddTicker = () => {
        setShowAddTickerModal(true);
    };
    const handleConfirmAddTicker = () => {
        setShowAddTickerModal(false);
    };

    const handleAddMetric = () => {
        setShowAddMetricModal(true);
    };
    const handleConfirmAddMetric = () => {
        setShowAddMetricModal(false);
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 6. RENDER

    return (
        <>
            {loading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <WindowPieces.OptionsContainer securitySearch={true}>
                        <AutosuggestSecurities
                            id={'symbol'}
                            ref={autoSuggestRef}
                            defaultValue={params.symbol}
                            handleSubmitCustom={requestData}
                        />
                        <WindowPieces.ConfigContainer>
                            <LoadButton onClick={requestData}>LOAD</LoadButton>
                        </WindowPieces.ConfigContainer>
                    </WindowPieces.OptionsContainer>

                    <WindowPieces.Content>
                        <div id="group-analysis-metric-table">
                            <ZenithTable1.Table 
                                tablehead={
                                    <tr>
                                        <th>Company</th>
                                        {metrics.map((metric, i) => (
                                            <th key={i}>{metric}</th>
                                        ))}
                                        <th className="text-center">
                                        <AddButton onClick={handleAddMetric}>
                                            + Add Fundamental
                                        </AddButton>
                                        </th>
                                    </tr>
                                }
                                tablebody={
                                    <>  
                                        {companyNames.map((company) => (
                                            <tr key={company}>
                                                <td>{company}</td>
                                                {metrics.map((metric, i) => (
                                                    <td key={i}>
                                                        {data[company][metric].toLocaleString()}
                                                    </td>
                                                ))}
                                                <td></td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td className="text-center">
                                                <AddButton onClick={handleAddTicker}>
                                                    + Add Symbol
                                                </AddButton>
                                            </td>
                                            <td colSpan={metrics.length + 1}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Mean</strong>
                                            </td>
                                            {means.map((mean, i) => (
                                                <td key={i}>
                                                    {mean.toLocaleString(undefined, {
                                                        maximumFractionDigits: 2,
                                                    })}
                                                </td>
                                            ))}
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Median</strong>
                                            </td>
                                            {medians.map((median, i) => (
                                                <td key={i}>
                                                    {median.toLocaleString(undefined, {
                                                        maximumFractionDigits: 2,
                                                    })}
                                                </td>
                                            ))}
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Max</strong>
                                            </td>
                                            {maxValues.map((max, i) => (
                                                <td key={i}>{max.toLocaleString()}</td>
                                            ))}
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Min</strong>
                                            </td>
                                            {minValues.map((min, i) => (
                                                <td key={i}>{min.toLocaleString()}</td>
                                            ))}
                                            <td></td>
                                        </tr>
                                    </>

                                }   

                            />
                        </div>
                    </WindowPieces.Content>
                    <CustomModal1
                            show={showAddTickerModal}
                            title="Add New Ticker"
                            body={
                                <AutosuggestSecurities
                                    value={newTicker}
                                    onChange={(e) =>
                                        setNewTicker(e.target.value.toUpperCase())
                                    }
                                    placeholder={"Enter ticker symbol"}
                                />
                    
                            }
                            onClose={() => setShowAddTickerModal(false)}
                            onConfirm={handleConfirmAddTicker}
                        />
                </>
            )}
        </>
    );
};

export default GroupAnalysisByMetric;

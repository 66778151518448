import React from 'react';

const SuggestionMenu = ({ securitySuggestions, functionSuggestions, nonSecurityFunctionSuggestions }) => {
    function getSuggestionTypeString(suggestionObject) {
        let suggestionTypeString = 'EQUITY';

        if (suggestionObject.suggestionType === 'security') {
            if (suggestionObject.exchangeShortName === 'ECONOMY') {
                suggestionTypeString = 'ECONOMICS'; 
            } else if (suggestionObject.exchangeShortName === 'CRYPTO') {
                suggestionTypeString = 'CRYPTO';
            } else if (suggestionObject.exchangeShortName === 'FOREX') {
                suggestionTypeString = 'FOREX';
            }
        } else {
            suggestionTypeString = 'FUNCTION'; // function is more clear than command
        }

        return suggestionTypeString;
    }

    return (
        <div 
            className='border'
            style={{
                position: 'absolute',
                top: '100%',
                left: '0',
                overflowY: 'auto',
                overflowX: 'hidden',
                minWidth: '100%',
                width: '700px',
                zIndex: 1000,
                textAlign: 'left',
                backgroundColor: "var(--theme)",
                borderColor: "var(--grid)"
            }} >
            {securitySuggestions.length > 0 ? (
                <>
                    {(securitySuggestions && securitySuggestions.length > 0) && <div className='p-2'>
                        <h5 style={{color: "var(--text)"}} className="fw-bold">SECURITIES</h5>
                        <div>
                            {securitySuggestions.map((suggestionObject, index) => {
                                const suggestionTypeString = getSuggestionTypeString(suggestionObject);
                                
                                return (
                                    <div 
                                        key={index} 
                                        data-suggestion-type={'security'} 
                                        data-suggestion-value={suggestionObject.symbol} 
                                        className="row suggestion-item p-1 d-flex align-items-center"
                                        style={{
                                            backgroundColor: "var(--theme)",
                                            color: "var(--text)"
                                        }}
                                        >
                                        <div className="col-3">
                                            <span className="fw-bold text-nowrap">{suggestionObject.symbol}</span>
                                        </div>
                                        <div className="col-6">
                                            <span className='text-primary text-nowrap'>{suggestionObject.name}</span>
                                        </div>
                                        <div className="col-3 text-end">
                                            <span>{suggestionTypeString=='EQUITY'? `${suggestionTypeString}: ${suggestionObject.exchangeShortName}`: suggestionTypeString}</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div> }

                    {(functionSuggestions && functionSuggestions.length > 0) && <div className='p-2'>
                        <h5 style={{color: "var(--text)"}} className="fw-bold">SECURITY-RELATED FUNCTIONS</h5>
                        <div>
                            {functionSuggestions.map((suggestionObject, index) => {
                                const suggestionTypeString = getSuggestionTypeString(suggestionObject);
                                
                                return (
                                    <div 
                                        key={index} 
                                        data-suggestion-type={'function'} 
                                        data-suggestion-value={`${securitySuggestions[0].symbol} ${suggestionObject.code}`}
                                        className="row suggestion-item p-1 d-flex align-items-center"
                                        style={{color: "var(--text)", backgroundColor: "var(--theme)"}}
                                        >
                                        <div className="col-3">
                                            <span className="fw-bold text-nowrap">{securitySuggestions[0].symbol} {suggestionObject.code}</span>
                                        </div>
                                        <div className="col-6">
                                            <span className='text-primary text-nowrap'>{suggestionObject.featureName}: {securitySuggestions[0].name}</span>
                                        </div>
                                        <div className="col-3 text-end">
                                            <span>{suggestionTypeString}</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>}

                    {(nonSecurityFunctionSuggestions && nonSecurityFunctionSuggestions.length > 0) && <div className='p-2'>
                        <h5 style={{color: "var(--text)"}}  className="fw-bold">NON-SECURITY RELATED FUNCTIONS</h5>
                        <div>
                            {nonSecurityFunctionSuggestions.map((suggestionObject, index) => {
                                const suggestionTypeString = getSuggestionTypeString(suggestionObject);
                                
                                return (
                                    <div 
                                        key={index} 
                                        data-suggestion-type={'function'} 
                                        data-suggestion-value={`${suggestionObject.code}`}
                                        className="row suggestion-item p-1 d-flex align-items-center"
                                        style={{color: "var(--text)", backgroundColor: "var(--theme)"}}
                                        >
                                            
                                        <div className="col-3">
                                            <span className="fw-bold text-nowrap">{suggestionObject.code}</span>
                                        </div>
                                        <div className="col-6">
                                            <span className='text-primary text-nowrap'>{suggestionObject.featureName}</span>
                                        </div>
                                        <div className="col-3 text-end">
                                            <span>{suggestionTypeString}</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>}
                
                </>
            ): (
                <div style={{color: "var(--negative-c)"}}>No Valid Symbol!</div>
            )
            }
            
        </div>
    );
}

export default SuggestionMenu;

import React from "react";

const IconToggleButton = ({label, dropDownMenu, showToggle=false}) => {
    return (
        <div className="btn-group">
            <button className={`btn rounded-0 ${showToggle? 'dropdown-toggle': ''}`} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                {label}
            </button>
            <div className="dropdown" data-bs-theme="dark">
                {dropDownMenu}
            </div>
        </div>
        
    )
}

export default IconToggleButton
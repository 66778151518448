let currentLayout = [];

export function setGlobalLayout(newLayout) {
  console.log("Setting global layout to: ", newLayout)
  currentLayout = newLayout;
}

export function getGlobalLayout() {
  
  return currentLayout;
}

/// Old Data Structure - Before Implementing Multi-Layout Tab System
// export function updateLayoutItem(windowID, updates) {
//   const index = currentLayout.findIndex(item => item.windowID === windowID);
//   if (index !== -1) {
//     currentLayout[index] = { ...currentLayout[index], ...updates };
//   }
//   else {
//     console.warn(`Window ID: <${windowID}> Not Found - No Updates to Layout Made`)
//   }
// }

// New Data Structure
export function updateLayoutItem(windowID, updates) {
  const targetTabIndex = currentLayout.findIndex(tab => 
    tab.layout.some(window => window.windowID === windowID)
  );
  if (targetTabIndex === -1) {
    console.warn(`Window ID: <${windowID}> Not Found Due to Parent Tab not being Found - No Updates to Layout Made`);
    return;
  }
  const targetWindowIndex = currentLayout[targetTabIndex].layout.findIndex(window=>
    window.windowID === windowID
  )
  if (targetWindowIndex === -1) {
    console.warn(`Window ID: <${windowID}> Not Found - No Updates to Layout Made`)
  }

  const newWindowLayout = { ...currentLayout[targetTabIndex].layout[targetWindowIndex], ...updates };
  currentLayout[targetTabIndex].layout[targetWindowIndex] = newWindowLayout
  // // console.log("Before: ",currentLayout) // // currentLayout[targetTabIndex].layout[targetWindowIndex] = { ...currentLayout[targetTabIndex].layout, ...updates };
  console.log("Saving Global Layout (layoutManager.js): ",currentLayout[0].layout)
 
}
import React from "react";

const NewsRow = ({ news }) => {
    return (
      <div className="row my-3">
        <div className="col-md-2">
          <img src={news.image} alt={news.title} className="img-fluid" />
        </div>
        <div className="col-md-10">
          <h5>{news.title}</h5>
          <p>
            <a href={news.url} style={{color: "var(--primary)"}}  target="_blank" rel="noopener noreferrer">{news.url}</a>
          </p>
          <small>Published on: {news.publishedDate}</small>
        </div>
      </div>
    );
  };

export default NewsRow
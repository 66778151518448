import React from "react";
import Button2 from "./Button2";

const AddButton = ({children, onClick})=>{
    return (
        <Button2 onClick={onClick}>
            {children}
        </Button2>
    )
}

export default AddButton
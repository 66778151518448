import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import LoadingSpinner from "../../misc/LoadingSpinner";
import FinancialStatementRow from "./FinancialStatementRow";
import WindowPieces from "../../ui/small/WindowPieces";
import AutosuggestSecurities from "../../autosuggest/searchbars/AutosuggestSecurities_v3";
import WindowError from "../../misc/WindowError";
import dashboardLayout from "../../../functions/memoryManagement/dashboardLayout";
import { getGlobalLayout, updateLayoutItem } from "../../../functions/memoryManagement/layoutManager";
import constants from "../../../constants";
import EmptyRow from "./EmptyRow";
import ComboChart from "../../charting/chartjs/ComboChart";
import ZenithTable1 from "../../ui/small/tables/ZenithTable1";
import LoadButton from "../../ui/small/buttons/LoadButton";
import Button2 from "../../ui/small/buttons/Button2";

const FinancialStatements = ({ symbol, windowID, newWindow }) => {
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 1. DEFAULT CONSTANTS
    const DEFAULT_PARAMS = {
        'symbol': symbol,
        'periods': 3,
        'periodType': 'annual',
        'divider': 'millions'
    };
    const ENDPOINT = '/financial-statement/';
    const tabs = [
        { label: "Income Statement", targetID: "incomeStatement" },
        { label: "Balance Sheet", targetID: "balanceSheet" },
        { label: "Cash Flow Statement", targetID: "cashflowStatement" },
        { label: "Ratio Sheet", targetID: "ratioSheet" },
    ];
    const DEFAULT_TAB='incomeStatement'
    

    const globalLayout = getGlobalLayout();
    const layout = globalLayout.find(tab =>
        tab.layout.find(window => window.windowID === windowID)
    )
    const window = layout?.layout.find(window=>window.windowID === windowID)
    const windowParameters = window?.windowParameters
    const paramsToUse = windowParameters || DEFAULT_PARAMS;

  
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 2. STATES - Most Windows will have a params, loading state. Ones with tabs will have an activeTabID state

    const [params, setParams] = useState(paramsToUse);
    const [loading, setLoading] = useState(true); // New loading state
    const [error, setError] = useState(null); // Error state
    const [activeTabID, setActiveTabID] = useState(DEFAULT_TAB)
    const [data, setData] = useState({});
    const [initialRender, setInitialRender] = useState(true);
    
    const [financialsPlottingData, setFinancialsPlottingData] = useState({})
    const [showFinPlot, setShowFinPlot] = useState(false)
    const [dateArray, setDateArray] = useState([])
    const [rowsPlotted, setRowsPlotted] = useState([
        'Revenue',
        'Net Income',
        'Total Assets',
        'Total Liabilities',
        'Free Cash Flow'
    ])

    const autoSuggestRef = useRef(null);

    

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 3. 

    const updateFinancialPlottingData = (allStatementData) => {
        setFinancialsPlottingData(prevFinPlotData=>{
            const fpData = rowsPlotted.reduce((acc, row) => {
                const rowName = row.rowName;
                const statementID = row.statementID;
                const targetStatement = Object.values(allStatementData).find(statement => statement.statementID === statementID)?.statementData;
                if (targetStatement) {
                    acc[rowName] = targetStatement[rowName];
                }
            
                return acc; 
            }, {}); 
            return fpData
    
        })
    }



    const requestData = () => {
        const symbolValue = autoSuggestRef.current ? autoSuggestRef.current.value : params.symbol;
    
        // Update params using the previous state value
        setParams((prevParams) => {
            const newParams = {
                ...prevParams,
                ['symbol']: symbolValue
            };
            
            // Update Database
            
            // Update Database only after the initial render
            ///////////////////////////////////////////////////////////////////////////////// FIX THIS ISSUE
            updateLayoutItem(windowID, {
                symbol: symbolValue ? symbolValue : "N/A",
                windowParameters: newParams,
            });
            const layout = getGlobalLayout();
            dashboardLayout.saveLayout(layout);

            ///////////////////////////////////////////////////////////////////////////////// FIX THIS ISSUE
            
    
            // Call the API with the updated params
            setLoading(true); // Start loading
            setError(null); 
            axios.post(constants.BACKEND_URL+ENDPOINT, newParams) // Use newParams here
                .then(response => {
                    const parsedResponse = JSON.parse(response.data.data);
                    
                    setData(parsedResponse)
                    setDateArray(parsedResponse.incomeStatement.statementData.Date.dataContent)
                    updateFinancialPlottingData(parsedResponse)

                    // until we update the plot on new data, these will reset to default
                    // setFinancialsPlottingData({})
                    // setShowFinPlot(false)
                    // setRowsPlotted([])
                    
                })
                .catch(error => {
                    console.error('Error during POST request:', error);
                    setError("Failed to load financial statements.");
                })
                .finally(() => {
                    setLoading(false); // Stop loading
                });
    
            return newParams; // Return the new params for state update
        });
        

        if (initialRender) setInitialRender(false);
    };

    useEffect(() => {
        requestData();  // Initial request
    }, []);


    const updateParams = (e) => {
        const { id, value } = e.target;
    
        setParams((prevParams) => ({
            ...prevParams,
            [id]: value
        }));
    }

    const handleTabClick = (targetID) => {
        setActiveTabID(targetID); 
        
    };

    const handleThClick = (statementID, arrayName, array, dataType) => {
        if (dataType == 'no-plot') {
            return 
        }

        setRowsPlotted((prevRowsPlotted)=>{
            const newRowsPlotted = prevRowsPlotted.map((row)=>row.rowName).includes(arrayName)
            ? prevRowsPlotted.filter(item => item.rowName !== arrayName) 
            : [...prevRowsPlotted, {
                rowName: arrayName,
                statementID: statementID
            }]

            setFinancialsPlottingData(prevFinPlotData=>{
                const fpData = newRowsPlotted.reduce((acc, row) => {
                    const rowName = row.rowName;
                    const statementID = row.statementID;
                    const targetStatement = Object.values(data).find(statement => statement.statementID === statementID)?.statementData;
                    if (targetStatement) {
                        acc[rowName] = targetStatement[rowName];
                    }
                
                    return acc; 
                }, {}); 
                console.log(fpData)
                return fpData
        
            })
            console.log(newRowsPlotted)
            console.log(newRowsPlotted.length)
            console.log(newRowsPlotted.length === 0)
            if (newRowsPlotted.length === 0) {
                setShowFinPlot(false);
                return []
            } else {
                setShowFinPlot(true);
            }

            return newRowsPlotted
        })
        
    }
    
    

    

    const closeFinPlotChart = () => {
        setFinancialsPlottingData({})
        setRowsPlotted([]);
        setShowFinPlot(false)
    }
    
    const handleChartDownload = (chartID) => {
        // https://dev.to/noemelo/how-to-save-chart-as-image-chart-js-2l0i
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 4. 
    

    return (
        <>  
            <WindowPieces.OptionsContainer securitySearch={true}>
                        <AutosuggestSecurities
                            id={'symbol'}
                            ref={autoSuggestRef}
                            defaultValue={params.symbol}
                            handleSubmitCustom={requestData}
                        />
                        <WindowPieces.ConfigContainer>
                            <WindowPieces.SquareInput 
                                id={'periods'}
                                type={'number'}
                                placeholder={'Periods'}
                                value={params.periods}
                                onChange={updateParams}
                            />
                            <WindowPieces.SquareSelect
                                id={'periodType'}
                                innerHTMLList={['Annual', 'Quarter']} 
                                valueList={['annual', 'quarter']}
                                value={params.periodType}
                                onChange={updateParams}

                            />
                            <WindowPieces.SquareSelect
                                id={'divider'}
                                innerHTMLList={['Billions', 'Millions', 'Thousands']} 
                                valueList={['billions', 'millions', 'thousands']}
                                value={params.divider}
                                onChange={updateParams}

                            />
                            <LoadButton onClick={requestData}>LOAD</LoadButton>
                        </WindowPieces.ConfigContainer>
                    
                        
            </WindowPieces.OptionsContainer>
            
            
            
            {loading ? ( // Conditional rendering for loading spinner
                <LoadingSpinner />
            ) : error ? (
                <WindowError message={error}/>
            ) : (
                <>

                    <div className="overflow-y-auto">
                        {showFinPlot && (
                            <>  
                                <div className="m-1">
                                    <Button2 fullWidth={true} onClick={()=>closeFinPlotChart()}>Close Figure</Button2>
                                </div>
                                <div style={{borderColor: "var(--grid)"}}  className="border m-1">
                                    <ComboChart windowID={windowID} data={financialsPlottingData} labels={dateArray} ticker={params.symbol}/>
                                </div>
                                
                            </>
                        )}
                        <WindowPieces.TabContainer tabs={tabs} activeTabID={activeTabID} onTabClick={handleTabClick} />
                        <WindowPieces.TabContent activeTabID={activeTabID} useKey={true} paddingBottom={"30px"}>
                            {Object.entries(data).map(([key, financialStatementData]) => {
                                const statementData = financialStatementData.statementData
                                const statementID = financialStatementData.statementID
                                
                                const allRows = Object.entries(statementData).map(([dict_key, value], index)=>{
                                    const dataType = value.dataType
                                    const dataContent = value.dataContent
                            
                                    return <FinancialStatementRow onThClick={handleThClick} key={index} rowname={dict_key} statementID={statementID} arrayType={dataType} array={dataContent} isActive={rowsPlotted.map(row=>row.rowName).includes(dict_key)} activeRowIndex={rowsPlotted.map(row=>row.rowName).indexOf(dict_key)} />
                                })
                            
                                const headerRow = allRows.slice(0,2)
                                const bodyRows = allRows.slice(2); 
                                return (
                                    <div key={key} id={statementID}>
                                        <ZenithTable1.Table 
                                        tablehead={headerRow}
                                        tablebody={
                                            <>
                                                {bodyRows}
                                            </>
                                        }
                                        /> 
                                    </div>
                                                                
                                )
                            })}
                        </WindowPieces.TabContent>
                    </div>
                </>
                

                    
            )}
        </>
    );
};

export default FinancialStatements;
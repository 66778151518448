import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ajaxRequestFuncs from '../functions/requestFuncs/ajaxRequestFuncs'
import { showAlert } from '../functions/showAlert';
import constants from '../constants';

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    // Send a POST request to the logout endpoint using axios
    axios.post(constants.BACKEND_URL+'/logout/', {}, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': ajaxRequestFuncs.getCookie('csrftoken')
      },
      withCredentials: true
    })
    .then((response) => {
      if (response.status === 200) {
        navigate('/terminal');
        window.location.reload()
        showAlert('success', "Successfully Logged Out!")
      } else {
        console.error('Failed to log out');
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }, [navigate]);

  return <div>Logging you out...</div>;
}

export default Logout;

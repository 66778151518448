import handleExcelDownload from './requestFuncs/handling/handleExcelDownload';

export default function initEventListeners() {
    document.addEventListener('keydown', e => {
        if (e.code === 'Slash') { // 'Slash' corresponds to the '/' key
            e.preventDefault();
            document.getElementById('command-line-main').focus();
        }
    });
    

    document.addEventListener('click', e => {
        if (e.target.id === "export-to-excel") {
            handleExcelDownload(e)
        }
    });
}


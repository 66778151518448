import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import constants from '../../../constants';
import axios from 'axios';
import CustomModal1 from '../../modals/CustomModal1';
import SettingLineWrapper from '../../ui/small/wrappers/settings/SettingLineWrapper';
import SettingsSection from '../../ui/small/wrappers/settings/SettingsSection';
import ColorPicker from '../../ui/small/ColorPicker';
import CheckBox from '../../ui/small/CheckBox';

function logWithTime(message) {
    const currentTime = new Date().toLocaleString();
    console.log(`[${currentTime}] ${message}`);
}

const LineChart = ({ lineData, symbol, params, windowContainer, contentContainerRef }) => {
    const chartContainerRef = useRef();
    const chartRef = useRef(null); // Ref for chart instance
    const seriesRef = useRef(null); // Ref for chart instance
    const websocket = useRef(null);
    const pollingInterval = useRef(null);
    let resizeAnimationFrame;

    const [candlestickSeries, setCandlestickSeries] = useState(lineData);
    const [latestCandlestickData, setLatestCandlestickData] = useState(null);
    const [currentOHLC, setCurrentOHLC] = useState({
        time: lineData[lineData.length-1].time,
        value: lineData[lineData.length-1].value
    })

    const [tooltipValues, setTooltipValues] = useState({time: null, value: null})
   

    const initializeChart = () => {
        const chart = createChart(chartContainerRef.current, {
            rightPriceScale: {
                scaleMargins: {
                    top: 0.4, // leave some space for the legend
                    bottom: 0.15,
                },
            },
            width: chartContainerRef.current.clientWidth,
            height: chartContainerRef.current.clientHeight,
            layout: {
                background: { color: '#000000' },
                textColor: '#DDD',
                fontFamily: 'Lucida Console',
            },
            grid: {
                vertLines: { visible: false },
                horzLines: { visible: false },
            },
        });

        const series = chart.addLineSeries({
            color: '#2962FF',
            lineWidth: 2,
            // disabling built-in price lines
            lastValueVisible: false,
            priceLineVisible: false,
        });

        seriesRef.current = series
        chartRef.current = chart; // Store chart in the ref

        const getLastBar = series => {
            console.log(series.dataByIndex())
            const lastIndex = series.dataByIndex(Number.MAX_SAFE_INTEGER, -1);
            return series.dataByIndex(lastIndex);
        };
        const formatPrice = price => (Math.round(price * 100) / 100).toFixed(2);
        const setTooltip = (time, value) => {
            setTooltipValues({
                time: time,
                value: formatPrice(value)
            })
            console.log(tooltipValues)
        };

        const updateLegend = param => {
            const validCrosshairPoint = !(
                param === undefined || param.time === undefined || param.point.x < 0 || param.point.y < 0
            );
            console.log(validCrosshairPoint)
            const bar = validCrosshairPoint ? param.seriesData.get(series) : currentOHLC;
            // time is in the same format that you supplied to the setData method,
            // which in this case is YYYY-MM-DD
            console.log(bar)
            const time = bar.time;
            const value = bar.value !== undefined ? bar.value : bar.value;
            console.log("Value", value)
            setTooltip(time, value);
        };
        

        chart.subscribeCrosshairMove(updateLegend);

        // updateLegend(undefined);

        return series;
    };

    const handleResize = () => {
        if (!chartRef.current) return;

        const resizeObserver = new ResizeObserver(entries => {
            if (entries.length === 0 || entries[0].target !== contentContainerRef.current) return;
            const { width, height } = entries[0].contentRect;

            if (height > 0 && width > 0) { // Only resize if dimensions are valid
                if (resizeAnimationFrame) cancelAnimationFrame(resizeAnimationFrame);
                resizeAnimationFrame = requestAnimationFrame(() => {
                    chartRef.current.resize(width, height - 5);
                });
            }
        });

        resizeObserver.observe(contentContainerRef.current);

        return () => {
            resizeObserver.disconnect();
            if (resizeAnimationFrame) cancelAnimationFrame(resizeAnimationFrame);
        };
    };

    const connectWebSocket = () => {
        websocket.current = new WebSocket('wss://websockets.financialmodelingprep.com');
        
        const loginMessage = {
            'event': 'login',
            'data': {
                'apiKey': constants.FMP_APIKEY
            }
        };

        websocket.current.onopen = () => {
            websocket.current.send(JSON.stringify(loginMessage));
        };

        websocket.current.onmessage = (message) => {
            const messageData = JSON.parse(message.data);
            const event = messageData.event;

            if (event === 'login' && messageData.status === 200) {
                const subscribeMessage = {
                    'event': 'subscribe', 
                    'data': { 'ticker': symbol }
                };
                websocket.current.send(JSON.stringify(subscribeMessage));
            } else if ('s' in messageData) {
                if (pollingInterval.current) {
                    clearInterval(pollingInterval.current);
                    // console.log("Cleared Polling Interval!")
                }
                handleNewQuote(messageData);
            } else if (messageData.event === 'heartbeat') {
                setupPolling();
            }
        };

        websocket.current.onerror = (error) => {
            console.error("WebSocket error:", error);
        };

        websocket.current.onclose = () => {
            console.log("WebSocket connection closed");
        };
    };

    const setupPolling = () => {
        if (!pollingInterval.current) {
            pollingInterval.current = setInterval(() => {
                axios.post('/watchlist-update/', { 'symbol': symbol })
                    .then(response => {
                        // Handle the response if needed
                    })
                    .catch(error => {
                        console.error('Error during POST request:', error);
                    });
            }, 60000);
            logWithTime("Created Polling Interval");
        }
    };

    const handleNewQuote = (quote, interval = 'daily') => {
        const timestamp = Math.floor(quote.t / 1000000000);  // Assuming the timestamp is in seconds
        const price = quote.lp;

        const timeString = new Date(Date.now()).toLocaleTimeString(undefined, {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false // Set to true if you want 12-hour format with AM/PM
          });

        // console.log(timeString, price)

    
        // Calculate the interval start time based on the chosen time frame
        let intervalStart;
        if (interval === 'minute') {
            intervalStart = Math.floor(timestamp / 60) * 60;
        } else if (interval === 'hourly') {
            intervalStart = Math.floor(timestamp / 3600) * 3600;
        } else if (interval === '4hours') {
            intervalStart = Math.floor(timestamp / (4 * 3600)) * (4 * 3600);
        } else if (interval === 'daily') {
            const date = new Date(timestamp * 1000);
            intervalStart = new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime() / 1000;
        } else {
            console.error("Unsupported interval type");
            return;
        }

        setCurrentOHLC((prevOHLC) => {
            console.log("prevOHLC: ", prevOHLC)
            // Check if we're starting a new interval
            if (!prevOHLC || prevOHLC.time < intervalStart) {
                console.log("New Bar!")
                // New interval: push the old OHLC data and start a new bar
                if (prevOHLC) {
                    setLatestCandlestickData(prevOHLC);  // Push the old data to the chart as a new candlestick
                }
                
                // Start a new OHLC bar for the new interval
                return {
                    time: intervalStart,
                    value: price,
                };
            } else {
                // Use the latest open, high, low from lineData if available
                const mostRecentHistoricalOHLC = lineData[lineData.length - 1];
     
                const updatedOHLC = {
                    ...prevOHLC,
                    value: price,
                };
                console.log("Updated OHLC: ", updatedOHLC)
                
                // Continuously push the updated OHLC to the chart for live updates
                setLatestCandlestickData(updatedOHLC);
                console.log(updatedOHLC)
                return updatedOHLC;
            }
        });
        
    };
    
    useEffect(() => {
        const series = initializeChart();

        series.setData(candlestickSeries);
        chartRef.current.timeScale().fitContent();
        
        connectWebSocket();
        
        const cleanupResize = handleResize();
        return () => {
            cleanupResize();
            chartRef.current.remove();
            if (pollingInterval.current) clearInterval(pollingInterval.current);
            if (websocket.current) websocket.current.close();
        };
    }, []);

    useEffect(() => {
        if (latestCandlestickData && chartRef.current) {
            seriesRef.current.update({
                time: latestCandlestickData.time,
                value: latestCandlestickData.value,
            });
        }
    }, [latestCandlestickData]);

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
    
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    };
    
    return (
        <div className='position-relative h-100 w-100' ref={chartContainerRef}>
            <div className='legend'
                style={{
                    position: 'absolute',
                    left: '6px',
                    top: '8px',
                    zIndex: '3',
                    fontSize: '14px',
                    lineHeight: "18px",
                    fontWeight: '300'
                }}
            >
                <div style={{fontSize: '16px'}}>{symbol.toUpperCase()}</div>
                {/* <div>{formatTimestamp(tooltipValues.time)}</div> */}
                <div>
                    <span className='text-primary'>C:<strong style={{color: "var(--text)"}}>{tooltipValues.value} </strong></span>
                   
                </div>
            </div>
        </div>
    )
};

export default LineChart;
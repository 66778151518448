import React, { useState } from "react";
import CustomModal2 from "../CustomModal2";
import features from "../../../catalogs/features";

const SecurityFunctionModal = ({functionMenuSymbol, show, setShow}) => {

    return (
        <CustomModal2 
            show={show}
            title={`${functionMenuSymbol.symbol} - ${functionMenuSymbol.name}`}
            body={
                <>
                <div style={{width: '800px'}}>
                    <div className="text-primary fst-italic">Select a Security Function...</div>
                    <div className="mt-3 container-fluid p-0" style={{minWidth: '500px'}}>
                        {features.map((feature, index)=>{
                            if (feature.securityFunction) {
                                return (
                                    <div className="row p-1 hover-item" onClick={()=>{feature.handler(functionMenuSymbol.symbol) ; setShow(false)}}>
                                        <div className="pe-0" style={{flex: "0 0 30px"}}>{`${index})`}</div>
                                        <div className="col-1 text-primary fw-bold">{feature.code} </div>
                                        <div className="col-3">{feature.featureName}</div>
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>
                </>
            }
            onClose={()=>setShow(false)}
        />
    )
}

export default SecurityFunctionModal
import React from "react";
import ReactDOM from "react-dom";
import prepareWindow from "../../windowCreating/prepareWindow";
import Screener from "../../../components/FeatureComponents/15_Screener/Screener";

async function handleScreener(symbol, isInitialDisplay=true, existingWindowID="") {
    const WINDOW_TYPE_NAME = 'window-screener';
    const TITLE_PREFIX = 'Advanced Equity Screener ';

    const window = await prepareWindow(symbol, WINDOW_TYPE_NAME, TITLE_PREFIX, isInitialDisplay, existingWindowID)
    const destinationElement = window.querySelector('.window-content')

    ReactDOM.render(<Screener />, destinationElement)
}


export default  handleScreener 
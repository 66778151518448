import React from "react";
import CustomModal2 from "../CustomModal2";


const AccountModal = ({show, setShow}) => {
  
    return (
        <CustomModal2 
            show={show}
            title={"Account"}
            body={
                <div>
                    <div>Account Item 1...</div>
                    <div>Account Item 2...</div>
                    <div>Account Item 3...</div>
                    <div>Account Item 4...</div>
                </div>
            }
            onClose={()=>setShow(false)}
        />
    )
}

export default AccountModal
import React from 'react';
import ReactDOM from 'react-dom';
import AlertDropdown from '../components/misc/AlertDropdown';

// Function to render the alert dynamically
export const showAlert = (type, message) => {
    const alertContainer = document.createElement('div');
    document.body.appendChild(alertContainer);

    const removeAlert = () => {
        ReactDOM.unmountComponentAtNode(alertContainer);
        document.body.removeChild(alertContainer);
    };

    ReactDOM.render(
        <AlertDropdown type={type} message={message} setShowAlert={removeAlert} />,
        alertContainer
    );
};
import axios from 'axios';

// Function to get the CSRF token from cookies
function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

// Function to make a POST request and update the destination element with the response
function axiosRequestToContainer(sendData, url, destinationElement) {
    const csrftoken = getCookie('csrftoken');

    axios.post(url, sendData, {
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,  // Include CSRF token in the header
        },
        withCredentials: true,  // Ensure that the cookie is sent with the request
    })
    .then(response => {
        destinationElement.innerHTML = response.data.data
    })
    .catch(error => {
        console.error("An error occurred:", error); // Log any errors
        destinationElement.innerHTML = `<div class="bg-light text-danger">Error: ${error.message}</div>`;
    });
}

// Function to make a POST request and return the response through a callback
function axiosRequestAndReturn(sendData, url, callback) {
    const csrftoken = getCookie('csrftoken');

    axios.post(url, sendData, {
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,  // Include CSRF token in the header
        },
        withCredentials: true,  // Ensure that the cookie is sent with the request
    })
    .then(response => {
        callback(null, response.data); // Call the callback with the response data
    })
    .catch(error => {
        console.error("An error occurred:", error); // Log any errors
        callback(error); // Call the callback with the error
    });
}









export default { getCookie, axiosRequestToContainer, axiosRequestAndReturn };

import prepareWindow from "../../windowCreating/prepareWindow";
import React from "react";
import ReactDOM from "react-dom";
import FinancialStatements from "../../../components/FeatureComponents/1_FinancialStatements/1_FinancialStatements";

async function handleFinancialStatements(symbol, isInitialDisplay=true, existingWindowID="") {
    const WINDOW_TYPE_NAME = 'window-financial-statements';
    const TITLE_PREFIX = 'Financials ';

    const window = await prepareWindow(symbol, WINDOW_TYPE_NAME, TITLE_PREFIX, isInitialDisplay, existingWindowID)
    const destinationElement = window.querySelector('.window-content')

    ReactDOM.render(<FinancialStatements symbol={symbol} windowID={window.id} newWindow={isInitialDisplay}/>, destinationElement)
}

export default  handleFinancialStatements 
import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import LoadingSpinner from "../../misc/LoadingSpinner";
import WindowPieces from "../../ui/small/WindowPieces";
import WindowError from "../../misc/WindowError";
import AutosuggestSecurities from "../../autosuggest/searchbars/AutosuggestSecurities_v3";
import dashboardLayout from "../../../functions/memoryManagement/dashboardLayout";
import { getGlobalLayout, updateLayoutItem } from "../../../functions/memoryManagement/layoutManager";
import LoadButton from "../../ui/small/buttons/LoadButton";
import EarningsChart from "../../charting/chartjs/EarningsChart";
import constants from "../../../constants";
import ZenithTable1 from "../../ui/small/tables/ZenithTable1";
import FormattingComponents from "../../formatting/FormattingComponents";

const EPS = ({ symbol, windowID, newWindow }) => {
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 1. DEFAULT CONSTANTS
    const DEFAULT_PARAMS = {
        'symbol': symbol,
        'periods': 10,
    };
    const ENDPOINT = '/eps/';

    const globalLayout = getGlobalLayout();
    const layout = globalLayout.find(tab =>
        tab.layout.find(window => window.windowID === windowID)
    )
    const window = layout?.layout.find(window=>window.windowID === windowID)
    const windowParameters = window?.windowParameters
    const paramsToUse = windowParameters || DEFAULT_PARAMS;

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 2. STATES - Most Windows will have a params, loading state. Ones with tabs will have an activeTabID state

    const [params, setParams] = useState(paramsToUse);
    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(null); 
    const [data, setData] = useState({});
    const [initialRender, setInitialRender] = useState(true);

    const autoSuggestRef = useRef(null);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 3. 

    const requestData = () => {
        const symbolValue = autoSuggestRef.current ? autoSuggestRef.current.value : params.symbol;
    
        // Update params using the previous state value
        setParams((prevParams) => {
            const newParams = {
                ...prevParams,
                ['symbol']: symbolValue
            };
            
            // Update Database
            updateLayoutItem(windowID, {
                symbol: symbolValue ? symbolValue : "N/A",
                windowParameters: newParams,
            });
            const layout = getGlobalLayout();
            dashboardLayout.saveLayout(layout);
    
            // Call the API with the updated params
            setLoading(true); // Start loading
            setError(null); 
            axios.post(constants.BACKEND_URL+ENDPOINT, newParams) // Use newParams here
                .then(response => {
                    const parsedResponse = JSON.parse(response.data.data);
                    
                    setData([...parsedResponse].reverse());
                    console.log(parsedResponse)
                })
                .catch(error => {
                    console.error('Error during POST request:', error);
                    // setError("Failed to load financial statements.");
                })
                .finally(() => {
                    setLoading(false); // Stop loading
                });
    
            return newParams; 
        });

        if (initialRender) setInitialRender(false);
    };

    useEffect(() => {
        requestData();  // Initial request
    }, []);


    const updateParams = (e) => {
        const { id, value } = e.target;
    
        setParams((prevParams) => ({
            ...prevParams,
            [id]: value
        }));
    }


    
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 4. 
    

    return (
        <>
            {loading ? ( // Conditional rendering for loading spinner
                <LoadingSpinner />
            ) : error ? (
                <WindowError message={error} />
            ) : (
                <>
                    <WindowPieces.OptionsContainer securitySearch={true}>
                        <AutosuggestSecurities
                            id={'symbol'}
                            ref={autoSuggestRef}
                            defaultValue={params.symbol}
                            handleSubmitCustom={requestData}
                        />
                        <WindowPieces.ConfigContainer>
                            <WindowPieces.SquareInput 
                                id={'periods'}
                                type={'number'}
                                placeholder={'# Periods'}
                                value={params.periods}
                                onChange={updateParams}
                            />
                            <LoadButton onClick={requestData}>LOAD</LoadButton>
                        </WindowPieces.ConfigContainer>
                 
                     
                    </WindowPieces.OptionsContainer>

                    <WindowPieces.Content>
                        <EarningsChart windowID={windowID} earningsData={data} />
                        <ZenithTable1.Table 
                            tablehead={
                                <tr>
                                    <th>Date Ending</th>
                                    <th>EPS Actual</th>
                                    <th>EPS Estimate</th>
                                    <th>Suprise</th>
                                    <th>Suprise (%)</th>
                                </tr>
                            }
                            tablebody={
                                <>
                                    {data.map((row, index)=>{
                                    const fiscalDateEnding = row.fiscalDateEnding
                                    const eps = row.eps
                                    const epsEstimate = row.epsEstimated
                                    const supriseAbsolute = eps - epsEstimate
                                    const supriseRel = (!eps && !epsEstimate)? 'N/A':  (eps/epsEstimate) - 1
                                    const color = eps == epsEstimate? '': (eps > epsEstimate? "var(--positive)": "var(--negative)")

                                    return (
                                        <tr>
                                            <td>{fiscalDateEnding}</td>
                                            <td>{eps}</td>
                                            <td>{epsEstimate}</td>
                                            <td style={{color: color}}><FormattingComponents.NumberRounded numDecimals={2} value={supriseAbsolute}/></td>
                                            <td style={{color: color}}><FormattingComponents.Percentage numDecimals={2} value={supriseRel} /></td>
                                        </tr>
                                    )
                                })}
                                </>
                            }
                        
                        />
                    </WindowPieces.Content>

                 

                    
                </>
            )}
        </>
    );
};

export default EPS;

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { showAlert } from '../functions/showAlert';

function Success() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/terminal');
    showAlert('success', 'Payment Successful. Thank You!')

  }, [navigate]);

  return <div>Payment Successful! Thank you...</div>;
}

export default Success;

import React from "react";
import ReactDOM from "react-dom";
import MarketScanner from "../../../components/FeatureComponents/16_MarketScanner/MarketScanner";
import prepareWindow from "../../windowCreating/prepareWindow";

async function handleMarketScanner(symbol, isInitialDisplay=true, existingWindowID="") {
    const WINDOW_TYPE_NAME = 'window-market-scanner';
    const TITLE_PREFIX = 'Market Scanner ';

    const window = await prepareWindow(symbol, WINDOW_TYPE_NAME, TITLE_PREFIX, isInitialDisplay, existingWindowID)
    const destinationElement = window.querySelector('.window-content')


    const DEFAULT_SELECTED_MARKET_SCANNER = ["^SPX", "^NDX", "^VIX"]

    // const watchlist = await loadWatchlist()
    // console.log(watchlist)
    // let watchlistObject
    // if (!watchlist || Object.keys(watchlist).length === 0) {
    //     watchlistObject=DEFAULT_WATCH_LIST
    // } else {
    //     watchlistObject=watchlist
    // }

    ReactDOM.render(<MarketScanner initialSymbolsList={DEFAULT_SELECTED_MARKET_SCANNER} />, destinationElement)
}

export default handleMarketScanner 

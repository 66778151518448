
import ReactDOM from "react-dom";
import React from 'react';
import GroupAnalysisByMetric from "../../../components/FeatureComponents/7_GroupAnalysisByMetric/7_GroupAnalysisByMetric";
import prepareWindow from "../../windowCreating/prepareWindow";

async function handleGroupMetric(symbol, isInitialDisplay=true, existingWindowID="") {
    const WINDOW_TYPE_NAME = 'window-group-metric';
    const TITLE_PREFIX = 'Group Analysis By Metric ';

    const window = await prepareWindow(symbol, WINDOW_TYPE_NAME, TITLE_PREFIX, isInitialDisplay, existingWindowID)
    const destinationElement = window.querySelector('.window-content')

    ReactDOM.render(<GroupAnalysisByMetric symbol={symbol}/>, destinationElement)
}

export default handleGroupMetric 
import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import constants from '../constants';

// not sensitive
const stripePromise = loadStripe('pk_test_51QPYYrIwFuKasYMC6P3y220W1McATBljLgTKlYcHTsFMQutNC1AqAFt3jVr6ovqjwODeRoTinR2TBzJOdx7g7ki400Dw0ONyFu'); // Add your publishable key here

const Checkout = () => {
    const [email, setEmail] = useState('');

    const handleCheckout = async () => {
        const stripe = await stripePromise;

        try {
            // Create a Checkout Session on the server using axios
            const response = await axios.post(constants.BACKEND_URL+'/create-checkout-session/', {
                email: email,
            });

            const { sessionId } = response.data;

            // Redirect to Stripe Checkout
            const { error } = await stripe.redirectToCheckout({ sessionId });
            if (error) console.error('Stripe Checkout Error:', error);
        } catch (err) {
            console.error('API Request Error:', err);
        }
    };

    return (
        <div>
            <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <button onClick={handleCheckout}>Subscribe</button>
        </div>
    );
};

export default Checkout;


import React, { useState } from "react";
import FormattingComponents from "../../formatting/FormattingComponents";
import EmptyRow from "./EmptyRow";
import constants from "../../../constants";
import { Sparklines, SparklinesLine } from 'react-sparklines';
const FinancialStatementRow = ({ onThClick, rowname, statementID, arrayType, array, isActive, activeRowIndex }) => {
    const [showChartIcon, setShowChartIcon] = useState(false)
    
    // Can Receive 6 different arrayTypes
    // 1. calculation1
    // 2. calculation2
    // 3. calculation3
    // 4. calculation4
    // 5. percent
    // 6. ratio
    // 7. nonnumber
    // 8. heading
    // 9. number (which is everything else)
    const INDENT_SIZE = 20
    const SPARK_LINES = false

    const cellStylingMap = {
        'percent': {fontStyle: 'italic'},
    }

    const thStylingMap = {
        'calculation': {textIndent: `${INDENT_SIZE*1}px`, fontWeight: 'bold'},
        'calculation2': {textIndent: `${INDENT_SIZE*2}px`, fontWeight: 'bold'},
        'calculation3': {textIndent: `${INDENT_SIZE*3}px`, fontWeight: 'bold'},
        'percent': {fontStyle: 'italic'},
        'heading': {color: 'var(--primary)'}
    }

    const plotTypeMap = {
        'percent': 'percent',
        'ratio': 'number',
        'number': 'number',
        'calculation': 'number',
        'calculation2': 'number',
        'calculation3': 'number',
    }

    // 1. Getting Mapped Style & data type for plotting
    const mappedCellStyle = cellStylingMap[arrayType]? cellStylingMap[arrayType]: {}
    const mappedThStyle = thStylingMap[arrayType]? thStylingMap[arrayType]: {}
    const mappedPlotType = plotTypeMap[arrayType]? plotTypeMap[arrayType]: 'no-plot'
    
    
    // 2. Creating Sparklines
    let cells = []
    const firstVal = array[0]
    const lastVal = array[array.length-1]
    const sparkLineColor = lastVal > firstVal? "var(--positive)": (lastVal < firstVal? "var(--negative)": 'white')
    const sparkLine = (
        <td style={{width: '100px', height: "10px"}}>
            <Sparklines style={{minWidth: '75px', height: '12px', padding: '0px'}} data={array} >
                <SparklinesLine color={sparkLineColor   } />
            </Sparklines>
        </td>
    )
    if (SPARK_LINES) {
        cells.push(sparkLine)
    }
    cells=cells.concat(array.map((cell, index)=>{
        let formattedCell;
        if (arrayType == 'percent') {
            formattedCell=<FormattingComponents.Percentage numDecimals={2} value={cell}/>
        }
        else if (arrayType == 'nonnumber') {
            formattedCell=cell
        }
        else {
            formattedCell=<FormattingComponents.AccountingFormat numDecimals={2} value={cell}/>
        }
        
        return (
            <td style={{borderColor: "var(--grid)", ...mappedCellStyle}}  className="text-nowrap p-0 border-start" key={index}>
                {formattedCell}
            </td>
        )
    }))

    return (
        <tr onClick={()=>onThClick(statementID, rowname, array, mappedPlotType)}  data-series-type={mappedPlotType} onMouseOver={()=>setShowChartIcon(true)} onMouseOut={()=>setShowChartIcon(false)}>
            <th style={{borderColor: "var(--grid)", mappedThStyle}}  className={`position-relative text-nowrap p-0 border-end`}>
                {isActive && (
                    <div style={{position: 'absolute', top: 0, height: '100%', width: '5px', backgroundColor: constants.COLORS[activeRowIndex % constants.COLORS.length]}}></div>
                )}
                <div className="ms-3">{rowname}</div>
                {showChartIcon && (
              
                    <svg  style={{position: 'absolute', top: 0, right: '5px', height: '100%', width: '20px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bar-chart-fill" viewBox="0 0 16 16">
                        <path d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1z"/>
                    </svg>
                  
                )}
            </th>
            {cells}
        </tr>
    ) 
}

export default FinancialStatementRow
import React from 'react';

const SuggestionListForSecurities = ({ securitySuggestions }) => {
    function getSuggestionTypeString(suggestionObject) {
        let suggestionTypeString = 'EQUITY';

        if (suggestionObject.suggestionType === 'security') {
            if (suggestionObject.exchangeShortName === 'ECONOMY') {
                suggestionTypeString = 'ECONOMICS'; 
            } else if (suggestionObject.exchangeShortName === 'CRYPTO') {
                suggestionTypeString = 'CRYPTO';
            } else if (suggestionObject.exchangeShortName === 'FOREX') {
                suggestionTypeString = 'FOREX';
            }
        } else if (suggestionObject.suggestionType === 'command') {
            suggestionTypeString = 'FUNCTION'; // function is more clear than command
        }

        return suggestionTypeString;
    }

    return (
        <div 
            className='ms-1 border'
            style={{
                position: 'absolute',
                top: '100%',
                left: '0',
                overflowY: 'auto',
                overflowX: 'hidden',
                maxWidth: '100%',
                width: '700px',
                zIndex: 1000,
                textAlign: 'left',
                backgroundColor: "var(--black)",
                borderColor: "var(--grid)"
            }} >
                <div>
                    {
                        securitySuggestions.length > 0 ? (
                            securitySuggestions.map((suggestionObject, index) => {
                                const suggestionTypeString = getSuggestionTypeString(suggestionObject);
                                
                                return (
                                    <div 
                                        key={index} 
                                        data-suggestion-type={suggestionObject.suggestionType} 
                                        data-suggestion-value={suggestionObject.symbol} 
                                        className="row suggestion-item p-1 d-flex align-items-center"
                                        style={{
                                            backgroundColor: "var(--theme)",
                                            color: "var(--text)"
                                        }}
                                        >
                                        <div className="col-3">
                                            <span className="fw-bold text-nowrap">{suggestionObject.symbol}</span>
                                        </div>
                                        <div className="col-6">
                                            <span className='text-primary text-nowrap'>{suggestionObject.name}</span>
                                        </div>
                                        <div className="col-3 text-end">
                                            <span>{suggestionTypeString}: {suggestionObject.exchangeShortName}</span>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div style={{backgroundColor: "var(--theme)", color: "var(--negative-c)"}}>No Valid Symbol!</div>
                        )
                    }
                    
            </div> 
        </div>
    );
}

export default SuggestionListForSecurities;



import React from "react";
import ReactDOM from "react-dom";
import AnalystPriceTargets from "../../../components/FeatureComponents/2_AnalystPriceTargets/2_AnalystPriceTargets";
import prepareWindow from "../../windowCreating/prepareWindow";

async function handleAnalystPriceTargets(symbol, isInitialDisplay=true, existingWindowID="") {
    const WINDOW_TYPE_NAME = 'window-analyst-price-targets';
    const TITLE_PREFIX = 'Analyst Price Targets ';

    const window = await prepareWindow(symbol, WINDOW_TYPE_NAME, TITLE_PREFIX, isInitialDisplay, existingWindowID)
    const destinationElement = window.querySelector('.window-content')

    ReactDOM.render(<AnalystPriceTargets symbol={symbol} windowID={window.id} newWindow={isInitialDisplay}/>, destinationElement)
}


export default handleAnalystPriceTargets;

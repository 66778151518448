import React, { useState } from "react";
import CustomModal2 from "../CustomModal2";
import SitePieces from "../../ui/small/SitePieces";
import Button2 from "../../ui/small/buttons/Button2";

const RenameTabModal = ({ show, setShow, onRenameTab }) => {
  const [inputVal, setInputVal] = useState("");

  // Handle input change
  const handleInputChange = (e) => {
    setInputVal(e.target.value);
  };

  return (
    <CustomModal2
      show={show}
      title={"Rename Tab"}
      body={
        <div style={{width: '400px'}}>
          <label htmlFor="rename-tab">New Name</label>
          <SitePieces.ZenithInput
            id={'rename-tab'}
            value={inputVal}  
            onChange={handleInputChange}  
          />
          <div className="mt-3">
            <Button2 fullWidth={true} onClick={()=>{
                onRenameTab(inputVal);
                setShow(false)
            }}>Confirm</Button2>
          </div>
        </div>
      }
      onClose={() => setShow(false)}
    />
  );
};

export default RenameTabModal;

import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import LoadingSpinner from "../../misc/LoadingSpinner";
import WindowPieces from "../../ui/small/WindowPieces";
import WindowError from "../../misc/WindowError";
import AutosuggestSecurities from "../../autosuggest/searchbars/AutosuggestSecurities_v3";
import dashboardLayout from "../../../functions/memoryManagement/dashboardLayout";
import { getGlobalLayout, updateLayoutItem } from "../../../functions/memoryManagement/layoutManager";
import LoadButton from "../../ui/small/buttons/LoadButton";
import RegressionPlot from "../../charting/chartjs/RegressionPlot";
import constants from "../../../constants";
import ZenithTable1 from "../../ui/small/tables/ZenithTable1";

const Beta = ({ symbol, windowID, newWindow }) => {
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 1. DEFAULT CONSTANTS
    const DEFAULT_PARAMS = {
        'symbol': symbol,
        'years': 5,
        'frequency': 'monthly',
        'benchmark': '^SPX'
    };
    const ENDPOINT = '/beta/';

    const globalLayout = getGlobalLayout();
    const layout = globalLayout.find(tab =>
        tab.layout.find(window => window.windowID === windowID)
    )
    const window = layout?.layout.find(window=>window.windowID === windowID)
    const windowParameters = window?.windowParameters
    const paramsToUse = windowParameters || DEFAULT_PARAMS;

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 2. STATES - Most Windows will have a params, loading state. Ones with tabs will have an activeTabID state

    const [params, setParams] = useState(paramsToUse);
    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(null); 
    const [data, setData] = useState({});
    const [initialRender, setInitialRender] = useState(true);

    const autoSuggestRef = useRef(null);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 3. 

    const requestData = () => {
        const symbolValue = autoSuggestRef.current ? autoSuggestRef.current.value : params.symbol;
    
        // Update params using the previous state value
        setParams((prevParams) => {
            const newParams = {
                ...prevParams,
                ['symbol']: symbolValue
            };
            
            // Update Database
            updateLayoutItem(windowID, {
                symbol: symbolValue ? symbolValue : "N/A",
                windowParameters: newParams,
            });
            const layout = getGlobalLayout();
            dashboardLayout.saveLayout(layout);
    
            // Call the API with the updated params
            setLoading(true); // Start loading
            setError(null); 
            axios.post(constants.BACKEND_URL+ENDPOINT, newParams) // Use newParams here
                .then(response => {
                    const parsedResponse = JSON.parse(response.data.data);
                    
                    setData(parsedResponse);
                    console.log(parsedResponse)
                })
                .catch(error => {
                    console.error('Error during POST request:', error);
                    // setError("Failed to load financial statements.");
                })
                .finally(() => {
                    setLoading(false); // Stop loading
                });
    
            return newParams; // Return the new params for state update
        });

        if (initialRender) setInitialRender(false);
    };

    useEffect(() => {
        requestData();  // Initial request
    }, []);


    const updateParams = (e) => {
        const { id, value } = e.target;
    
        // Update the params by setting the new value for the key that matches the input's id
        setParams((prevParams) => ({
            ...prevParams,
            [id]: value
        }));
    }


    
    ///////////////////////////////////////////////////////////////////////////////////////////////////////// 4. 
    

    return (
        <>
            {loading ? ( // Conditional rendering for loading spinner
                <LoadingSpinner />
            ) : error ? (
                <WindowError message={error} />
            ) : (
                <>
                    <WindowPieces.OptionsContainer securitySearch={true}>
                        <AutosuggestSecurities
                            id={'symbol'}
                            ref={autoSuggestRef}
                            defaultValue={params.symbol}
                            handleSubmitCustom={requestData}
                        />
                        <WindowPieces.ConfigContainer>
                            <WindowPieces.SquareSelect
                                id={'benchmark'}
                                innerHTMLList={['S&P500 Index (SPX)', 'Nasdaq 100 (NDX)', 'Dow Jones Industrial Average (DJI)', 'Volatility Index (VIX)']} 
                                valueList={['^SPX', '^NDX', '^DJI', '^VIX']}
                                value={params.benchmark}
                                onChange={updateParams}

                            />
                            <WindowPieces.SquareSelect
                                id={'years'}
                                innerHTMLList={['1 Year', '3 Year', '5 Year']} 
                                valueList={[1, 2, 5]}
                                value={params.years}
                                onChange={updateParams}

                            />
                            <WindowPieces.SquareSelect
                                id={'frequency'}
                                innerHTMLList={['Daily', 'Weekly', 'Monthly']} 
                                valueList={['daily', 'weekly', 'monthly']}
                                value={params.frequency}
                                onChange={updateParams}

                            />
                            <LoadButton onClick={requestData}>LOAD</LoadButton>

                        </WindowPieces.ConfigContainer>
                    </WindowPieces.OptionsContainer>

                    <WindowPieces.Content>
                        <RegressionPlot windowID={windowID} scatter_data={data.scatter} regressionline_data={data.regression}/>
                        <ZenithTable1.Table
                            tablebody={
                                <>
                                    <tr>
                                        <th>Beta</th>
                                        <td>{data.slope}</td>
                                    </tr>
                                    <tr>
                                        <th>Alpha</th>
                                        <td>{data.intercept}</td>
                                    </tr>
                                    <tr>
                                        <th>R-Squared</th>
                                        <td>{data.r_value}</td>
                                    </tr>
                                    <tr>
                                        <th>P-Value</th>
                                        <td>{data.p_value}</td>
                                    </tr>
                                    <tr>
                                        <th>Model</th>
                                        <td>Y = {data.slope}X + {data.intercept}</td>
                                    </tr>
                                    <tr>
                                        <th>Number of Observations</th>
                                        <td>{data.numObservations}</td>
                                    </tr>
                                </>
                            }
                        
                        />
                    </WindowPieces.Content>

                 

                    
                </>
            )}
        </>
    );
};

export default Beta;

import React, { useEffect } from "react";

const Home = ({}) => {
    
    console.log("Serving!!!!")
    return (
        // <div className="bg-danger">Hey</div>
        <iframe
            src="/landing-page/home.html"
            style={{
                width: "100%",
                height: "100vh",
                border: "none",
            }}
            title="Landing Page"
        />
    );
}


// const Home = ({}) => {
//     return (
//         <>
//             <main className="page-wrapper">
//                 <header class="navbar navbar-expand-lg fixed-top bg-black">
//                     <div class="container">
//                         <a class="navbar-brand pe-sm-3 text-light" href="index.html">
//                             {/* <span class="text-primary flex-shrink-0 me-2">
//                                 <svg width="35" height="32" viewBox="0 0 36 33" xmlns="http://www.w3.org/2000/svg">
//                                     <path fill="currentColor" d="M35.6,29c-1.1,3.4-5.4,4.4-7.9,1.9c-2.3-2.2-6.1-3.7-9.4-3.7c-3.1,0-7.5,1.8-10,4.1c-2.2,2-5.8,1.5-7.3-1.1c-1-1.8-1.2-4.1,0-6.2l0.6-1.1l0,0c0.6-0.7,4.4-5.2,12.5-5.7c0.5,1.8,2,3.1,3.9,3.1c2.2,0,4.1-1.9,4.1-4.2s-1.8-4.2-4.1-4.2c-2,0-3.6,1.4-4,3.3H7.7c-0.8,0-1.3-0.9-0.9-1.6l5.6-9.8c2.5-4.5,8.8-4.5,11.3,0L35.1,24C36,25.7,36.1,27.5,35.6,29z"></path>
//                                 </svg>
//                             </span> */}
//                             FIGR  
//                         </a>

//                         <div class="form-check form-switch mode-switch order-lg-2 me-3 me-lg-4 ms-auto" data-bs-toggle="mode">
//                             <input class="form-check-input" type="checkbox" id="theme-mode"/>
//                             <label class="form-check-label" for="theme-mode">
//                             <i class="ai-sun fs-lg"></i>
//                             </label>
//                             <label class="form-check-label" for="theme-mode">
//                             <i class="ai-moon fs-lg"></i>
//                             </label>
//                         </div>

//                         <a class="btn btn-primary btn-sm fs-sm order-lg-3 d-none d-sm-inline-flex" href="https://themes.getbootstrap.com/product/around-multipurpose-template-ui-kit/" target="_blank" rel="noopener">
//                             <i class="ai-cart fs-xl me-2 ms-n1"></i>
//                             Buy now
//                         </a>

//                         <button class="navbar-toggler ms-sm-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-label="Toggle navigation">
//                             <span class="navbar-toggler-icon"></span>
//                         </button>Z

//                         <nav class="collapse navbar-collapse" id="navbarNav">
//                             <ul class="navbar-nav navbar-nav-scroll me-auto">
//                                 <li class="nav-item dropdown">
//                                     <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">Pages</a>
//                                     <ul class="dropdown-menu bg-black">
//                                     <li class="dropdown">
//                                         <a class="dropdown-item dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">Portfolio</a>
//                                         <ul class="dropdown-menu bg-black">
//                                         <li><a class="dropdown-item" href="portfolio-list-v1.html">List View v.1</a></li>
//                                         <li><a class="dropdown-item" href="portfolio-list-v2.html">List View v.2</a></li>
//                                         <li><a class="dropdown-item" href="portfolio-grid-v1.html">Grid View v.1</a></li>
//                                         <li><a class="dropdown-item" href="portfolio-grid-v2.html">Grid View v.2</a></li>
//                                         <li><a class="dropdown-item" href="portfolio-slider.html">Slider View</a></li>
//                                         <li><a class="dropdown-item" href="portfolio-single-v1.html">Single Project v.1</a></li>
//                                         <li><a class="dropdown-item" href="portfolio-single-v2.html">Single Project v.2</a></li>
//                                         </ul>
//                                     </li>
//                                     <li class="dropdown">
//                                         <a class="dropdown-item dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">Shop</a>
//                                         <ul class="dropdown-menu bg-black">
//                                         <li><a class="dropdown-item" href="shop-catalog.html">Catalog (Listing)</a></li>
//                                         <li><a class="dropdown-item" href="shop-single.html">Product Page</a></li>
//                                         <li><a class="dropdown-item" href="shop-checkout.html">Checkout</a></li>
//                                         </ul>
//                                     </li>
//                                     <li class="dropdown">
//                                         <a class="dropdown-item dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">Blog</a>
//                                         <ul class="dropdown-menu bg-black">
//                                         <li><a class="dropdown-item" href="blog-grid-sidebar.html">Grid View with Sidebar</a></li>
//                                         <li><a class="dropdown-item" href="blog-grid.html">Grid View no Sidebar</a></li>
//                                         <li><a class="dropdown-item" href="blog-list-sidebar.html">List View with Sidebar</a></li>
//                                         <li><a class="dropdown-item" href="blog-list.html">List View no Sidebar</a></li>
//                                         <li><a class="dropdown-item" href="blog-single-v1.html">Single post v.1</a></li>
//                                         <li><a class="dropdown-item" href="blog-single-v2.html">Single post v.2</a></li>
//                                         <li><a class="dropdown-item" href="blog-single-v3.html">Single post v.3</a></li>
//                                         </ul>
//                                     </li>
//                                     <li class="dropdown">
//                                         <a class="dropdown-item dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">About</a>
//                                         <ul class="dropdown-menu bg-black">
//                                         <li><a class="dropdown-item" href="about-agency.html">About - Agency</a></li>
//                                         <li><a class="dropdown-item" href="about-product.html">About - Product</a></li>
//                                         </ul>
//                                     </li>
//                                     <li class="dropdown">
//                                         <a class="dropdown-item dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">Services</a>
//                                         <ul class="dropdown-menu bg-black">
//                                         <li><a class="dropdown-item" href="services-v1.html">Services v.1</a></li>
//                                         <li><a class="dropdown-item" href="services-v2.html">Services v.2</a></li>
//                                         <li><a class="dropdown-item" href="services-v3.html">Services v.3</a></li>
//                                         </ul>
//                                     </li>
//                                     <li><a class="dropdown-item" href="pricing.html">Pricing</a></li>
//                                     <li class="dropdown">
//                                         <a class="dropdown-item dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">Contacts</a>
//                                         <ul class="dropdown-menu bg-black">
//                                         <li><a class="dropdown-item" href="contacts-v1.html">Contacts v.1</a></li>
//                                         <li><a class="dropdown-item" href="contacts-v2.html">Contacts v.2</a></li>
//                                         <li><a class="dropdown-item" href="contacts-v3.html">Contacts v.3</a></li>
//                                         </ul>
//                                     </li>
//                                     <li class="dropdown">
//                                         <a class="dropdown-item dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">Specialty Pages</a>
//                                         <ul class="dropdown-menu bg-black">
//                                         <li><a class="dropdown-item" href="coming-soon-v1.html">Coming Soon v.1</a></li>
//                                         <li><a class="dropdown-item" href="coming-soon-v2.html">Coming Soon v.2</a></li>
//                                         <li><a class="dropdown-item" href="404-v1.html">404 Error v.1</a></li>
//                                         <li><a class="dropdown-item" href="404-v2.html">404 Error v.2</a></li>
//                                         <li><a class="dropdown-item" href="404-v3.html">404 Error v.3</a></li>
//                                         </ul>
//                                     </li>
//                                     </ul>
//                                 </li>
//                                 <li class="nav-item dropdown">
//                                     <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">Account</a>
//                                     <ul class="dropdown-menu bg-black">
//                                     <li class="dropdown">
//                                         <a class="dropdown-item dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">Auth pages</a>
//                                         <ul class="dropdown-menu bg-black">
//                                         <li><a class="dropdown-item" href="account-signin.html">Sign In</a></li>
//                                         <li><a class="dropdown-item" href="account-signup.html">Sign Up</a></li>
//                                         <li><a class="dropdown-item" href="account-signinup.html">Sign In / Up</a></li>
//                                         <li><a class="dropdown-item" href="account-password-recovery.html">Password Recovery</a></li>
//                                         </ul>
//                                     </li>
//                                     <li><a class="dropdown-item" href="account-overview.html">Overview</a></li>
//                                     <li><a class="dropdown-item" href="account-settings.html">Settings</a></li>
//                                     <li><a class="dropdown-item" href="account-billing.html">Billing</a></li>
//                                     <li><a class="dropdown-item" href="account-orders.html">Orders</a></li>
//                                     <li><a class="dropdown-item" href="account-earnings.html">Earnings</a></li>
//                                     <li><a class="dropdown-item" href="account-chat.html">Chat (Messages)</a></li>
//                                     <li><a class="dropdown-item" href="account-favorites.html">Favorites (Wishlist)</a></li>
//                                     </ul>
//                                 </li>
//                                 <li class="nav-item">
//                                     <a class="nav-link" href="components/typography.html">UI Kit</a>
//                                 </li>
//                                 <li class="nav-item">
//                                     <a class="nav-link" href="docs/getting-started.html">Docs</a>
//                                 </li>
//                             </ul>
//                             <div class="d-sm-none p-3 mt-n3">
//                             <a class="btn btn-primary w-100 mb-1" href="https://themes.getbootstrap.com/product/around-multipurpose-template-ui-kit/" target="_blank" rel="noopener">
//                                 <i class="ai-cart fs-xl me-2 ms-n1"></i>
//                                 Buy now
//                             </a>
//                             </div>
//                         </nav>
//                     </div>
//                 </header>
//                 <section class="position-relative pt-5">
//                     <div class="position-absolute top-0 start-0 w-100" style={{height: '80%'}} >
//                         <div class="bg-primary position-absolute top-0 start-0 w-100 h-100"></div>
//                         <div class="position-absolute start-0 bottom-0 w-100 overflow-hidden mb-n1" style={{paddingBottom: '6.2%'}}>
//                             <svg class="position-absolute start-0 bottom-0 w-100 h-100" viewBox="0 0 3000 185.4" xmlns="http://www.w3.org/2000/svg">
//                             <path fill="currentColor" d="M3000,0v185.4H0V0c496.4,115.6,996.4,173.4,1500,173.4S2503.6,115.6,3000,0z"></path>
//                             </svg>
//                         </div>
//                         </div>
//                             <svg class="position-absolute end-0 z-2 mt-4 mt-sm-5" width="836" height="755" fill="none" stroke="white" stroke-miterlimit="10" xmlns="http://www.w3.org/2000/svg">
//                             <path d="M21.253 754.495c-18.309-66.796-31.642-139.581-6.067-203.929 33.531-84.307 126.54-131.543 216.622-142.348 90.082-10.831 180.803 7.052 271.284 13.945s187.376.931 261.969-50.776C868.476 299.694 902.433 159.981 995.762 75.54 1073.58 5.151 1191.04-18.081 1289.8 17.446M89.774 754.494c-17.324-63.177-29.938-132.049-5.748-192.911 31.722-79.756 119.701-124.438 204.913-134.657 85.239-10.246 171.036 6.68 256.621 13.2s177.263.878 247.812-48.035c97.852-67.835 129.947-199.99 218.248-279.853 73.61-66.584 184.74-88.565 278.15-54.954M158.305 754.496c-16.313-59.585-28.235-124.492-5.402-181.894 29.912-75.206 112.862-117.333 193.231-126.966 80.368-9.661 161.269 6.307 241.983 12.427 80.715 6.148 167.151.825 233.655-45.293 92.264-63.949 122.522-188.574 205.768-263.886 69.4-62.805 174.17-83.509 262.26-51.814M226.834 754.495c-15.328-55.965-26.532-116.96-5.083-170.876 28.103-70.655 106.023-110.228 181.521-119.276 75.499-9.074 151.503 5.908 227.321 11.683s157.011.772 219.497-42.553c86.675-60.09 115.097-177.157 193.31-247.891 65.2-59 163.64-78.453 246.37-48.674M295.359 754.495c-14.344-52.372-24.802-109.429-4.763-159.859 26.266-66.104 99.183-103.122 169.812-111.584 70.628-8.489 141.736 5.535 212.657 10.937s146.899.719 205.366-39.811c81.087-56.205 107.699-165.741 180.859-231.925 60.99-55.193 153.1-73.396 230.48-45.533" stroke-opacity=".12"></path>
//                             </svg>
//                             <div class="container position-relative z-5 pt-5 mt-4 mt-lg-5" data-bs-theme="dark">
//                             <div class="row justify-content-center text-center pt-md-2 pt-lg-0 pt-xl-2 pb-5 mb-md-2">
//                                 <div class="col-lg-10 col-xl-9 col-xxl-8"><span class="badge bg-light bg-opacity-10 fs-sm text-white px-3 mb-4">Take your data to the next level</span>
//                                 <h1 class="display-3 mb-sm-4">One dashboard to manage all your businesses</h1>
//                                 <p class="text-body pb-3 mb-3 mb-lg-4">Using basic data skills you can improve your business stuff with Around</p>
//                                 <a class="btn btn-lg btn-warning" href="#">Start free 14-day trial</a>
//                             </div>
//                         </div>
//                         <div class="bg-black rounded-4 p-2 mx-auto" style={{maxWidth: '1160px' }}>
//                             Hey
//                         </div>
//                     </div>
//                 </section>

//             </main>
//         </>
//     )
// }

export default Home


import { addGridItem } from "../gridLayoutManager";

export default function createWindow(windowTypeName) {
  return new Promise((resolve, reject) => {
      const uniqueWindowID = `window-${Date.now()}`;

      const items = {
          windowID: uniqueWindowID,
          windowType: windowTypeName,  
      };

      addGridItem(items)
        .then((newWindowElement) => {
            if (newWindowElement) {
                resolve(newWindowElement);
                
            } else {
                reject("Failed to create new window element");
            }
        })
        .catch((error) => {
            reject(error);
        });
  });
}


import React from 'react';
import ReactDOM from 'react-dom';
import prepareWindow from "../../windowCreating/prepareWindow";
import CompanyNews from "../../../components/FeatureComponents/4_CompanyNews/4_CompanyNews";

async function handleCompanyNews(symbol, isInitialDisplay=true, existingWindowID="") {
  const WINDOW_TYPE_NAME = 'window-company-news';
  const TITLE_PREFIX = 'Company News ';

  const window = await prepareWindow(symbol, WINDOW_TYPE_NAME, TITLE_PREFIX, isInitialDisplay, existingWindowID)
  const destinationElement = window.querySelector('.window-content')

  ReactDOM.render(<CompanyNews symbol={symbol} windowID={window.id} newWindow={isInitialDisplay}/>, destinationElement)
}

export default  handleCompanyNews 
import React from "react";
import UserPlanBadge from "./UserPlanBadge";
import constants from "../../constants";
import handleCheckout from "../../functions/handleCheckout";
import getRootColorString from "../../functions/helpers/getRootColorString";

const UserSummary = ({userObject}) => {
    const primColor = getRootColorString("--primary-c")

    return (
        <div>
            <div className="d-flex justify-content-between">
                <h5 className="me-3">{userObject.username}</h5>
                <UserPlanBadge groupName={userObject.group}/>
            </div>
            <p className="m-0">{userObject.email}</p>
            {userObject.group === 'level1_user' && (
                <button 
                className="btn border-none py-1 px-2 mt-2 w-100" 
                style={{ borderRadius: '2px', background: `${primColor}50`, color: primColor}}
                onClick={()=>handleCheckout(userObject.email)}
                >
                    Upgrade to Premium!
                </button>
            )}
        </div>
    )
}

export default UserSummary
// GridLayoutManager.js
let zenithGridLayout = null;

export const setGridLayoutRef = (ref) => {
  zenithGridLayout = ref;
};

export const addGridItem = (windowLayoutProps) => {
  if (zenithGridLayout) {
    return zenithGridLayout.addItem(windowLayoutProps)
      .then(newWindow => {
        return newWindow;
      })
      .catch(error => {
        console.error(error);
      });
  } else {
    console.warn("GridLayout ref is not set");
  }
};

import React, { useState, useMemo } from 'react';
import constants from '../../../constants';
import RemoveButton from '../../ui/small/buttons/RemoveButton';
import ZenithTable1 from '../../ui/small/tables/ZenithTable1';

const GroupAnalysisTimeTable = ({ data, onSymbolRemove, onSymbolAdd, setShowAddSymbolModal }) => {
    const [hoveredSymbol, setHoveredSymbol] = useState(null);

    const companyNames = Object.keys(data);
    const numberOfColumns = data[companyNames[0]]?.length || 0;

    // Helper functions for calculating Mean, Median, Max, and Min
    const calculateMean = (arr) => arr.reduce((acc, val) => acc + val, 0) / arr.length;

    const calculateMedian = (arr) => {
        const sorted = [...arr].sort((a, b) => a - b);
        const mid = Math.floor(sorted.length / 2);
        return sorted.length % 2 !== 0 ? sorted[mid] : (sorted[mid - 1] + sorted[mid]) / 2;
    };

    const calculateMax = (arr) => Math.max(...arr);
    const calculateMin = (arr) => Math.min(...arr);

    // Transpose data to get columns
    const columns = useMemo(() => (
        Array.from({ length: numberOfColumns }, (_, colIndex) =>
            companyNames.map((company) => data[company][colIndex])
        )
    ), [data, numberOfColumns, companyNames]);

    // Calculate statistics for each column
    const means = columns.map(calculateMean);
    const medians = columns.map(calculateMedian);
    const maxValues = columns.map(calculateMax);
    const minValues = columns.map(calculateMin);

    return (
        <ZenithTable1.Table
            tablehead={
                <tr>
                    <th>Company</th>
                    {Array.from({ length: numberOfColumns }, (_, i) => (
                        <th key={i}>Year {i + 1}</th>
                    ))}
                </tr>
            }
            tablebody={
                <>
                    {companyNames.map((company) => (
                        <tr key={company}>
                            <td
                                onMouseEnter={() => setHoveredSymbol(company)}
                                onMouseLeave={() => setHoveredSymbol(null)}
                                style={{ position: 'relative' }}
                            >
                                {company}
                                {hoveredSymbol === company && (
                                    <RemoveButton onClick={() => onSymbolRemove(company)}/>
                                )}
                            </td>
                            {data[company].map((value, i) => (
                                <td key={i}>{value.toLocaleString()}</td>
                            ))}
                        </tr>
                    ))}
                    <tr>
                        <td><button onClick={setShowAddSymbolModal}>Add Symbol +</button></td>
                        <td colSpan={numberOfColumns + 1}>&nbsp;</td>
                    </tr>
                    <tr>
                        <td><strong>Mean</strong></td>
                        {means.map((mean, i) => (
                            <td key={i}>{mean.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                        ))}
                    </tr>
                    <tr>
                        <td><strong>Median</strong></td>
                        {medians.map((median, i) => (
                            <td key={i}>{median.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                        ))}
                    </tr>
                    <tr>
                        <td><strong>Max</strong></td>
                        {maxValues.map((max, i) => (
                            <td key={i}>{max.toLocaleString()}</td>
                        ))}
                    </tr>
                    <tr>
                        <td><strong>Min</strong></td>
                        {minValues.map((min, i) => (
                            <td key={i}>{min.toLocaleString()}</td>
                        ))}
                    </tr>
                </>
            }
        />
    );
};

export default GroupAnalysisTimeTable;

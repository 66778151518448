import React from "react";

const ColorPicker = ({setColor, value}) => {
    return (
        <input
            className="border"
            name="color-input"
            type="color"
            value={value}
            onChange={(e) => {
                setColor(e.target.value); // Pass the selected color
            }}
            style={{
                borderColor: "var(--grid)",
                border: 'none',
                outline: 'none',
                height: "20px",
                width: "20px",
                padding: "0px",
                background: "none",
                cursor: "pointer",
                appearance: "none", // Remove default styles
                WebkitAppearance: "none", // Remove WebKit-specific styles
            }}
        />
    )
}

export default ColorPicker
import React from "react";
import ReactDOM from "react-dom";
import prepareWindow from "../../windowCreating/prepareWindow";
import TearSheet from "../../../components/FeatureComponents/13_TearSheet/13_TearSheet";

async function handleTearSheet(symbol, isInitialDisplay=true, existingWindowID="") {
    const WINDOW_TYPE_NAME = 'window-tear-sheet';
    const TITLE_PREFIX = 'Tear Sheet ';

    const window = await prepareWindow(symbol, WINDOW_TYPE_NAME, TITLE_PREFIX, isInitialDisplay, existingWindowID)
    const destinationElement = window.querySelector('.window-content')

    ReactDOM.render(<TearSheet symbol={symbol} windowID={window.id} newWindow={isInitialDisplay}/>, destinationElement)
}

export default  handleTearSheet ;

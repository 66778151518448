export default function debounce(func, delay) {
    let timeoutId;

    return function(...args) {
        // Clear the previous timeout
        if (timeoutId) clearTimeout(timeoutId);

        // Set a new timeout to call the function after the delay
        timeoutId = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
}
import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import constants from '../../../constants';
import axios from 'axios';
import CustomModal1 from '../../modals/CustomModal1';
import ColorPicker from '../../ui/small/ColorPicker';
import SettingsSection from '../../ui/small/wrappers/settings/SettingsSection';
import SettingLineWrapper from '../../ui/small/wrappers/settings/SettingLineWrapper';
import CheckBox from '../../ui/small/CheckBox';
import SettingsConfigButton from '../../ui/small/buttons/SettingsConfigButton';

function logWithTime(message) {
    const currentTime = new Date().toLocaleString();
    console.log(`[${currentTime}] ${message}`);
}

const CandlestickChart = ({ ohlcData, symbol, params, windowContainer, contentContainerRef }) => {
    // Modal Stuff
    const [showSettings, setShowSettings] = useState(false)

    const [chartBackground, setChartBackground] = useState('#000000')
    const [textColor, setTextColor] = useState('#FFFFFF')
    const [upCandleColor, setUpCandleColor] = useState(constants.POSITIVE_COLOR)
    const [downCandleColor, setDownColor] = useState(constants.NEGATIVE_COLOR)
    const [upCandleBorder, setUpCandleBorder] = useState(constants.POSITIVE_COLOR)
    const [downCandleBorder, setDownCandleBorder] = useState(constants.NEGATIVE_COLOR)
    const [syncUpCandleBorder, setSyncUpCandleBorder] = useState(true)
    const [syncDownCandleBorder, setSyncDownCandleBorder] = useState(true)
    const [showGridLines, setShowGridLines] = useState(false)
    const [gridColor, setGridColor] = useState('white')
    const [showWatermark, setShowWatermark] = useState(true)
    const [watermarkColor, setWatermarkColor] = useState('white')
    
    const chartContainerRef = useRef();
    const chartRef = useRef(null); // Ref for chart instance
    const seriesRef = useRef(null); // Ref for chart instance
    const websocket = useRef(null);
    const pollingInterval = useRef(null);
    let resizeAnimationFrame;

    const [candlestickSeries, setCandlestickSeries] = useState(ohlcData);
    const [latestCandlestickData, setLatestCandlestickData] = useState(null);
    const [currentOHLC, setCurrentOHLC] = useState({
        time: ohlcData[ohlcData.length-1].time,
        open: ohlcData[ohlcData.length-1].open,
        high: ohlcData[ohlcData.length-1].high,
        low: ohlcData[ohlcData.length-1].low,
        close: ohlcData[ohlcData.length-1].close

    })

    const [tooltipValues, setTooltipValues] = useState({time: null, open: null, high: null, low: null, close: null})
   

    const initializeChart = () => {
        const chart = createChart(chartContainerRef.current, {
            rightPriceScale: {
                scaleMargins: {
                    top: 0.4, // leave some space for the legend
                    bottom: 0.15,
                },
            },
            width: chartContainerRef.current.clientWidth,
            height: chartContainerRef.current.clientHeight,
            layout: {
                background: { color: chartBackground },
                textColor: textColor,
                fontFamily: 'Inter',
            },
            grid: {
                vertLines: { visible: showGridLines, color: gridColor },
                horzLines: { visible: showGridLines, color: gridColor },
            },
            watermark: {
                visible: showWatermark,
                fontSize: 24,
                horzAlign: 'center',
                vertAlign: 'center',
                color: watermarkColor,
                text: symbol.toUpperCase(),
            },
        });

        const series = chart.addCandlestickSeries({
            upColor: upCandleColor,
            downColor: downCandleColor,
            borderVisible: false,
            wickUpColor: syncUpCandleBorder? upCandleColor: upCandleBorder,
            wickDownColor: syncDownCandleBorder?downCandleColor: downCandleBorder,
        });
        seriesRef.current = series
        chartRef.current = chart; // Store chart in the ref

        const getLastBar = series => {
            console.log(series.dataByIndex())
            const lastIndex = series.dataByIndex(Number.MAX_SAFE_INTEGER, -1);
            return series.dataByIndex(lastIndex);
        };
        const formatPrice = price => (Math.round(price * 100) / 100).toFixed(2);
        const setTooltip = (time, open, high, low, close) => {
            setTooltipValues({
                time: time,
                open: formatPrice(open),
                high: formatPrice(high),
                low: formatPrice(low),
                close: formatPrice(close)
            })
        };

        const updateLegend = param => {
            const validCrosshairPoint = !(
                param === undefined || param.time === undefined || param.point.x < 0 || param.point.y < 0
            );
            const bar = validCrosshairPoint ? param.seriesData.get(series) : currentOHLC;
            // time is in the same format that you supplied to the setData method,
            // which in this case is YYYY-MM-DD
            // console.log(bar)
            const time = bar.time;
            const open = bar.open !== undefined ? bar.open : bar.close;
            const high = bar.high !== undefined ? bar.high : bar.close;
            const low = bar.low !== undefined ? bar.low : bar.close;
            const close = bar.close !== undefined ? bar.close : bar.close;
            setTooltip(time, open, high, low, close);
        };
        

        chart.subscribeCrosshairMove(updateLegend);

        // updateLegend(undefined);

        return series;
    };

    const handleResize = () => {
        if (!chartRef.current) return;

        const resizeObserver = new ResizeObserver(entries => {
            if (entries.length === 0 || entries[0].target !== contentContainerRef.current) return;
            const { width, height } = entries[0].contentRect;

            if (height > 0 && width > 0) { // Only resize if dimensions are valid
                if (resizeAnimationFrame) cancelAnimationFrame(resizeAnimationFrame);
                resizeAnimationFrame = requestAnimationFrame(() => {
                    chartRef.current.resize(width, height - 5);
                });
            }
        });

        resizeObserver.observe(contentContainerRef.current);

        return () => {
            resizeObserver.disconnect();
            if (resizeAnimationFrame) cancelAnimationFrame(resizeAnimationFrame);
        };
    };

    const connectWebSocket = () => {
        websocket.current = new WebSocket('wss://websockets.financialmodelingprep.com');
        
        const loginMessage = {
            'event': 'login',
            'data': {
                'apiKey': constants.DATA_API_KEY
            }
        };

        websocket.current.onopen = () => {
            websocket.current.send(JSON.stringify(loginMessage));
        };

        websocket.current.onmessage = (message) => {
            const messageData = JSON.parse(message.data);
            const event = messageData.event;

            if (event === 'login' && messageData.status === 200) {
                const subscribeMessage = {
                    'event': 'subscribe', 
                    'data': { 'ticker': symbol }
                };
                websocket.current.send(JSON.stringify(subscribeMessage));
            } else if ('s' in messageData) {
                if (pollingInterval.current) {
                    clearInterval(pollingInterval.current);
                    // console.log("Cleared Polling Interval!")
                }
                handleNewQuote(messageData, params.interval);
            } else if (messageData.event === 'heartbeat') {
                setupPolling();
            }
        };

        websocket.current.onerror = (error) => {
            console.error("WebSocket error:", error);
        };

        websocket.current.onclose = () => {
            console.log("WebSocket connection closed");
        };
    };

    const setupPolling = () => {
        if (!pollingInterval.current) {
            pollingInterval.current = setInterval(() => {
                axios.post('/watchlist-update/', { 'symbol': symbol })
                    .then(response => {
                        // Handle the response if needed
                    })
                    .catch(error => {
                        console.error('Error during POST request:', error);
                    });
            }, 60000);
            logWithTime("Created Polling Interval");
        }
    };

    const handleNewQuote = (quote, interval = 'daily') => {
        const timestamp = Math.floor(quote.t / 1000000000);  // Assuming the timestamp is in seconds
        const price = quote.lp;

        const timeString = new Date(Date.now()).toLocaleTimeString(undefined, {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false // Set to true if you want 12-hour format with AM/PM
          });

        // console.log(timeString, price)

    
        // Calculate the interval start time based on the chosen time frame
        let intervalStart;
        if (interval === '1min') {
            intervalStart = Math.floor(timestamp / 60) * 60;
        } else if (interval === 'hourly') {
            intervalStart = Math.floor(timestamp / 3600) * 3600;
        } else if (interval === '4hours') {
            intervalStart = Math.floor(timestamp / (4 * 3600)) * (4 * 3600);
        } else if (interval === 'daily') {
            const date = new Date(timestamp * 1000);
            intervalStart = new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime() / 1000;
        } else {
            console.error("Unsupported interval type");
            return;
        }

        setCurrentOHLC((prevOHLC) => {
            // Check if we're starting a new interval
            if (!prevOHLC || prevOHLC.time < intervalStart) {
                console.log("New Bar!")
                // New interval: push the old OHLC data and start a new bar
                if (prevOHLC) {
                    setLatestCandlestickData(prevOHLC);  // Push the old data to the chart as a new candlestick
                }
                
                // Start a new OHLC bar for the new interval
                return {
                    time: intervalStart,
                    open: price,
                    high: price,
                    low: price,
                    close: price,
                };
            } else {
                // Use the latest open, high, low from ohlcData if available
                // const mostRecentHistoricalOHLC = ohlcData[ohlcData.length - 1]
                const mostRecentHistoricalOHLC = prevOHLC
                console.log("MRH: ",mostRecentHistoricalOHLC)
     
                const updatedOHLC = {
                    ...prevOHLC,
                    open: mostRecentHistoricalOHLC ? mostRecentHistoricalOHLC.open : prevOHLC.open,
                    high: Math.max(
                        mostRecentHistoricalOHLC ? mostRecentHistoricalOHLC.high : prevOHLC.high,
                        price
                    ),
                    low: Math.min(
                        mostRecentHistoricalOHLC ? mostRecentHistoricalOHLC.low : prevOHLC.low,
                        price
                    ),
                    close: price,
                };
                
                // Continuously push the updated OHLC to the chart for live updates
                setLatestCandlestickData(updatedOHLC);
                console.log(updatedOHLC)
                return updatedOHLC;
            }
        });
        
    };
    
    useEffect(() => {
        const series = initializeChart();

        series.setData(candlestickSeries);
        chartRef.current.timeScale().fitContent();
        
        connectWebSocket();
        
        const cleanupResize = handleResize();
        return () => {
            cleanupResize();
            chartRef.current.remove();
            if (pollingInterval.current) clearInterval(pollingInterval.current);
            if (websocket.current) websocket.current.close();
        };
    }, [upCandleColor, downCandleColor, showGridLines, gridColor, chartBackground, textColor, upCandleBorder, downCandleBorder, syncUpCandleBorder, syncDownCandleBorder, showWatermark, watermarkColor]);

    useEffect(() => {
        if (latestCandlestickData && chartRef.current) {
            seriesRef.current.update({
                time: latestCandlestickData.time,
                open: latestCandlestickData.open,
                high: latestCandlestickData.high,
                low: latestCandlestickData.low,
                close: latestCandlestickData.close,
            });
        }
    }, [latestCandlestickData]);

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
    
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    };
    
    return (
        <>
            <div className='h-100 w-100' ref={chartContainerRef}>
                <div className='legend'
                    style={{
                        position: 'absolute',
                        left: '6px',
                        top: '38px',
                        zIndex: '3',
                        fontSize: '14px',
                        lineHeight: "18px",
                        fontWeight: '300'
                    }}
                >
                    <div style={{fontSize: '16px', color: textColor}}>{symbol.toUpperCase()}</div>
                    {/* <div>{formatTimestamp(tooltipValues.time)}</div> */}
                    <div>
                        <span className='text-primary'>O:<strong style={{color: "var(--text)"}}>{tooltipValues.open} </strong></span>
                        <span className='text-primary'>H:<strong style={{color: "var(--text)"}}>{tooltipValues.high} </strong></span>
                        <span className='text-primary'>L:<strong style={{color: "var(--text)"}}>{tooltipValues.low} </strong></span>
                        <span className='text-primary'>C:<strong style={{color: "var(--text)"}}>{tooltipValues.close} </strong></span>
                    
                    </div>
                </div>
                {/* <SettingsConfigButton onClick={()=>setShowSettings(true)} /> */}
                <button 
                    onClick={()=>setShowSettings(true)}
                    style={{
                    position: 'absolute',
                    right: '70px',
                    top: '38px',
                    zIndex: '3',
                    fontSize: '14px',
                    lineHeight: "18px",
                    fontWeight: '300'
                }}>SETTINGS</button>
            </div>
            <CustomModal1 
                show={showSettings}
                body={
                    <>
                        <h3>Candlestick Chart Settings</h3>
                        {/* <SettingsSection sectionTitle={"Background & Foreground"}>
                            <SettingLineWrapper label={"Background"}>
                                <ColorPicker value={chartBackground} setColor={setChartBackground}/>
                            </SettingLineWrapper>
                            <SettingLineWrapper label={"Foreground"}>
                                <ColorPicker value={textColor} setColor={setTextColor}/>
                            </SettingLineWrapper>
                        </SettingsSection> */}
                        <SettingsSection sectionTitle={"Candlesticks"}>
                            <SettingLineWrapper label={"Up Candle"}>
                                <ColorPicker value={upCandleColor} setColor={setUpCandleColor}/>
                            </SettingLineWrapper>
                            <SettingLineWrapper label={"Down Candle"}>
                                <ColorPicker value={downCandleColor} setColor={setDownColor}/>
                            </SettingLineWrapper>
                            <SettingLineWrapper label={"Up Candle Border"}>
                                <ColorPicker value={upCandleBorder} setColor={setUpCandleBorder}/>
                                <div className='ms-2'>
                                    <SettingLineWrapper label={"Sync"}>
                                        <CheckBox setBool={setSyncUpCandleBorder} initialValue={syncUpCandleBorder}/>
                                    </SettingLineWrapper>
                                </div>
                            </SettingLineWrapper>
                            <SettingLineWrapper label={"Down Candle Border"}>
                                <ColorPicker value={downCandleBorder} setColor={setDownCandleBorder}/>
                                <div className='ms-2'>
                                    <SettingLineWrapper label={"Sync"}>
                                        <CheckBox setBool={setSyncDownCandleBorder} initialValue={syncDownCandleBorder}/>
                                    </SettingLineWrapper>
                                </div>
                            </SettingLineWrapper>
                        </SettingsSection>
                        <SettingsSection sectionTitle={"Grid"}>
                            <SettingLineWrapper label={"Show Grid"}>
                                <CheckBox setBool={setShowGridLines} initialValue={showGridLines}/>
                            </SettingLineWrapper>
                            <SettingLineWrapper label={"Grid Color"}>
                            <ColorPicker value={gridColor} setColor={setGridColor}/>
                            </SettingLineWrapper>
                        </SettingsSection>
                        <SettingsSection sectionTitle={"Other Configurations"}>
                            <SettingLineWrapper label={"Show Watermark"}>
                                <CheckBox setBool={setShowWatermark} initialValue={showWatermark}/>
                            </SettingLineWrapper>
                            <SettingLineWrapper label={"Watermark Color"}>
                                <ColorPicker value={watermarkColor} setColor={setWatermarkColor}/>
                            </SettingLineWrapper>
                        </SettingsSection>
                    </>
                    
                }
                onClose={()=>setShowSettings(false)}
                onConfirm={()=>setShowSettings(false)}
            
            />
        
        </>
        
    )
};

export default CandlestickChart;

import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import getRootColorString from '../../functions/helpers/getRootColorString';

const AlertDropdown = ({ type, message, setShowAlert }) => {
    const [progress, setProgress] = useState(100);
    const [show, setShow] = useState(false); // State to handle the show class

    useEffect(() => {
        const duration = 3000; // Duration in milliseconds
        const interval = 50; // Update interval in milliseconds
        const decrementAmount = (interval / duration) * 100;

        // Trigger the CSS transition by adding the show class
        const showTimeout = setTimeout(() => setShow(true), 10);

        const timer = setTimeout(() => {
            setShow(false); // Remove the show class
            setTimeout(() => setShowAlert(false), 300); // Wait for transition before unmounting
        }, duration);

        // Progress bar update interval
        const progressInterval = setInterval(() => {
            setProgress((prev) => Math.max(prev - decrementAmount, 0));
        }, interval);

        return () => {
            clearTimeout(showTimeout);
            clearTimeout(timer);
            clearInterval(progressInterval);
        };
    }, [setShowAlert]);

    let color;
    let headerText;
    if (type === 'success') {
        color = getRootColorString("--positive-c");
        headerText = 'Success';
    } else if (type === 'error') {
        color = getRootColorString("--negative-c");
        headerText = 'Error';
    } else {
        color = getRootColorString("--neutral-c");
        headerText = 'Warning';
    }

    return (
        <div
            className={`alert alert-dropdown ${show ? 'show' : ''}`}
            style={{
                backgroundColor: "var(--theme)",
                color: color,
                border: `1px solid ${color}`,
                borderRadius: '0px',
            }}
            role="alert"
        >
            <div>
                <h5 style={{ fontWeight: 'bold' }}>{headerText}</h5>
                <p>{message}</p>
            </div>
            <button
                type="button"
                className="btn-close btn-close-white"
                aria-label="Close"
                onClick={() => setShowAlert(false)} // Immediately hide the alert on click
                style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    fontSize: '1rem',
                }}
            ></button>

            <div
                style={{
                    position: 'absolute',
                    bottom: '0',
                    left: '0',
                    height: '5px',
                    width: `${progress}%`,
                    backgroundColor: color,
                    transition: 'width 0.05s linear',
                }}
            />
        </div>
    );
};

export default AlertDropdown;

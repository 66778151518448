import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import constants from '../../../constants';
import TVLWLineChartMultiSeries from '../../charting/chartjs/LineChartMultiSeries'
import WindowPieces from "../../ui/small/WindowPieces";

const marketScannerStructure={
    'World Equity Indicies': {
        'North America': ['^SPX', '^NDX', '^VIX'],
        'South America': [],
        'Europe': [],
        'Asia/Pacific': []
    },
    'US Industries & Sectors': {

    },
    'Countries': {
        
    },
    'Factor ETFs': {
        
    },
    'Commodities': {
        
    },

}




const MarketScanner = ({ initialSymbolsList = [] }) => {
    const [checkedSymbols, setCheckedSymbols] = useState(initialSymbolsList);
    const [stockData, setStockData] = useState([])
    const [selectedPeriod, setSelectedPeriod] = useState('1D')

    const websocket = useRef(null)
    const pollingInterval = useRef(null)

    const handleCheckboxChange = (event, symbol) => {
        if (event.target.checked) {
            setCheckedSymbols([...checkedSymbols, symbol]);
        } else {
            setCheckedSymbols(checkedSymbols.filter((item) => item !== symbol));
        }
        console.log(checkedSymbols);
    };

    useEffect(()=>{ 
        const tickers=checkedSymbols


        // 1. Get the initial data
        axios.post('/market-scanner/', { 
            'symbols': tickers, 
            'period': selectedPeriod 
        })
            .then(response => {
                response = response.data.data;
                const parsedResponse = JSON.parse(response)
          
                setStockData(parsedResponse)                

            })
            .catch(error => {
                console.error('Error during POST request:', error);
        });

        const connectWebSocket = () => {
            websocket.current = new WebSocket('wss://websockets.financialmodelingprep.com')

            websocket.current.onopen = () => {
                const login = {
                    'event': 'login',
                    'data': {
                        'apiKey':constants.FMP_APIKEY
                    }
                };
                websocket.current.send(JSON.stringify(login));

            };

            websocket.current.onmessage = (event) => {
                const messageData = JSON.parse(event.data)
                console.log(messageData)
                if (messageData.event == 'login' && messageData.status == 200) {
                    const subscribeMessage={
                        'event': 'subscribe',
                        'data': {
                            'ticker': ['aapl', 'msft'] //tickers
                        }
                    }
                    websocket.current.send(JSON.stringify(subscribeMessage))
                }
                else if (messageData.event == 'heartbeat') {

                }
                else if ('s' in messageData) {
                    const messageTicker = messageData.s.toUpperCase()
                    const messageTimestamp = messageData.t

                    // update the chart
                }
                
                
            }

            websocket.current.onerror = (error) => {
                console.error("WebSocket error:", error);
            };

            websocket.current.onclose = () => {
                console.log("WebSocket connection closed");
            };

        
        }
        connectWebSocket()

        return () => {
            if (pollingInterval.current) {
                clearInterval(pollingInterval.current); 
            }
            if (websocket.current) {
                websocket.current.close(); 
            }
        };







        
    }, [checkedSymbols, selectedPeriod])

    return (
        <div className="p-1 h-100 d-flex flex-row">
            <div className="accordion accordion-flush" id="marketScannerAccordion">

                {Object.keys(marketScannerStructure).map((category, idx) => (
                    <div style={{backgroundColor: "var(--theme)", borderColor: "var(--grid)"}} className="accordion-item"  key={idx}>
                        <h2 className="accordion-header text-nowrap" id={`heading-${idx}`}>
                            <button 
                                style={{backgroundColor: "var(--theme)", color: "var(--text)"}}
                                className="py-2 accordion-button collapsed" 
                                type="button" 
                                data-bs-toggle="collapse" 
                                data-bs-target={`#collapse-${idx}`} 
                                aria-expanded="true" 
                                aria-controls={`collapse-${idx}`}
                            >
                                {category}
                            </button>
                        </h2>
                        <div 
                            id={`collapse-${idx}`} 
                            className="accordion-collapse collapse" 
                            aria-labelledby={`heading-${idx}`}
                            data-bs-parent="marketScannerAccordion"
                        >
                            <div className="accordion-body" style={{color: "var(--text)"}}>
                                {Object.keys(marketScannerStructure[category]).map((subCategory, subIdx) => (
                                    <div key={subIdx}>
                                        <h5>{subCategory}</h5>
                                        <div className='mx-2 mb-2'> 
                                        {marketScannerStructure[category][subCategory].length > 0 ? (
                                            marketScannerStructure[category][subCategory].map((symbol, symbolIdx) => (
                                                <div className="form-check" key={symbolIdx}>
                                                    <input 
                                                        className="form-check-input"
                                                        style={{borderRadius: '0px', background: "var(--grid)"}} 
                                                        type="checkbox" 
                                                        value={symbol} 
                                                        id={`symbol-${symbolIdx}`} 
                                                        checked={checkedSymbols.includes(symbol)}
                                                        onChange={(e) => handleCheckboxChange(e, symbol)}
                                                    />
                                                    <label className="form-check-label" htmlFor={`symbol-${symbolIdx}`}>
                                                        {symbol}
                                                    </label>
                                                </div>
                                            ))
                                        ) : (
                                            <p>No symbols available in this category</p>
                                        )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}

            </div>
            <div className='w-100'>
                <div className='d-flex flex-row-reverse mb-2'>
                    <WindowPieces.SquareRadioButton label={"1D"} onClick={()=> setSelectedPeriod('1D')}/>
                    <WindowPieces.SquareRadioButton label={"5D"} onClick={()=> setSelectedPeriod('5D')}/>
                    <WindowPieces.SquareRadioButton label={"1M"} onClick={()=> setSelectedPeriod('1M')}/>
                    <WindowPieces.SquareRadioButton label={"3M"} onClick={()=> setSelectedPeriod('3M')}/>
                    <WindowPieces.SquareRadioButton label={"6M"} onClick={()=> setSelectedPeriod('6M')}/>
                    <WindowPieces.SquareRadioButton label={"YTD"} onClick={()=> setSelectedPeriod('YTD')}/>
                    <WindowPieces.SquareRadioButton label={"1Y"} onClick={()=> setSelectedPeriod('1Y')}/>
                    <WindowPieces.SquareRadioButton label={"3Y"} onClick={()=> setSelectedPeriod('3Y')}/>
                    <WindowPieces.SquareRadioButton label={"5Y"} onClick={()=> setSelectedPeriod('5Y')}/>
                    <WindowPieces.SquareRadioButton label={"10Y"} onClick={()=> setSelectedPeriod('10Y')}/>
                </div>
                <TVLWLineChartMultiSeries seriesData={stockData} />
            </div>
        </div>
    );
};

export default MarketScanner;